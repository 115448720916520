import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NodeType } from '../../../types/node-types.type';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { RequestDto } from '../../../models/request-dto.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ImagesService } from '../../../services';
import { Asset } from '../../../../../modules/shared/state/assets/asset.model';
import { Subscription } from 'rxjs';
import { ImageGridForm } from '../../../forms/image-grid.form';
import { ItemCustomComponent } from '../../item-custom/item-custom.component';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { AssetBrowserButtonComponent } from '../../../../asset-browser/components/asset-browser-button/asset-browser-button.component';
import { FlashMessageService } from '../../../../../modules/core/services/flash-message.service';
import { FormConfig } from '../../../../../modules/forms/models/form-config.model';

@Component({
    selector: 'elias-editor-edit-image',
    templateUrl: './edit-image.component.html',
    styleUrls: ['edit-image.component.scss'],
})
export class EditImageComponent implements NodeEdit, AfterViewInit, OnDestroy, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    @Output() contentChange = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    id: string;
    aspectRatio: string;
    description: string;
    link: string;
    objectData = {};
    objectKeys = Object.keys;
    formConfig!: FormConfig;
    order;
    imageData = [];
    itemFieldSettings;

    nodeViewModelSubscription: Subscription;

    @ViewChild('assetBrowserButton') assetBrowserButton: AssetBrowserButtonComponent;

    constructor(
        private flashMessageService: FlashMessageService,
        private http: HttpClient,
        private imageForm: ImageGridForm,
        private imageService: ImagesService,
        private sideNavService: SideNavService
    ) {}

    ngOnInit() {
        this.formConfig = this.imageForm.getConfiguration('image');
        const parsedContent = this.content ? JSON.parse(this.content) : {};
        for (const key of Object.keys(parsedContent)) {
            this.objectData[key] = parsedContent[key] || '';
        }
        this.itemFieldSettings = JSON.parse(this.node.itemFieldSettings);
        this.order = Object.keys(JSON.parse(this.node.itemFieldSettings));
        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModelSubscription = this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }
    }

    ngAfterViewInit(): void {
        // auto open asset manager
        if (this.objectData['image'] === undefined) {
            if (this.assetBrowserButton) {
                this.assetBrowserButton.openAssetManager();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.nodeViewModelSubscription) {
            this.nodeViewModelSubscription.unsubscribe();
        }
    }

    onContentChange() {
        if (this.objectData['description']) {
            this.objectData['description'] = this.objectData['description'].replace(/"/g, "'"); // Fix JSON syntax
        }

        if (this.objectData['link']) {
            this.objectData['link'] = this.validateURL(this.objectData['link']);
        }

        this.content = `{"image": "${this.objectData['image']}", "description": "${
            this.objectData['description'] || ''
        }", "link": "${this.objectData['link'] || ''}","title": "${this.objectData['title'] || ''}","subtitle": "${
            this.objectData['subtitle'] || ''
        }","source": "${this.objectData['source'] || ''}"}`;
        this.contentChange.emit(this.content);
    }

    validateURL(content) {
        if (content.startsWith('https://') || content.startsWith('http://')) return content;
        else return 'https://' + content;
    }

    onAssetsSelected(assets: Asset[]) {
        if (Array.isArray(assets) && assets.length === 1) {
            this.objectData['image'] = assets[0].id;
            const payload = new RequestDto(
                { assetId: this.objectData['image'], aspectRatio: this.aspectRatio },
                {},
                {}
            );
            this.imageService.loadSingleImage(payload).subscribe();

            if (this.objectData.hasOwnProperty('image') && this.objectData['image'] !== null) {
                this.onContentChange();
            } else {
                this.flashMessageService.showTranslatedError('component.image.selectionError');
            }
        } else {
            this.flashMessageService.showTranslatedError('component.image.selectionError');
        }
    }

    openEditor() {
        const onSave = new EventEmitter<any>();

        const inputs = {
            data: this.objectData,
            type: 'image',
            config: this.formConfig,
            isDelete: false,
        };
        const outputs = {
            save: onSave,
        };

        this.sideNavService.setComponent(ItemCustomComponent, inputs, outputs);

        onSave.subscribe((data) => {
            this.onModifyData(data);
        });
    }

    onModifyData(data) {
        for (const key of Object.keys(data)) {
            this.objectData[key] = data[key];
        }

        const arr = ['image', 'title', 'subtitle', 'description', 'source', 'link'];
        let counter = 0;

        arr.forEach((ele) => {
            if (this.objectData.hasOwnProperty(ele)) {
                counter++;
            }
        });

        if (counter === 6) {
            this.onContentChange();
            this.close();
        }
    }

    close() {
        this.sideNavService.close();
    }

    getData(val) {
        if (val == undefined || val == '') return '';
        else return val;
    }

    trackByIndex(index, item) {
        return index;
    }
}
