import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Asset } from '../../state/assets/asset.model';
import { AssetsService } from '../../state/assets/assets.service';
import { ProcessingErrorMessage } from '../../models/processing-error-message.model';
import { AssetsQuery } from '../../state/assets/assets.query';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'elias-shared-asset-view',
    templateUrl: './asset-view.component.html',
    styleUrls: ['./asset-view.component.scss'],
})
export class AssetViewComponent implements OnChanges {
    @Input() selectedAsset!: Asset;

    public asset$: Observable<Asset | undefined>;
    public processingErrorMessages$: Observable<ProcessingErrorMessage[]>;

    private selectedAssetChange$ = new BehaviorSubject<Asset | undefined>(undefined);

    constructor(private assetsQuery: AssetsQuery, public assetsService: AssetsService) {
        this.asset$ = this.selectedAssetChange$.pipe(
            filter((asset) => asset !== undefined),
            map((asset) => asset as Asset),
            switchMap((asset: Asset) => this.assetsQuery.selectEntity(asset.id)),
            map((asset) => asset ?? this.selectedAsset) // If asset is not available in the store, use original one
        );

        this.processingErrorMessages$ = this.asset$.pipe(map((asset) => this.getProcessingErrorMessages(asset)));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedAsset']) {
            this.selectedAssetChange$.next(this.selectedAsset);
        }
    }

    private getProcessingErrorMessages(asset: Asset | undefined): ProcessingErrorMessage[] {
        if (asset === undefined || !this.assetsService.isExcel(asset) || asset.processingErrorMessages === null) {
            return [];
        }

        return Object.values(asset.processingErrorMessages);
    }
}
