<elias-assetbrowser-dropzone-upload
    [config]="dropzoneConfig"
    (uploadStart)="onUploadStart($event)"
    class="asset-browser-position"
    (uploadCompleted)="onUploadComplete($event)"
    (errorUpload)="onErrorUpload($event)"
    (uploadInProgress)="onUploadInProgress($event)"
    (dragEnter)="onDragEnter($event)"
    (assetDropped)="onAssetDropped($event)"
    (dragOver)="onDragOver($event)"
    (dragLeave)="onDragLeave($event)">
    <elias-shared-sidebar-left
        [ngClass]="{ overlay: toggleView && !fixedHeight, 'fixed-height-asset-sidebar': fixedHeight }"
        class="default-asset-browser">
        <elias-shared-sidebar-widget-scrollable class="asset-tree-data">
            <i
                class="ei ei-arrows-left arrow-right"
                *ngIf="toggleView && !fixedHeight"
                (click)="toggle()"
                [ngClass]="{ 'overlay-position': toggleView && !fixedHeight }"></i>
            <i
                class="ei ei-arrows-right arrow-left"
                *ngIf="!toggleView && !fixedHeight"
                (click)="toggle()"
                [ngClass]="{ 'initial-position': !toggleView && !fixedHeight }"></i>
            <tree-root
                #treeComponent
                [nodes]="allStorages$ | async"
                [options]="treeOptions"
                class="tree-root"
                [ngClass]="{ visible: toggleView && !fixedHeight, 'fixed-height-tree-root': fixedHeight }">
                <ng-template #treeNodeTemplate let-node let-index="index">
                    <div class="node-value">
                        <div fxLayout="row">
                            <i class="ei ei-{{ node.data | assetStorage : 'icons' }} icons"></i>
                            <span>{{ node.data | assetStorage : 'treeName' }}</span>
                        </div>
                    </div>
                </ng-template>
            </tree-root>
        </elias-shared-sidebar-widget-scrollable>
    </elias-shared-sidebar-left>
    <div id="custom-dropzone" [ngClass]="{ 'fixed-height-custom-dropzone-asset-browser': fixedHeight }"></div>
    <div class="content-wrapper" [ngClass]="{ 'fixed-height-content-wrapper-asset-browser': fixedHeight }">
        <div class="drag-icon-text" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
            <i class="ei ei-drag-drop drag-drop-icon"></i>
            <span class="drag-drop-text">Drag your files here</span>
        </div>
        <ng-container *ngIf="!fixedHeight">
            <div class="sticky-content" fxLayoutAlign="space-around space-between" fxLayoutGap="20px">
                <div
                    class="view-config-container"
                    fxLayout="row"
                    fxLayoutGap="20px"
                    fxLayoutAlign="start space-between"
                    [ngClass]="{ 'fixed-height-asset-browser': fixedHeight }">
                    <div
                        class="filters"
                        fxFlex="fill"
                        fxLayout="row"
                        fxLayoutGap="35px"
                        fxLayoutAlign="start space-between">
                        <div class="search-field">
                            <input
                                type="search"
                                required
                                class="search-input"
                                #stickyFilterName
                                placeholder="{{ 'assetBrowser.filter.search' | translate }}"
                                (keyup)="applyStickyFilter($event.target.value)" />
                            <i class="search-cancel ei ei-close" (click)="applyStickyFilter(null)"></i>
                        </div>
                        <elias-shared-filter-dropdown
                            class="filter-dropdown"
                            title="{{ 'assetBrowser.filter.collection' | translate }}"
                            [options]="collectionOptions"
                            [selectedValue]="(assetFilters$ | async).collection"
                            (changeValue)="onChangeCollection($event)"></elias-shared-filter-dropdown>
                        <elias-shared-filter-dropdown
                            class="filter-dropdown"
                            title="{{ 'assetBrowser.filter.fileType' | translate }}"
                            [options]="fileTypeOptions"
                            [selectedValue]="(assetFilters$ | async).fileType"
                            (changeValue)="onChangeFileType($event)"
                            *ngIf="isEmpty(type)"></elias-shared-filter-dropdown>
                        <elias-shared-sorting-dropdown
                            class="filter-dropdown"
                            title="{{ 'assetBrowser.sort.defaultOption' | translate }}"
                            [options]="sortOptions"
                            [selectedValue]="(assetSorting$ | async).key"
                            (changeValue)="onChangeSortingValue($event)"></elias-shared-sorting-dropdown>
                    </div>
                </div>
                <div class="upload-button">
                    <button
                        mat-flat-button
                        color="accent"
                        id="uploadButton"
                        [ngClass]="{ 'display-parent-shared': storageTypesNotAllowed.includes(selectedStorageType) }">
                        <span>{{ selectedStorage | assetStorage : 'button' : selectedStorageType }}</span>
                    </button>
                </div>
            </div>
        </ng-container>

        <div class="container asset-browser-container" [ngClass]="{ 'fixed-height-asset-browser': fixedHeight }">
            <i class="ei ei-close close-icon" (click)="close()" *ngIf="fixedHeight"></i>
            <elias-shared-module-title
                title="{{ selectedStorageType | assetStorage : 'title' : selectedStorage | strlen : 35 }}"
                [ngClass]="{ 'title-position': fixedHeight }">
                <button
                    mat-flat-button
                    color="accent"
                    class="upload-button"
                    id="uploadButton"
                    [ngClass]="{
                        'popup-position': fixedHeight,
                        'display-parent-shared': storageTypesNotAllowed.includes(selectedStorageType)
                    }">
                    <span>{{ selectedStorage | assetStorage : 'button' : selectedStorageType }}</span>
                </button>
            </elias-shared-module-title>

            <div
                class="view-config-container"
                fxLayout="row"
                fxLayoutGap="20px"
                fxLayoutAlign="start space-between"
                [ngClass]="{ 'fixed-height-asset-browser': fixedHeight }">
                <div
                    class="filters"
                    fxFlex="fill"
                    fxLayout="row"
                    fxLayoutGap="35px"
                    fxLayoutAlign="start space-between">
                    <div class="search-field">
                        <input
                            type="search"
                            required
                            class="search-input"
                            #filterName
                            placeholder="{{ 'assetBrowser.filter.search' | translate }}"
                            (keyup)="applyFilter($event.target.value)" />
                        <i class="search-cancel ei ei-close" (click)="applyFilter(null)"></i>
                    </div>
                    <elias-shared-filter-dropdown
                        class="filter-dropdown"
                        title="{{ 'assetBrowser.filter.collection' | translate }}"
                        [options]="collectionOptions"
                        [selectedValue]="(assetFilters$ | async).collection"
                        (changeValue)="onChangeCollection($event)"></elias-shared-filter-dropdown>
                    <elias-shared-filter-dropdown
                        class="filter-dropdown"
                        title="{{ 'assetBrowser.filter.fileType' | translate }}"
                        [options]="fileTypeOptions"
                        [selectedValue]="(assetFilters$ | async).fileType"
                        (changeValue)="onChangeFileType($event)"
                        *ngIf="isEmpty(type)"></elias-shared-filter-dropdown>
                    <elias-shared-sorting-dropdown
                        class="sorting-dropdown"
                        title="{{ 'assetBrowser.sort.defaultOption' | translate }}"
                        [options]="sortOptions"
                        [selectedValue]="(assetSorting$ | async).key"
                        (changeValue)="onChangeSortingValue($event)"></elias-shared-sorting-dropdown>
                </div>
                <div fxFlex="75px" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="end space-between">
                    <elias-shared-view-option-toggle
                        [viewOption]="assetViewOption$ | async"
                        (toggleViewEvent)="onChangeViewOption($event)"
                        [big]="true"
                        [intermediate]="false"
                        [list]="true"></elias-shared-view-option-toggle>
                </div>
            </div>

            <div class="file-list" [ngClass]="{ popup: fixedHeight }">
                <div
                    class="dropzone"
                    [@slideInOut]="uploadInProgress ? 'in' : 'out'"
                    [ngClass]="{ 'fixed-height-dropzone-asset-browser': fixedHeight }">
                    <div id="dropzonePreviewContainer" class="dropzone-previews"></div>
                </div>
                <elias-assetbrowser-files
                    [assets]="filteredAssets$ | async"
                    [selectedAssets]="selectedAssets"
                    [assetsPerPage]="assetsPerPage"
                    [aspectRatio]="originalAspectRatio"
                    [assetsInRow]="assetsInRow"
                    [selectedCollection]="selectedCollection"
                    [showPagination]="showAllState"
                    [fixedHeight]="fixedHeight"
                    (clickAsset)="onAssetClick($event)"
                    (selectAsset)="onAssetSelect($event)"
                    (clearSelection)="onClearSelection($event)"
                    (changePage)="onPageChange($event)"
                    [viewValue]="assetViewOption"
                    (fileReplaced)="onFileReplaced($event)"></elias-assetbrowser-files>
            </div>

            <footer [ngClass]="{ 'action-popup-buttons': fixedHeight }">
                <div fxLayout="row" fxLayoutGap="20px" fxFlex="fill" class="footer-buttons">
                    <div *ngIf="fixedHeight">
                        <button class="btn-save cancel popup-position" mat-button (click)="close()">
                            {{ 'actions.cancel' | translate }}
                        </button>
                    </div>

                    <div class="pagination" *ngIf="showAllState">
                        <mat-paginator
                            [ngClass]="{ popup: fixedHeight }"
                            [length]="totalDataLength$ | async"
                            [pageSize]="assetsPerPage"
                            (page)="onPageChange($event)"></mat-paginator>
                    </div>
                    <div class="pagination-placeholder" *ngIf="!showAllState"></div>

                    <div *ngIf="fixedHeight">
                        <button
                            color="accent"
                            class="btn-save popup-position select"
                            mat-flat-button
                            [disabled]="selectedAssets.length === 0"
                            (click)="returnAssets()">
                            <span *ngIf="selectedAssets.length !== 1">{{
                                'assetBrowser.selectAssets.multiple' | translate : getCount()
                            }}</span>
                            <span *ngIf="selectedAssets.length === 1">{{
                                'assetBrowser.selectAssets.single' | translate : getCount()
                            }}</span>
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</elias-assetbrowser-dropzone-upload>
