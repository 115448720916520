import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AspectRatio } from './aspect-ratio.model';
import { Injectable } from '@angular/core';

export interface AspectRatiosState extends EntityState<AspectRatio> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'AspectRatios' })
export class AspectRatiosStore extends EntityStore<AspectRatiosState, AspectRatio> {
    constructor() {
        super();
    }
}

export const aspectRatiosStore = new AspectRatiosStore();
