import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AssetCollection } from './asset-collection.model';
import { Injectable } from '@angular/core';

export interface AssetCollectionsState extends EntityState<AssetCollection> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'AssetCollections' })
export class AssetCollectionsStore extends EntityStore<AssetCollectionsState, AssetCollection> {
    constructor() {
        super({ loaded: false });
    }
}

export const assetCollectionsStore = new AssetCollectionsStore();
