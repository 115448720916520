import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { Asset } from '../../../../shared/state/assets/asset.model';
import { Subscription, timer } from 'rxjs';
import { RequestDto } from '../../../../../editor/editor/models/request-dto.model';
import { AssetsService } from '../../../../shared/state/assets/assets.service';
import { AssetsQuery } from '../../../../shared/state/assets/assets.query';
import { ExcelDataService } from '../../../service/excel-data.service';
import { ExcelQuery } from '../../../../../editor/editor/state/excel/excel.query';
import { Excel } from '../../../../../editor/editor/state/excel/excel.model';

@Component({
    selector: 'elias-form-element-excel-asset',
    templateUrl: './form-excel-asset.component.html',
    styleUrls: ['./form-excel-asset.component.scss'],
})
export class FormExcelAssetComponent extends BasicFormElementComponent implements OnInit, OnDestroy {
    @Input()
    allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/octet-stream',
    ];

    asset: Excel;
    excelAsset: Excel;
    worksheetsOptions = [];
    excelSubscription: Subscription = null;

    constructor(
        injector: Injector,
        private assetsService: AssetsService,
        private excelQuery: ExcelQuery,
        private assetsQuery: AssetsQuery,
        private dataService: ExcelDataService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.initValue) {
            const payload = new RequestDto({ assetId: this.initValue }, {}, {});
            this.excelAsset = this.excelQuery.getEntity(this.initValue);
            this.asset = this.excelAsset;
            if (this.excelAsset.processedAt === null) {
                this.loadWorkSheet();
            }
            this.formGroup.get(this.name).setValue(this.excelAsset.id);
        }
    }

    onSelect(assets: Asset[]) {
        const previouslySelectedAsset = this.formGroup.get(this.name)?.value;

        this.asset = assets[0] as Excel;
        this.formGroup.get(this.name).setValue(this.asset.id);

        // Reset field `excelWorksheet` if Excel file was changed
        if (this.asset?.id !== previouslySelectedAsset) {
            this.formGroup.get('excelWorksheet')?.setValue(null);
        }

        if (this.asset.worksheets.length > 0) {
            this.worksheetsOptions = this.asset.worksheets;
            this.dataService.setData(this.worksheetsOptions);
            this.formGroup.get('excelWorksheet').markAsTouched();
        } else {
            this.loadWorkSheet();
        }
    }

    loadWorkSheet() {
        if (this.excelSubscription == null || this.excelSubscription.closed) {
            const intval = timer(0, 10000);
            this.excelSubscription = intval.subscribe(() => {
                const payload = new RequestDto({ assetId: this.asset.id }, {}, {});
                this.assetsService.loadSingleAsset(payload).subscribe((asset: Asset) => {
                    this.asset = asset as Excel;
                    this.worksheetsOptions = asset['worksheets'];
                    this.dataService.setData(this.worksheetsOptions);
                    this.formGroup.get('excelWorksheet').markAsTouched();
                    if (asset['processedAt'] !== null) {
                        if (this.excelSubscription) {
                            this.excelSubscription.unsubscribe();
                        }

                        const currentSelection = this.formGroup.get('excelWorksheet').value;
                        let selectionStillAvailable = false;

                        this.worksheetsOptions.forEach((obj, i) => {
                            if (obj['key'] === currentSelection) {
                                selectionStillAvailable = true;
                            }
                        });

                        if (!selectionStillAvailable) {
                            this.formGroup.get('excelWorksheet').setValue(null);
                        }
                    }
                });
            });
        }
    }

    ngOnDestroy() {
        this.dataService.setData(null);
        if (this.excelSubscription != null) {
            this.excelSubscription.unsubscribe();
        }
    }
}
