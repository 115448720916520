import { NodeType } from '../../types/node-types.type';

export class Node {
    id: string;
    name: string;
    content: string;
    sectionId: string;
    position: number;
    createdAt: any;
    updatedAt: any;
    type: NodeType | string;
    nodeTypeDiscriminator: string;
    allowedFileTypes?: string;
    channelSettings: any[];
    needsReview: boolean;
    hasUnresolvedComments: boolean;
    renderedContent?: string;

    editing = false;
    readonly discriminator: 'Node';
}

export function isNode(value: any): value is Node {
    return value && value.discriminator === 'Node';
}
