import { Component, Input } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
    selector: 'elias-shared-module-title',
    templateUrl: './module-title.component.html',
    styleUrls: ['./module-title.component.scss'],
})
export class ModuleTitleComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() value = '';
    @Input() buttonFlexLayout = 'space-between center';

    id: string;

    constructor(private route: ActivatedRoute) {
        if (this.route.parent) {
            const segments: UrlSegment[] = this.route.parent.snapshot.url;
            if (segments.length > 0) {
                this.id = segments[0].path;
            }
        }
    }
}
