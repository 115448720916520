import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ReplaySubject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SuggestionKeyDownProps } from '@tiptap/suggestion';
import { Mentionable } from '../../models/mentionable.model';
import { PublicationGroupQuery } from '../../../../publication-group/state/publication-group/publication-group.query';
import { User } from '../../../../modules/shared/state/users/user.model';

type IndexedMentionable = Mentionable & { index: number };

@Component({
    selector: 'elias-mention-menu',
    templateUrl: './mention-menu.component.html',
    styleUrls: ['./mention-menu.component.scss'],
})
export class MentionMenuComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChildren('menuItem') menuItemElements?: QueryList<ElementRef>;

    @Input() clientRectFunction?: () => DOMRect | null;
    @Input() selectFunction?: Function;
    @Input() mentionableItems: Mentionable[] = [];

    public selectedIndex = 0;
    public mentionableChiefEditors: IndexedMentionable[] = [];
    public mentionableEditors: IndexedMentionable[] = [];
    public mentionableGroups: IndexedMentionable[] = [];

    private lastLeftPosition = 0;
    private lastTopPosition = 0;
    private destroyed$: ReplaySubject<void> = new ReplaySubject(1);

    constructor(private elementRef: ElementRef, private publicationGroupQuery: PublicationGroupQuery) {}

    ngOnInit() {
        timer(0, 10)
            .pipe(
                tap(() => {
                    this.repositionMentionMenu();
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe();
    }

    ngOnChanges() {
        this.selectedIndex = 0;

        let index = 0;

        this.mentionableChiefEditors = this.getMentionableChiefEditors().map((item) => {
            return { ...item, index: index++ };
        });

        this.mentionableEditors = this.getMentionableEditors().map((item) => {
            return { ...item, index: index++ };
        });

        this.mentionableGroups = this.mentionableItems
            .filter((item) => item.mentionType === 'group')
            .map((item) => {
                return { ...item, index: index++ };
            });

        this.mentionableItems = [
            ...this.mentionableChiefEditors,
            ...this.mentionableEditors,
            ...this.mentionableGroups,
        ];
    }

    public selectItem(mentionable: Mentionable): void {
        this.selectFunction?.(mentionable);
    }

    public onKeyDown({ event }: SuggestionKeyDownProps): boolean {
        if (event.key === 'ArrowUp') {
            this.upHandler();
            return true;
        }

        if (event.key === 'ArrowDown') {
            this.downHandler();
            return true;
        }

        if (event.key === 'Enter') {
            this.enterHandler();
            return true;
        }

        return false;
    }

    private upHandler(): void {
        this.selectedIndex = (this.selectedIndex + this.mentionableItems.length - 1) % this.mentionableItems.length;
        this.scrollIntoSelectedElement();
    }

    private downHandler(): void {
        this.selectedIndex = (this.selectedIndex + 1) % this.mentionableItems.length;
        this.scrollIntoSelectedElement();
    }

    private enterHandler(): void {
        const mentionable = this.mentionableItems[this.selectedIndex];

        if (mentionable) {
            this.selectItem(mentionable);
        }
    }

    private scrollIntoSelectedElement(): void {
        this.menuItemElements?.get(this.selectedIndex)?.nativeElement.scrollIntoView();
    }

    private repositionMentionMenu(): void {
        if (!this.clientRectFunction) {
            return;
        }

        const clientRect = this.clientRectFunction();

        if (!clientRect) {
            return;
        }

        const { top, left } = clientRect;

        if (top === this.lastTopPosition && left === this.lastLeftPosition) {
            return;
        }

        this.lastTopPosition = top;
        this.lastLeftPosition = left;

        const { width } = document.body.getBoundingClientRect();
        const boundedLeft = Math.min(width - 400, left);

        this.elementRef.nativeElement.style.setProperty('--left', boundedLeft);
        this.elementRef.nativeElement.style.setProperty('--bottom', top);
        this.elementRef.nativeElement.style.setProperty('display', 'block');
    }

    private getMentionableEditors(): Mentionable[] {
        const editors = this.publicationGroupQuery.getEditorsForActive();

        const editorsIds = editors.map((editor) => editor.id);

        return this.mentionableItems.filter((item) => {
            return item.mentionType === 'user' && editorsIds.includes(item.id as string);
        });
    }

    private getMentionableChiefEditors(): Mentionable[] {
        const chiefEditors = this.publicationGroupQuery.getChiefEditorsForActive();

        const chiefEditorsIds = chiefEditors.map((chiefEditor) => chiefEditor.id);

        return this.mentionableItems.filter((item) => {
            return item.mentionType === 'user' && chiefEditorsIds.includes(item.id as string);
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
