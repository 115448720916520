import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MapQuery } from '../../../state/maps/map.query';

@Component({
    selector: 'elias-editor-display-maps',
    templateUrl: './display-maps.component.html',
})
export class DisplayMapsComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    _id: string;
    path: SafeUrl | string;

    constructor(private sanitizer: DomSanitizer, private mapQuery: MapQuery) {}

    ngOnInit() {
        this._id = this.node.id;

        this.mapQuery.selectAll().subscribe((map) => {
            this.path = this.getPath();
        });
    }

    getPath() {
        if (this._id && this.mapQuery.hasEntity(this._id)) {
            return this.sanitizer.bypassSecurityTrustUrl(this.mapQuery.getEntity(this._id).content);
        }
    }
}
