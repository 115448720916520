export class EnvService {
    // The values that are defined here are the default values that can
    // be overridden by env.js

    // The api base url. Should contain /v1 and no trailing slash.
    public apiBaseUrl: string = null;

    // The sentry project to be used in production
    // This needs to be overwritten in env.js for e.g. Dev Docker Images running the app in prod mode
    public sentryDsn: string = 'https://b34767ada6164bdc868cd96994dc8a45@o371356.ingest.sentry.io/5184032';

    // The sentry environment
    // This needs to be overwritten in env.js with e.g. 'production'
    public sentryEnvironment: string = 'production';

    // Whether or not sentry errors should be logged to console
    public sentryLogErrors: boolean = false;

    // Whether or not the sentry dialog should be showed
    public sentryShowDialog: boolean = false;

    // The sentry traces sample rate. The higher, the slower
    public sentryTracesSampleRate: number = 0.1;

    constructor() {}
}
