<div class="position-loader">
    <div class="google-loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="loading-content" *ngIf="includeText">
        <h1>{{ 'login.loadingtext' | translate }}</h1>
        <p>{{ 'login.loadingtextdescription' | translate }}</p>
    </div>
</div>
