import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequestDto } from '../../../editor/models/request-dto.model';
import { HttpClient } from '@angular/common/http';
import { ImagesStore } from './images.store';
import { Image } from './image.model';
import { ImagesQuery } from './images.query';
import { of, Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { DomainService } from '../../../../modules/core/services/domain.service';

@Injectable()
export class ImagesService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private imagesStore: ImagesStore,
        private imagesQuery: ImagesQuery
    ) {}

    /**
     * Loads all images from a section
     *
     * @param {RequestDto} payload
     * @returns {Observable<any>}
     */
    loadImagesForSection(payload: RequestDto): Observable<Image[]> {
        this.imagesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/images`;

        return this.http.get<any[]>(url, {}).pipe(
            map((images: any) => {
                return images.map((image: any) => {
                    const content = URL.createObjectURL(this.b64toBlob(image.content));
                    let returnImage;
                    if (this.imagesQuery.hasEntity(image.id)) {
                        returnImage = this.setImageAspectRatio(image, content);
                    } else {
                        returnImage = new Image(image.id, { [image.aspectRatio]: { content } }, image.type);
                        this.imagesStore.add(returnImage);
                    }

                    return returnImage as Image;
                });
            }),
            switchMap((images: any) => {
                return of(images);
            }),
            tap((images) => {
                this.imagesStore.upsertMany(images);
                this.imagesStore.setLoading(false);
                this.imagesStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    /**
     * Loads all images from a node
     *
     * @param {RequestDto} payload
     * @returns {Observable<any>}
     */
    loadImagesForNode(payload: RequestDto): Observable<Image[]> {
        this.imagesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/nodes/${payload.getParam('nodeId')}/images`;

        return this.http.get<any>(url, {}).pipe(
            tap((images) => {
                images = images.map((image) => {
                    if (this.imagesQuery.hasEntity(image.id)) {
                        const content = URL.createObjectURL(this.b64toBlob(image.content));
                        return this.setImageAspectRatio(image, content);
                    } else {
                        return new Image(image.id, { [image.aspectRatio]: { content: image.content } }, image.type);
                    }
                });
                this.imagesStore.upsertMany(images);
                this.imagesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    loadSingleImage(payload: RequestDto): Observable<any> {
        this.imagesStore.setLoading(true);
        let url = '';
        if (payload.getParam('aspectRatio')) {
            url = `${this.domainService.apiBaseUrl}/images/${payload.getParam('assetId')}/${payload.getParam(
                'aspectRatio'
            )}`;
        } else {
            url = `${this.domainService.apiBaseUrl}/images/${payload.getParam('assetId')}`;
        }

        return this.http.get<any>(url).pipe(
            tap((image) => {
                if (this.imagesQuery.hasEntity(image.id)) {
                    const content = URL.createObjectURL(this.b64toBlob(image.content));
                    const existingImage = this.setImageAspectRatio(image, content);
                    this.imagesStore.replace(image.id, existingImage);
                } else {
                    const newImage = new Image(
                        image.id,
                        { [image.aspectRatio]: { content: image.content } },
                        image.type
                    );
                    this.imagesStore.add(newImage);
                }
                this.imagesStore.setLoading(false);
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    loadImages(payload: RequestDto): Observable<Image[]> {
        this.imagesStore.setLoading(true);
        let url;
        if (payload.getParam('aspectRatio')) {
            url = `${this.domainService.apiBaseUrl}/images/multiple/${payload.getParam(
                'aspectRatio'
            )}?ids=${payload.getParam('imageIds')}`;
        } else {
            url = `${this.domainService.apiBaseUrl}/images/multiple?ids=${payload.getParam('imageIds')}`;
        }

        return this.http.get<any[]>(url).pipe(
            map((images: any) => {
                return images.map((image: any) => {
                    const aspectRatio = image.aspectRatio;
                    const type = image.type;
                    const content = URL.createObjectURL(this.b64toBlob(image.content));
                    let returnImage;
                    if (this.imagesQuery.hasEntity(image.id)) {
                        returnImage = this.setImageAspectRatio(image, content);
                    } else {
                        returnImage = new Image(image.id, { [aspectRatio]: { content } }, type);
                    }

                    return returnImage as Image;
                });
            }),
            switchMap((images: any) => {
                return of(images);
            }),
            tap((images) => {
                this.imagesStore.upsertMany(images);
                this.imagesStore.setLoading(false);
                this.imagesStore.update({ loaded: true });
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 1024): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    private setImageAspectRatio(image, content: string): Image {
        const returnImage = _.cloneDeep(this.imagesQuery.getEntity(image.id));
        if (!returnImage.aspectRatios) {
            returnImage.aspectRatios = {};
        }
        returnImage.aspectRatios[image.aspectRatio] = { content };

        return returnImage;
    }
}
