export class Image {
    id: string;
    aspectRatios: { [id: string]: { content: string } };
    type: string;

    constructor(id, aspectRatios, type) {
        this.id = id;
        this.aspectRatios = aspectRatios;
        this.type = type;
    }
}
