import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValueOption } from '../../../../modules/shared/models/key-value-option.model';
import { map } from 'rxjs/operators';
import { User } from '../../../../modules/shared/state/users/user.model';
import { UsersQuery } from '../../../../modules/shared/state/users/users.query';
import { TranslateService } from '@ngx-translate/core';
import { CommentFilterTypes } from '../../state/comments.store';
import { SortingOptions } from '../../../../modules/shared/models/sorting-options.model';
import { SortingDirection } from '../../../../modules/shared/enums/sorting-direction.enum';
import { CommentsFacade } from '../../services/comments-facade.service';

@Component({
    selector: 'elias-comment-list-filters',
    templateUrl: './comment-list-filters.component.html',
    styleUrls: ['./comment-list-filters.component.scss'],
})
export class CommentListFiltersComponent implements OnDestroy {
    @Output() filtersChanged = new EventEmitter();

    public sortOptions: KeyValueOption[] = [];
    public createdByFilterOptions$: Observable<KeyValueOption[]>;
    public resolvedAtFilterOptions: KeyValueOption[] = [];

    constructor(
        private commentsFacade: CommentsFacade,
        private translateService: TranslateService,
        private usersQuery: UsersQuery
    ) {
        this.resolvedAtFilterOptions = [
            { key: 'resolved', value: this.translateService.instant('comments.filter.resolvedAt.resolved') },
            { key: 'unresolved', value: this.translateService.instant('comments.filter.resolvedAt.unresolved') },
        ];

        this.createdByFilterOptions$ = this.usersQuery.selectAll().pipe(
            map((users: User[]) =>
                users.map((user) => {
                    return {
                        key: user.id as string,
                        value: user.firstName + ' ' + user.lastName,
                    } as KeyValueOption;
                })
            )
        );
    }

    public onChangeFilterValue(filterType: CommentFilterTypes, property: string): void {
        this.commentsFacade.updateFilter(filterType, property);
        this.commentsFacade.resetPage();

        this.filtersChanged.emit();
    }

    public onChangeSortingValue(sortingProperty: string | null): void {
        const options: SortingOptions = { property: sortingProperty, direction: SortingDirection.Desc };

        this.commentsFacade.setSorting(options);
        this.commentsFacade.resetPage();

        this.filtersChanged.emit();
    }

    ngOnDestroy() {
        this.commentsFacade.resetUiStore();
    }
}
