import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { NodeDto } from '../models/node-dto.model';
import { PropertyEditorQuery } from '../state/property-editor/property-editor.query';
import { PropertyEditorService } from '../state/property-editor/property-editor.service';
import { NodesQuery } from '../state/nodes/nodes.query';
import { NodesService } from '../state/nodes/nodes.service';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { SectionsService } from '../state/sections/sections.service';
import { SectionsQuery } from '../state/sections/sections.query';
import { LocksQuery } from '../state/locks/locks.query';
import { LocksService } from '../state/locks/locks.service';
import { PublicationsService } from '../../../publication/state/publication/publications.service';

@Injectable()
export class EditorDocumentResolver implements Resolve<any> {
    constructor(
        private propertyEditorService: PropertyEditorService,
        private propertyEditorQuery: PropertyEditorQuery,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private publicationsQuery: PublicationsQuery,
        private sectionsService: SectionsService,
        private sectionsQuery: SectionsQuery,
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private publicationsService: PublicationsService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const sectionId = route.params.sectionId;
        const observables = [];
        let id = this.publicationsQuery.getActiveId();
        if (!this.publicationsQuery.hasEntity(id)) {
            observables.push(this.publicationsService.getSinglePublication(id));
        }
        if (this.nodesQuery.getValue().loadedNodesForSectionId !== sectionId) {
            const payload: NodeDto = new NodeDto({ sectionId });
            observables.push(this.nodesService.getNodesForSection(payload));
        }

        if (observables.length > 0) {
            // this.loaded$.next(false);
            return forkJoin(observables).pipe(
                first(),
                tap(() => {
                    this.sectionsService.setActiveSection(this.sectionsQuery.getEntity(sectionId));
                })
            );
        } else {
            this.sectionsService.setActiveSection(this.sectionsQuery.getEntity(sectionId));
            return true;
        }
    }
}
