import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../../../auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageKeys } from '../../shared/enums/local-storage-keys.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(LocalStorageKeys.Token);

        const newReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        return next.handle(newReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(error);
                        case 403:
                            return this.handle403Error(error);
                        default:
                            return throwError(error);
                    }
                } else {
                    return throwError(error);
                }
            })
        );
    }

    handle403Error(error: HttpErrorResponse) {
        const text = 'Access Denied.';
        if (
            error &&
            error.status === 403 &&
            text.toLowerCase().includes(error.error.message.toString().toLowerCase())
        ) {
            this.router.navigate(['/accessdenied']);
            setTimeout(() => {
                this.router.navigate(['/publications']);
            }, 3000);

            return EMPTY;
        }

        return throwError(error);
    }

    handle400Error(error: HttpErrorResponse) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            return throwError(error);
        }
        return throwError(error);
    }

    handle401Error(error: HttpErrorResponse) {
        if (this.isUnauthorized(error)) {
            this.router.navigate(['/disabledUser']);
            return EMPTY;
        }

        if (this.invalidCredentials(error)) {
            return throwError(error);
        }

        this.auth.unattendedLogout();
        return EMPTY;
    }

    isUnauthorized(error: HttpErrorResponse) {
        const text = 'Account is disabled.';

        return (
            error && error.status === 401 && error.error.code == 401 && text.includes(error.error.message.toString())
        );
    }

    invalidCredentials(error: HttpErrorResponse) {
        const text = 'Invalid credentials.';

        return (
            error && error.status === 401 && error.error.code === 401 && text.includes(error.error.message.toString())
        );
    }
}
