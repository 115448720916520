import { ChannelsState, ChannelsStore } from './channels.store';
import { Channel } from './channel.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChannelsQuery extends QueryEntity<ChannelsState, Channel> {
    constructor(protected store: ChannelsStore) {
        super(store);
    }

    getEnabledChannels() {
        return this.getAll().filter((channel) => channel.enabled);
    }
}
