import { Injectable } from '@angular/core';
import { combineQueries, QueryEntity, SelectAllOptionsD } from '@datorama/akita';
import { CommentsState, CommentsStore, CommentsTarget } from './comments.store';
import { UsersQuery } from '../../../modules/shared/state/users/users.query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Comment, CommentNormalized } from '../models/comment.model';
import { SpecialUsers } from '../../../modules/shared/enums/special-users.enum';
import { CommentStatistics } from '../models/comment-statistics.model';
import { SortingOptions } from '../../../modules/shared/models/sorting-options.model';

@Injectable({
    providedIn: 'root',
})
export class CommentsQuery extends QueryEntity<CommentsState, CommentNormalized> {
    target$ = this.select('target');

    constructor(protected override store: CommentsStore, private usersQuery: UsersQuery) {
        super(store);
    }

    selectComments(options: SelectAllOptionsD<CommentNormalized> = {}): Observable<Comment[]> {
        return combineQueries([this.selectAll(options), this.usersQuery.selectAll({ asObject: true })]).pipe(
            map(([comments, users]) => {
                return comments.map((comment: CommentNormalized) => {
                    return {
                        ...comment,
                        createdBy: users[comment.createdBy] ?? SpecialUsers.EliasSupport,
                        resolvedBy: comment.resolvedBy ? users[comment.resolvedBy] ?? SpecialUsers.EliasSupport : null,
                        replies: comment.replies.map((reply) => {
                            return {
                                ...reply,
                                createdBy: users[reply.createdBy] ?? SpecialUsers.EliasSupport,
                            };
                        }),
                    };
                });
            })
        );
    }

    selectStatistics(): Observable<CommentStatistics> {
        return this.select('statistics');
    }

    getStatistics(): CommentStatistics {
        return this.getValue().statistics;
    }

    getFilters(): CommentsState['ui']['filters'] {
        return this.getValue().ui.filters;
    }

    getSorting(): SortingOptions {
        return this.getValue().ui.sorting;
    }

    getCurrentPage(): number {
        return this.getValue().currentPage;
    }

    hasNextPage(): boolean {
        return this.getValue().hasNextPage;
    }

    getNextPage(): number | null {
        return this.getValue().ui.page;
    }

    getTarget(): CommentsTarget | null {
        return this.getValue().target;
    }
}
