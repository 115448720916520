import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExcelStore } from './excel.store';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { Excel } from './excel.model';
import { Observable, throwError } from 'rxjs';
import { RequestDto } from '../../models/request-dto.model';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class ExcelService {
    constructor(private excelStore: ExcelStore, private domainService: DomainService, private http: HttpClient) {}

    getExcelForSection(payload: RequestDto): Observable<Excel[]> {
        this.excelStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/excel`;
        return this.http.get<Excel[]>(url).pipe(
            tap((excel) => {
                this.excelStore.upsertMany(excel);
                this.excelStore.setLoading(false);
                this.excelStore.update({ loaded: true });
            }),
            catchError((error) => throwError(error))
        );
    }

    loadExcel(payload: RequestDto): Observable<any> {
        this.excelStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/excel?ids=${payload.getParam('excelIds')}`;

        return this.http.get<Excel[]>(url).pipe(
            tap((excel) => {
                this.excelStore.upsertMany(excel);
                this.excelStore.setLoading(false);
                this.excelStore.update({ loaded: true });
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
}
