import { Component, Input } from '@angular/core';
import { AvatarSize } from '../../types/avatar-size.type';

@Component({
    selector: 'elias-shared-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
    @Input() backgroundColor?: string;
    @Input() disabled: boolean = false;
    @Input() hideToolTip = false;
    @Input() image?: string;
    @Input() initials: string = '';
    @Input() label: string = '';
    @Input() size: AvatarSize = 'small';
}
