import { ID, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Role } from '../../../../editor/editor/enums/role.enum';

export interface LoggedInUserState {
    id: ID;
    username: string;
    email: string;
    name: string;
    roles: Role[];
    firstName: string;
    lastName: string;
    mobile: string;
    locale: string;
    publicationView: string;
    gender: string;
    profileImage: string;
    enabled: boolean;
    profileImageContent: string;
    loaded: boolean;
    loading: boolean;
    twoFactorAuthenticationMethod: string;
    lastLogin: string;
}

export function createInitialState(): LoggedInUserState {
    return {
        id: null,
        username: null,
        email: null,
        name: null,
        roles: null,
        firstName: null,
        lastName: null,
        mobile: null,
        locale: null,
        publicationView: null,
        gender: null,
        profileImage: null,
        enabled: null,
        profileImageContent: null,
        loaded: false,
        loading: false,
        twoFactorAuthenticationMethod: null,
        lastLogin: null,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'LoggedInUser' })
export class LoggedInUserStore extends Store<LoggedInUserState> {
    constructor() {
        super(createInitialState());
    }
}

export const loggedInUserStore = new LoggedInUserStore();
