import { Injectable } from '@angular/core';
import { KeyValueOption } from '../../../../shared/models/key-value-option.model';
import { SortableSelectValue } from '../../../models/sortable-select-value.model';

@Injectable({
    providedIn: 'root',
})
export class FormSortableSelectService {
    public mergeProvidedOptionsWithFieldValue(
        options: KeyValueOption[],
        fieldValue: SortableSelectValue
    ): SortableSelectValue {
        const result: SortableSelectValue = {};

        const optionsKeys = options.map((data) => data.key);

        // Copy all available fieldValue properties
        for (const [key, value] of Object.entries(fieldValue)) {
            if (optionsKeys.includes(key)) {
                result[key] = value;
            }
        }

        // Add all remaining options
        for (const option of options) {
            if (!result.hasOwnProperty(option.key)) {
                result[option.key] = false;
            }
        }

        return result;
    }
}
