import { QueryEntity } from '@datorama/akita';
import { AssetCollectionsState, AssetCollectionsStore, assetCollectionsStore } from './asset-collections.store';
import { AssetCollection } from './asset-collection.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AssetCollectionsQuery extends QueryEntity<AssetCollectionsState, AssetCollection> {
    constructor(protected store: AssetCollectionsStore) {
        super(store);
    }
}

export const assetCollectionsQuery = new AssetCollectionsQuery(assetCollectionsStore);
