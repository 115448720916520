import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Language } from './language.model';

export interface SystemInformationState {
    allowedNumberOfUsers: number;
    deletedPublicationAssetsRemoveTime: number;
    domain: string;
    emailAddress: string;
    emailName: string;
    featureFlags: { [key: string]: boolean };
    languages: Language[];
    layouts: any[];
    loaded: boolean;
    loading: boolean;
    maximumUploadSize: number;
    numberOfUsers: number;
    passwordPolicyExplanation: string;
    passwordPolicyPattern: string;
    phpSpreadsheet: string;
    phpWord: string;
    publicAssetsDomain: string;
    renderer: any[];
    sectionLockExpiringTime: number;
    smsAccountName: string;
    smsEnabled: boolean;
    twoFactorAuthMethods: any[];
    updateInterval: number;
    validPhoneNumberPattern: string;
    version: string;
}

export function createInitialState(): SystemInformationState {
    return {
        allowedNumberOfUsers: null,
        deletedPublicationAssetsRemoveTime: null,
        domain: null,
        emailAddress: null,
        emailName: null,
        featureFlags: {},
        languages: null,
        layouts: null,
        loaded: null,
        loading: null,
        maximumUploadSize: null,
        numberOfUsers: null,
        passwordPolicyExplanation: null,
        passwordPolicyPattern: null,
        phpSpreadsheet: null,
        phpWord: null,
        publicAssetsDomain: null,
        renderer: null,
        sectionLockExpiringTime: null,
        smsAccountName: null,
        smsEnabled: null,
        twoFactorAuthMethods: null,
        updateInterval: null,
        validPhoneNumberPattern: null,
        version: null,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'SystemInformation' })
export class SystemInformationStore extends Store<SystemInformationState> {
    constructor() {
        super(createInitialState());
    }
}

export const systemInformationStore = new SystemInformationStore();
