import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Comment } from '../../models/comment.model';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';

@Component({
    selector: 'elias-comment-action-editable',
    templateUrl: './comment-action-editable.component.html',
    styleUrls: ['./comment-action-editable.component.scss'],
})
export class CommentActionEditableComponent implements OnChanges {
    public resolverName: string | null = null;

    @Input() comment?: Comment;
    @Input() showControls: boolean = true;

    @Output() commentResolved = new EventEmitter<void>();
    @Output() commentUnresolved = new EventEmitter<void>();
    @Output() commentReplied = new EventEmitter<void>();

    constructor(private usersUtilsService: UsersUtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['comment']) {
            this.resolverName = this.comment?.resolvedBy
                ? this.usersUtilsService.getFullName(this.comment.resolvedBy)
                : null;
        }
    }

    public resolveComment(e: MouseEvent): void {
        e.stopPropagation();
        this.commentResolved.emit();
    }

    public unresolveComment(e: MouseEvent): void {
        e.stopPropagation();
        this.commentUnresolved.emit();
    }

    public reply(e: MouseEvent): void {
        e.stopPropagation();
        this.commentReplied.emit();
    }
}
