import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'elias-editor-display-colbreak',
    templateUrl: './display-col-break.component.html',
})
export class DisplayColBreakComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    ngOnInit() {
        this.config = this.config || { type: 'col-break' };
    }
}
