<div class="asset-view-container">
    <div fxLayout="row" class="content" fxLayoutGap="20px">
        <ng-container *ngIf="asset$ | async as asset">
            <ng-container *ngIf="asset.type === 'image'">
                <div class="image">
                    <elias-shared-custom-image
                        class="custom-image"
                        [id]="asset.id"
                        [allowFallbackImage]="true"></elias-shared-custom-image>
                </div>
            </ng-container>
            <ng-container *ngIf="asset.type === 'excel'">
                <ng-container [ngSwitch]="asset.mimeType">
                    <ng-container *ngSwitchCase="'application/vnd.ms-excel'">
                        <i class="ei ei-file-excel"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'application/octet-stream'">
                        <i class="ei ei-file-excel"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                        <i class="ei ei-file-xls"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'application/word'">
                        <i class="ei ei-file-doc"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'application/pdf'">
                        <i class="ei ei-file-pdf"></i>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <i class="ei ei-file-doc"></i>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div>
                <table>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.name' | translate }}:</td>
                        <td>{{ asset.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.createdAt' | translate }}:</td>
                        <td>
                            {{ asset.createdAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetView.updatedAt' | translate }}:</td>
                        <td>
                            {{ asset.updatedAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr *ngIf="assetsService.isExcel(asset)">
                        <td>{{ 'assetBrowser.assetView.excel.processedAt' | translate }}:</td>
                        <td *ngIf="asset.processedAt === null && asset.processing === false">
                            {{ 'assetBrowser.assetView.excel.waitingForProcessing' | translate }}
                        </td>
                        <td *ngIf="asset.processing === true">
                            {{ 'assetBrowser.assetView.excel.processing' | translate }}
                        </td>
                        <td *ngIf="asset.processedAt !== null && asset.processing === false">
                            {{ asset.processedAt | localizedDate : 'eliasVerboseDateTime' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'assetBrowser.assetStorage' | translate }}:</td>
                        <td *ngIf="asset.storage.type === 'publication-group'">
                            {{ 'assetBrowser.assetstorages.publication-group' | translate }}
                        </td>
                        <td *ngIf="asset.storage.type === 'general'">
                            {{ 'assetBrowser.assetstorages.general' | translate }}
                        </td>
                        <td *ngIf="asset.storage.type === 'shared'">{{ asset.storage.name }}</td>
                    </tr>
                </table>
            </div>
        </ng-container>
    </div>

    <div *ngIf="assetsService.hasError(asset$ | async)">
        <mat-accordion class="processing-error">
            <mat-expansion-panel class="processing-error-panel">
                <mat-expansion-panel-header class="processing-error-header">
                    <mat-panel-title class="processing-error-title">
                        <i class="ei ei-error-full error-icon"></i>
                        <p>
                            <span class="bold">{{ 'assetBrowser.assetView.excel.processingError' | translate }}: </span>
                            {{ 'assetBrowser.assetView.excel.showMore' | translate }}
                        </p>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="processing-error-details">
                    <div class="worksheet-errors" *ngFor="let worksheet of processingErrorMessages$ | async">
                        <div class="bold">{{ worksheet.name }}</div>
                        <div>{{ worksheet.error }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
