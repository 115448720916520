import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'elias-form-element-select',
    templateUrl: './form-select.component.html',
})
export class FormSelectComponent extends BasicFormElementComponent implements OnInit {
    @Input()
    options: any;

    @Input()
    settings;

    @ViewChild(MatSelect) matSelect: MatSelect;

    selectedValue: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.selectedValue = this.initValue;
        }
        this.formGroup.get(this.name).setValue(this.selectedValue);
    }

    trackByIndex(index, item) {
        return index;
    }
}
