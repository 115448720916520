import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ChartsQuery } from '../../../state/charts/charts.query';

@Component({
    selector: 'elias-editor-display-chart',
    templateUrl: './display-chart.component.html',
})
export class DisplayChartComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    _id: string;
    path: SafeUrl | string;

    constructor(private sanitizer: DomSanitizer, private chartsQuery: ChartsQuery) {}

    ngOnInit() {
        this._id = this.node.id;

        this.chartsQuery.selectAll().subscribe((charts) => {
            this.path = this.getPath();
        });
    }

    getPath() {
        if (this._id && this.chartsQuery.hasEntity(this._id)) {
            return this.sanitizer.bypassSecurityTrustUrl(this.chartsQuery.getEntity(this._id).content);
        }
    }
}
