<div class="system-create-container">
    <i class="ei ei-close close-icon" (click)="onCancel()"></i>
    <elias-shared-custom-stepper #stepper (cancel)="onCancel()" linear [mode]="'create-section'">
        <elias-shared-custom-step
            (stepIncreased)="createSection(nameStepForm.formGroup)"
            title="{{ 'modal.createSection.' + type + '.title' | translate }}"
            subtitle="{{ 'modal.createSection.' + type + '.subtitle' | translate }}"
            class="basic-step"
            [isSave]="nameStepForm.canSave()"
            [hideDots]="true">
            <div class="step-container">
                <elias-form [formConfig]="nameStepControls" [hideButtons]="true" #nameStepForm></elias-form>
            </div>
        </elias-shared-custom-step>
    </elias-shared-custom-stepper>
</div>
