import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Asset } from './asset.model';
import { Injectable } from '@angular/core';

export interface AssetsState extends EntityState<Asset> {
    loaded: boolean;
    ui: {
        viewOption: number;
        filters: {
            collection: string | null;
            storage: string | null;
            fileType: string | null;
        };
        sort: {
            key: string | null;
            direction: string | null;
        };
    };
    totalNumberOfPages: number;
    currentPage: number;
    totalDataLength: number;
}

const initialState = {
    loaded: false,
    ui: {
        viewOption: 0,
        filters: {
            collection: null,
            storage: null,
            fileType: null,
        },
        sort: {
            key: null,
            direction: null,
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Assets', resettable: true })
export class AssetsStore extends EntityStore<AssetsState, Asset> {
    constructor() {
        super(initialState);
    }
}

export const assetsStore = new AssetsStore();
