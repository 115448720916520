import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Observable } from 'rxjs';

@Component({
    selector: 'elias-editor-display-image',
    templateUrl: './display-image.component.html',
    styleUrls: ['./display-image.component.scss'],
})
export class DisplayImageComponent implements NodeRepresentation, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    @Output() contentChange = new EventEmitter<any>();

    aspectRatio: string;
    path: string;
    parsedContent;
    itemFieldSettings;
    order;
    objectData = [];
    objectKeys = Object.keys;

    constructor() {}

    ngOnInit() {
        this.parsedContent = this.content ? JSON.parse(this.content) : {};
        for (const key of Object.keys(this.parsedContent)) {
            this.objectData[key] = this.parsedContent[key] || '';
        }
        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }
        this.itemFieldSettings = this.node.itemFieldSettings ? JSON.parse(this.node.itemFieldSettings) : {};
        this.order = Object.keys(this.itemFieldSettings);
        this.formatData();
    }

    formatData() {
        this.objectData = [];
        const content = this.content ? JSON.parse(this.content) : {};
        const res = Object.keys(content)
            .map((a) => ({ [a]: content[a] }))
            .sort((a, b) => this.order.indexOf(Object.keys(a)[0]) + 1 - (this.order.indexOf(Object.keys(b)[0]) + 1));

        const newData = res.reduce(function (r, c) {
            return Object.assign(r, c);
        }, {});
        this.objectData.push(newData);
    }

    trackByIndex(index, item) {
        return index;
    }
}
