import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    Type,
    ViewChild,
} from '@angular/core';
import { NodeType } from '../../types/node-types.type';
import { NodeRepresentation } from '../../interfaces/node-representation.interface';
import { NodeDirective } from '../../directives/node.directive';
import { isNodeEdit } from '../../interfaces/node-edit.interface';
import { Node } from '../../state/nodes/node.model';
import { defaultNodeComponent, NodeComponent, NodeComponentMap, nodeComponents } from './node-components';
import { NodeViewModel } from '../../viewmodels/node.viewmodel';
import { SaveService } from '../../services/save.service';
import { NodesService } from '../../state/nodes/nodes.service';
import { NodeDto } from '../../models/node-dto.model';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { PropertyEditorComponent } from '../property-editor/property-editor.component';
import { SectionsQuery } from '../../state/sections/sections.query';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { NodeCommentsSidenavService } from '../../../comments/services/sidenav/node-comments-sidenav.service';

@Component({
    selector: 'elias-editor-node-editable',
    styleUrls: ['./node-editable.component.scss'],
    templateUrl: './node-editable.component.html',
    // use Default strategy instead of OnPush since image node was not possible to implement with onPush
    changeDetection: ChangeDetectionStrategy.Default,
})
export class EditableNodeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() node: Node;
    @Input() editing;

    @Output() contentChange = new EventEmitter<any>();
    @Output() update = new EventEmitter<Node>();
    @Output() move = new EventEmitter<Node>();
    @Output() remove = new EventEmitter<Node>();

    @ViewChild(NodeDirective, { static: true }) nodeHost: NodeDirective;

    content: string;

    private currentComponentRef: ComponentRef<NodeRepresentation>;
    private currentNodeComponent: NodeComponent;
    myNodeComponent: NodeComponentMap;
    subs: Subscription;
    publication: Publication;

    isValidType(type: string): type is NodeType {
        return type in NodeType && type in this.myNodeComponent;
    }

    constructor(
        public elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private nc: nodeComponents,
        private nodeCommentsSidenavService: NodeCommentsSidenavService,
        private nodeService: NodesService,
        private nodeViewModel: NodeViewModel,
        private publicationsQuery: PublicationsQuery,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery,
        private sideNavService: SideNavService
    ) {
        this.publication = this.publicationsQuery.getActive() as Publication;
        this.myNodeComponent = this.nc.getConfiguration(this.publication?.locale);
        this.placeDragIcon();
    }

    ngOnInit() {
        this.content = this.node.content;

        const scroll = fromEvent(document.querySelector('.scroll-container'), 'scroll');
        scroll.pipe(throttleTime(500)).subscribe(() => this.placeDragIcon());
        /* const source = interval(1000);
        this.subs = source.subscribe(() => this.placeDragIcon());*/
    }

    placeDragIcon() {
        const element = document.getElementsByClassName('content-wrapper-for-hover');
        for (let i = 0; i < element.length; i++) {
            if (element[i].getBoundingClientRect().height > window.innerHeight) {
                const currentParentElement = element[i].parentElement;
                if (
                    currentParentElement &&
                    element[i].getBoundingClientRect().top < 93 &&
                    element[i].getBoundingClientRect().bottom > window.innerHeight
                ) {
                    const icon = currentParentElement.children[1] as HTMLElement;
                    if (icon && icon.classList.contains('node-buttons-left')) {
                        icon.style.top = window.innerHeight / 2 + 'px';
                    }
                }

                if (currentParentElement && element[i].getBoundingClientRect().bottom > window.innerHeight) {
                    const icon = currentParentElement.children[1] as HTMLElement;
                    if (icon && icon.classList.contains('node-buttons-left')) {
                        // - 200 px - this will place button in the region of the current node content
                        icon.style.top = window.innerHeight - element[i].getBoundingClientRect().top - 200 + 'px';
                    }
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const nodeChange: SimpleChange = changes.node;
        if (nodeChange) {
            this.onNodeChange(nodeChange.previousValue, nodeChange.currentValue);
        }
    }

    onNodeChange(oldNode: Node, newNode: Node) {
        if (!oldNode || oldNode.editing !== newNode.editing || JSON.stringify(oldNode) !== JSON.stringify(newNode)) {
            this.updateComponent();
        }
    }

    onPropertyEditorOpen(event) {
        event.stopPropagation();
        this.saveService.saveNode(true).subscribe(() => {
            const inputs = {
                sectionOrNodeType: 'node',
                sectionId: this.sectionsQuery.getActiveId(),
                nodeId: this.node.id,
            };

            const outputs = {};
            this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
        });
    }

    async openComments(event: MouseEvent, preselectedCommentId?: string): Promise<void> {
        event.stopPropagation();
        await this.nodeCommentsSidenavService.open(this.node, preselectedCommentId);
    }

    getNodeRepresentation(type: NodeType) {
        return this.myNodeComponent[type][this.node.editing ? 'editor' : 'display'];
    }

    getNodeComponent(type: string): NodeComponent {
        if (this.isValidType(type)) {
            return Object.assign({}, defaultNodeComponent, this.getNodeRepresentation(type));
        }
        return defaultNodeComponent;
    }

    setComponentInputs() {
        this.currentComponentRef.instance.content = this.node.content;
        this.currentComponentRef.instance.config = this.currentNodeComponent.config;
        this.currentComponentRef.instance.node = this.node;

        if (this.node.editing) {
            this.currentComponentRef.instance.nodeViewModel = this.nodeViewModel.node$;
        }
    }

    setComponentOutputs() {
        if (isNodeEdit(this.currentComponentRef.instance)) {
            this.currentComponentRef.instance.contentChange.subscribe((content: String) =>
                this.onContentChange(content)
            );
            this.currentComponentRef.instance.save.subscribe((content: string) => this.onSave(content));
        }
    }

    updateComponent() {
        const nodeComponent = this.getNodeComponent(this.node.type);

        if (!(this.currentNodeComponent === nodeComponent)) {
            this.currentNodeComponent = nodeComponent;
            this.loadComponent(this.currentNodeComponent.component);
            this.setComponentInputs();
            this.setComponentOutputs();
        }
    }

    loadComponent(component: Type<NodeRepresentation>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.nodeHost.viewContainerRef.clear();
        this.currentComponentRef = this.nodeHost.viewContainerRef.createComponent(componentFactory);
    }

    /**
     * Emit update event for the node.
     *
     * Currently called by the save button.
     * TODO: Call when losing focus, needs to be done on the document or even higher
     *
     * @param {string} content
     */
    onSave(content: string) {
        this.onContentChange(content);
        this.update.emit(this.node);
    }

    onRemove(event) {
        event.stopPropagation();
        this.remove.emit(this.node);
    }

    onContentChange(content) {
        this.contentChange.emit(content);
        this.content = content;
        this.saveService.setContent(content);
        this.node = { ...this.node, content };
    }

    ngOnDestroy() {
        if (this.subs) this.subs.unsubscribe();
    }

    review(event) {
        event.stopPropagation();
        const payload = new NodeDto({ nodeId: this.node.id }, {}, { needsReview: !this.node.needsReview });
        this.nodeService.updateNode(payload).subscribe();
    }
}
