import { Component, Input } from '@angular/core';
import { NodeRepresentation } from '../../interfaces/node-representation.interface';
import { NodeType } from '../../types/node-types.type';
import { Node } from '../../state/nodes/node.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'elias-editor-node-unknown',
    template: '<strong>Undefined node type «{{node.type}}» with content </strong>«{{content}}»',
})
export class UnknownNodeComponent implements NodeRepresentation {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;
}
