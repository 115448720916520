import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { LocksService, SectionsService } from '../services';
import { Lock } from '../state/locks/lock.model';
import { LockDto } from '../models/lock-dto.model';
import { TreeService } from '../services/tree.service';
import { FlashMessageService } from '../../../modules/core/services/flash-message.service';
import { UrlParamsService } from '../../../modules/core/services/url-params.service';

@Injectable()
export class SectionLockGuard implements CanActivate {
    constructor(
        private flashMessageService: FlashMessageService,
        private lockService: LocksService,
        private sectionsService: SectionsService,
        private treeService: TreeService,
        private urlParamsService: UrlParamsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const sectionId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'sectionId');

        if (!sectionId) {
            return of(false);
        }

        return this.lockSection(sectionId).pipe(
            tap(() => {
                this.treeService.setActive(sectionId);
                this.sectionsService.setActiveSectionById(sectionId);
            }),
            catchError((error) => {
                this.flashMessageService.showError(error.error.message);
                this.treeService.removeFocus(sectionId);

                return of(false);
            })
        );
    }

    private lockSection(id: string): Observable<boolean> {
        const payload = new LockDto({ sectionId: id });

        return this.lockService.addLock(payload).pipe(
            map((locks: Lock[]) => locks[0].hasOwnProperty('section')),
            take(1)
        );
    }
}
