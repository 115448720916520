import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Lock } from './lock.model';
import { LockDto } from '../../models/lock-dto.model';
import { LocksStore } from './locks.store';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { SectionsStore } from '../sections/sections.store';
import { ID } from '@datorama/akita';

@Injectable()
export class LocksService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private locksStore: LocksStore,
        private publicationsQuery: PublicationsQuery,
        private sectionsStore: SectionsStore
    ) {}

    getLocksForPublication(publicationId: string): Observable<Lock[]> {
        this.locksStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${publicationId}/locks`;

        return this.http.get<Lock[]>(url).pipe(
            tap((locks) => {
                this.locksStore.set(locks);
                this.locksStore.setLoading(false);
                this.locksStore.update({ loaded: true });
                locks.forEach((lock) => {
                    this.sectionsStore.update(lock['sectionId'], { locked: true });
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    addLock(payload: LockDto): Observable<Lock[]> {
        this.locksStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/lock`;
        return this.http.post<Lock[]>(url, payload.body).pipe(
            tap((locks) => {
                this.locksStore.set(locks);
                this.sectionsStore.upsertMany(locks.map((lock) => lock.section));
                this.locksStore.setLoading(false);
                this.sectionsStore.update(null, { locked: false });
                locks.forEach((lock) => {
                    this.sectionsStore.update(lock['sectionId'], { locked: true });
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteLock(id: ID) {
        this.locksStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${id}/lock`;
        return this.http.delete<Lock>(url).pipe(
            tap((lock) => {
                this.locksStore.remove((lock) => lock.section.id === id);
                this.locksStore.setLoading(false);
                this.sectionsStore.update(id, { locked: false });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    resetLocks(locks: Lock[]) {
        this.locksStore.setLoading(true);
        this.locksStore.set(locks);
        this.locksStore.setLoading(false);
        this.sectionsStore.update(null, { locked: false });
        locks.forEach((lock) => {
            this.sectionsStore.update(lock['sectionId'], { locked: true });
        });
    }
}
