<div class="pe row">
    <div
        *ngFor="let keyFigureCol of keyImages; trackBy: trackByIndex"
        class="col-{{ cols }} keyimage-{{ keyImageLayout }}">
        <div *ngFor="let keyImageItem of keyFigureCol; trackBy: trackByIndex" class="keyimage">
            <div class="col-12 image">
                <elias-shared-custom-image
                    [id]="keyImageItem.image"
                    [aspectRatio]="aspectRatio"></elias-shared-custom-image>
            </div>
            <div class="row row-text">
                <div class="col-8 label" [innerHTML]="keyImageItem.label1"></div>
                <div class="col-4 value" [innerHTML]="keyImageItem.value1"></div>
            </div>
            <div class="row row-text">
                <div class="col-8 label" [innerHTML]="keyImageItem.label2"></div>
                <div class="col-4 value" [innerHTML]="keyImageItem.value2"></div>
            </div>
            <div class="row row-text">
                <div class="col-8 label" [innerHTML]="keyImageItem.label3"></div>
                <div class="col-4 value" [innerHTML]="keyImageItem.value3"></div>
            </div>
        </div>
    </div>
</div>
