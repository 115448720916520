import { User } from '../../../modules/shared/state/users/user.model';
import { ID } from '@datorama/akita';

export class CommentEditorOutputDto {
    constructor(private content: string, private mentionedUserIds: ID[], private mentionedGroups: string[]) {}

    public getContent(): string {
        return this.content;
    }

    public getMentionedUserIds(): ID[] {
        return this.mentionedUserIds;
    }

    public getMentionedGroups(): string[] {
        return this.mentionedGroups;
    }
}
