import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { Channel } from '../../../../shared/state/channels/channel.model';
import { ChannelsQuery } from '../../../../shared/state/channels/channels.query';
import { ChannelsService } from '../../../../shared/state/channels/channels.service';

@Component({
    selector: 'elias-form-element-channel',
    templateUrl: './form-channel.component.html',
    styleUrls: ['./form-channel.component.scss'],
})
export class FormChannelComponent extends BasicFormElementComponent implements OnInit {
    @Input()
    multiple = false;

    allChannels$: Observable<Channel[]>;
    selectedChannels: ID[] = [];

    constructor(
        private fb: FormBuilder,
        private channelsQuery: ChannelsQuery,
        private channelsService: ChannelsService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (!this.channelsQuery.getValue().loaded) {
            this.channelsService.getChannels().subscribe();
        }

        this.allChannels$ = this.channelsQuery.selectAll();

        if (this.initValue) {
            this.selectedChannels = this.initValue;
        }
        this.formGroup.get(this.name).setValue(this.selectedChannels);
    }

    toggleActivation(channel: Channel) {
        if (this.multiple) {
            const index = this.selectedChannels.indexOf(channel.id);
            if (index > -1) {
                this.selectedChannels.splice(index, 1);
            } else {
                this.selectedChannels.push(channel.id);
            }
        } else {
            this.selectedChannels = [channel.id];
        }
        this.formGroup.get(this.name).setValue(this.selectedChannels);
    }

    isActive(channel: Channel) {
        if (this.multiple) {
            const index = this.selectedChannels.indexOf(channel.id);
            return index > -1;
        } else {
            return this.selectedChannels.length > 0 && this.selectedChannels[0] == channel.id;
        }
    }

    trackByItemId(index, item) {
        return item.id;
    }
}
