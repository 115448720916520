import { EventEmitter, Injectable } from '@angular/core';
import { CommentsService } from '../../state/comments.service';
import { CommentTargetType } from '../../enums/comment-target-type.enum';
import { CommentStatus } from '../../enums/comment-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { CommentListComponent } from '../../components/comment-list/comment-list.component';
import { switchMap } from 'rxjs/operators';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { CommentsStore, CommentsTarget } from '../../state/comments.store';
import { Section } from '../../../editor/state/sections/section.model';
import { CommentsFacade } from '../comments-facade.service';

@Injectable({
    providedIn: 'root',
})
export class SectionCommentsSidenavService {
    constructor(
        private commentsFacade: CommentsFacade,
        private commentsService: CommentsService,
        private commentsStore: CommentsStore,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    public async open(section: Section, preselectedCommentId?: string): Promise<void> {
        this.commentsFacade.resetStore();

        const target: CommentsTarget = { id: section.id, type: CommentTargetType.Section };
        this.commentsStore.update({ target });

        this.commentsService.getComments(CommentTargetType.Section, section.id as string).subscribe();
        await this.createComponent(section, preselectedCommentId);
    }

    private async createComponent(section: Section, preselectedCommentId?: string): Promise<void> {
        const inputs = {
            commentStatus: CommentStatus.Editable,
            title: this.translateService.instant('comments.title', { title: section.title }),
            preselectedCommentId,
        };

        const commentCancelled = new EventEmitter();
        const outputs = {
            commentCancelled,
        };

        const commentsCancelled$ = commentCancelled.pipe(switchMap(() => this.sideNavService.close()));
        commentsCancelled$.subscribe();

        await this.sideNavService.setComponent(CommentListComponent, inputs, outputs);
    }
}
