import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AssetsService } from '../../../../modules/shared/state/assets/assets.service';
import { RequestDto } from '../../../editor/models/request-dto.model';
import { map } from 'rxjs/operators';
import { Asset } from '../../../../modules/shared/state/assets/asset.model';
import { saveAs } from 'file-saver';
import { FormComponent } from '../../../../modules/forms/components/form/form.component';
import { CategoriesQuery } from '../../../../modules/shared/state/categories/categories.query';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { Router } from '@angular/router';
import { PublicationsService } from '../../../../publication/state/publication/publications.service';
import { SectionsQuery } from '../../../editor/state/sections/sections.query';
import { EditorContainerService } from '../../../editor-container/services/editor-container.service';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { PublicationGroupQuery } from '../../../../publication-group/state/publication-group/publication-group.query';
import { PublicationGroup } from '../../../../publication-group/state/publication-group/publication-group.model';
import { SystemInformationQuery } from '../../../../modules/shared/state/system-information/system-information.query';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';

@Component({
    selector: 'elias-assetbrowser-asset-custom',
    templateUrl: './asset-custom.component.html',
    styleUrls: ['./asset-custom.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
})
export class AssetCustomComponent implements OnInit {
    @Input() data;
    @Input() formConfig: FormConfig = { elements: [] };
    @Input() isDelete = true;

    @Output() save = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();
    @Output() replace = new EventEmitter<any>();
    loaded$ = new BehaviorSubject<boolean>(true);

    isSaving = false;

    @ViewChild('form') form: FormComponent;
    assetPublicationsPartOf: any[];
    ObjectKeys = Object.keys;

    get replacementMimeTypes(): string {
        switch (this.data.mimeType) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
                return ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].join(',');

            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'application/octet-stream':
                return [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'application/octet-stream',
                ].join(',');

            default:
                return '';
        }
    }

    constructor(
        public dialog: MatDialog,
        private sideNavService: SideNavService,
        private assetsService: AssetsService,
        public categoryQuery: CategoriesQuery,
        public publicationsQuery: PublicationsQuery,
        public publicationGroupQuery: PublicationGroupQuery,
        private publicationsService: PublicationsService,
        private sectionsQuery: SectionsQuery,
        private router: Router,
        private editorContainerService: EditorContainerService,
        private systemInformationQuery: SystemInformationQuery,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {
        this.assetsService
            .getPublicationsAssetPartOf(this.data.id)
            .subscribe((data) => (this.assetPublicationsPartOf = data));
    }

    remove() {
        const modalRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'file',
            },
        });

        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                this.delete.emit(this.data);
            }
        });
    }

    onFormSubmitted(props) {
        this.isSaving = true;
        this.save.emit(props);
    }

    close() {
        this.sideNavService.close();
    }

    download() {
        const publicationGroupId = (this.publicationGroupQuery.getActive() as PublicationGroup).id.toString();
        const asset = this.data;
        const payload = new RequestDto({ asset }, { pubGroupId: publicationGroupId }, {});
        this.assetsService
            .downloadAsset(payload)
            .pipe(
                map((response: any) => {
                    const { name, extension }: Asset = payload.getParam('asset');
                    const filename = name.endsWith(`.${extension}`) ? name : `${name}.${extension}`;

                    saveAs(response, filename);
                })
            )
            .subscribe();
    }

    replaceFile(e: InputEvent) {
        const target = e.target as HTMLInputElement;
        if (!target.files || !target.files[0]) {
            return;
        }

        const file = target.files[0];
        const publicationId = this.publicationsQuery.getActiveId() as string;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('assetStorage', this.data.storage.id);
        formData.append('publication', publicationId);
        formData.append('asset', this.data.id);
        formData.append('assetType', this.data.mimeType);

        this.assetsService.uploadAsset(formData, this.data.storage.type).subscribe(
            () => {
                this.flashMessageService.showTranslatedInfo('propertyEditor.asset.success');
                this.replace.emit(this.data);
            },
            (response) => {
                this.flashMessageService.showError(response.error.error);
            }
        );
    }

    getLanguage(publication: Publication): string {
        return this.systemInformationQuery.getLanguages().get(publication.locale)?.name || '';
    }

    navigateToPublicationSection(pub) {
        this.editorContainerService.resetStore();
        if (this.publicationsQuery.hasEntity(pub.id)) {
            const publication = this.publicationsQuery.getEntity(pub.id) as Publication;
            this.router.navigateByUrl(
                '/publication-groups/' +
                    publication.publicationGroup.id +
                    '/publication/' +
                    pub.id +
                    '/editor/section/' +
                    pub.sectionId
            );
        } else {
            this.publicationsService.getSinglePublication(pub.id).subscribe((publication) => {
                this.router.navigateByUrl(
                    '/publication-groups/' +
                        publication.publicationGroup.id +
                        '/publication/' +
                        pub.id +
                        '/editor/section/' +
                        pub.sectionId
                );
            });
        }
    }
}
