import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'elias-editor-display-twocolsstart',
    templateUrl: './display-two-cols-start.component.html',
})
export class DisplayTwoColsStartComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    ngOnInit() {
        this.config = this.config || { type: 'two-cols-start' };
    }
}
