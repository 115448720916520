import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { NameStepControlForm } from '../../forms/name-step-control.form';

@Component({
    selector: 'elias-editor-new-section',
    templateUrl: './new-section.component.html',
    styleUrls: ['./new-section.component.scss'],
})
export class NewSectionComponent implements AfterViewInit {
    public nameStepControls: FormConfig = this.nameStepControlForm.getConfiguration();
    @Input() type: string;

    constructor(
        public activeModal: MatDialogRef<NewSectionComponent>,
        private changeDetector: ChangeDetectorRef,
        private flashMessageService: FlashMessageService,
        private nameStepControlForm: NameStepControlForm
    ) {}

    ngAfterViewInit() {
        this.changeDetector.detectChanges();
    }

    public createSection(formGroup) {
        if (formGroup.valid) {
            this.activeModal.close(formGroup.value);
        } else {
            Object.keys(formGroup.value).forEach((key) => {
                formGroup.get(key).markAsTouched();
            });
            this.flashMessageService.showTranslatedError('modal.createSection.error');
        }
    }

    onCancel() {
        this.activeModal.close(false);
    }
}
