<div *ngIf="loading$ | async">
    <div class="position">
        <div class="google-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="loading-content">
            <h1>{{ 'login.loadingtext' | translate }}</h1>
            <p>{{ 'login.loadingtextdescription' | translate }}</p>
        </div>
    </div>
</div>
<div *ngIf="(loading$ | async) === false">
    <div class="login-form">
        <img src="/../../../assets/logo.png" class="logo" />

        <div class="spacer-2x"></div>

        <form
            [formGroup]="loginForm"
            *ngIf="!isLoggedIn"
            class="form-row"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true">
            <h1>{{ 'login.signin' | translate }}</h1>
            <p>{{ 'login.description' | translate }}</p>
            <div class="spacer"></div>

            <div class="form-row" *ngIf="!isSaving">
                <mat-form-field class="login-control">
                    <mat-label>{{ 'login.username' | translate }}</mat-label>
                    <input
                        matInput
                        #username
                        placeholder="{{ 'login.username' | translate }}"
                        type="text"
                        formControlName="username"
                        required />
                    <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                        {{ 'login.usernameerror' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            loginForm.controls.username.hasError('maxlength') &&
                            !loginForm.controls.username.hasError('required')
                        ">
                        The username cannot be longer than 255 characters
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-row" *ngIf="!isSaving">
                <mat-form-field class="login-control">
                    <mat-label>{{ 'login.password' | translate }}</mat-label>
                    <input
                        matInput
                        #password
                        placeholder="{{ 'login.password' | translate }}"
                        formControlName="password"
                        [type]="isVisible ? 'password' : 'text'"
                        required />
                    <ng-container *ngIf="isVisible">
                        <i class="ei ei-visibility-off" (click)="isVisible = !isVisible"></i>
                    </ng-container>
                    <ng-container *ngIf="!isVisible">
                        <i class="ei ei-visibility" (click)="isVisible = !isVisible"></i>
                    </ng-container>
                    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                        {{ 'login.passworderror' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            loginForm.controls.password.hasError('maxlength') &&
                            !loginForm.controls.password.hasError('required')
                        ">
                        The password cannot be longer than 255 characters
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="loginErrorMessage && !isSaving" class="error-message">
                <i class="ei ei-error-full"></i>
                <span class="message">{{ loginErrorMessage }}</span>
            </div>

            <div class="button" *ngIf="!isSaving">
                <button mat-stroked-button *ngIf="loginForm.invalid">
                    {{ 'login.login' | translate }}
                </button>
                <button mat-flat-button color="accent" *ngIf="loginForm.valid || isSaving" (click)="login()">
                    {{ 'login.login' | translate }}
                </button>
            </div>
            <div *ngIf="isSaving">
                <div class="google-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </form>

        <form [formGroup]="twoFactor" *ngIf="isLoggedIn" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
            <h1>{{ 'login.signin' | translate }}</h1>
            <p>{{ 'login.securitycodetext' | translate }}</p>
            <div class="spacer"></div>

            <div class="form-row security-code">
                <mat-form-field class="login-control">
                    <mat-label>{{ 'login.securitycode' | translate }} </mat-label>
                    <input
                        matInput
                        #code
                        placeholder="{{ 'login.securitycode' | translate }}"
                        type="password"
                        formControlName="code"
                        [type]="isVisible ? 'password' : 'text'"
                        autofocus />
                    <ng-container *ngIf="isVisible">
                        <i class="ei ei-visibility-off" (click)="isVisible = !isVisible"></i>
                    </ng-container>
                    <ng-container *ngIf="!isVisible">
                        <i class="ei ei-visibility" (click)="isVisible = !isVisible"></i>
                    </ng-container>
                    <mat-error *ngIf="twoFactor.controls.code.hasError('required')">
                        {{ 'login.codeerror' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="secureLoginErrorMessage" class="error-message">
                <i class="ei ei-error-full"></i>
                <span class="message">{{ secureLoginErrorMessage }}</span>
            </div>

            <div class="button-security-code">
                <button mat-stroked-button *ngIf="twoFactor.invalid">
                    {{ 'login.login' | translate }}
                </button>
                <button mat-flat-button color="accent" *ngIf="twoFactor.valid" (click)="secureLogin()">
                    {{ 'login.login' | translate }}
                </button>
            </div>

            <div class="spacer"></div>

            <div>
                {{ 'login.securityCodeNotReceived' | translate }}
                <span (click)="startAgain()" class="link">{{ 'login.requestNewSecurityCode' | translate }}</span
                >.
            </div>
        </form>
    </div>

    <!--<div class="footer">
        {{ 'login.footer' | translate }}
    </div>-->

    <div class="image" [ngStyle]="{ 'background-image': homeImage }"></div>
</div>
