import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-form-element',
    templateUrl: './form-element.component.html',
})
export class FormElementComponent {
    @Input()
    formGroup;
    @Input()
    element;
    @Input()
    errors;
    @Input()
    initValue: any;
    @Input()
    translationKey: string;
    @Input()
    disabledOptions;
    @Input()
    hint: string;
    @Input()
    settings;
}
