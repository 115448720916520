import { ActiveState, EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PublicationGroup } from './publication-group.model';
import { SortingOption } from '../../../modules/shared/models/sorting-option.model';

export interface PublicationGroupState extends EntityState<PublicationGroup>, ActiveState {
    totalNumberOfPages: number;
    currentPage: number;
    totalDataLength: number;
    loaded: boolean;
    ui: {
        viewOption: number;
        filters: {
            genCategory: string[];
            pubTypeCategory: string[];
            locale: string | null;
            state: string | null;
        };
        sort: SortingOption;
        search: string;
    };
}

const initialState = {
    totalNumberOfPages: 0,
    currentPage: 1,
    totalDataLength: 0,
    loaded: false,
    ui: {
        viewOption: 0,
        filters: {
            genCategory: [],
            pubTypeCategory: [],
            locale: null,
            state: null,
        },
        sort: {
            key: null,
            direction: null,
        },
        search: null,
    },
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'PublicationGroups', resettable: true })
export class PublicationGroupsStore extends EntityStore<PublicationGroupState, PublicationGroup> {
    constructor() {
        super(initialState);
    }
}

export const publicationGroupStore = new PublicationGroupsStore();
