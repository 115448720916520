import { PublicationsStore, PublicationState } from './publications.store';
import { Publication } from './publication.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PublicationsQuery extends QueryEntity<PublicationState, Publication> {
    constructor(protected store: PublicationsStore) {
        super(store);
    }
}
