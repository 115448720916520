import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../modules/shared/shared.module';
import { AssetBrowserComponent } from './components/asset-browser/asset-browser.component';
import { AssetBrowserFilesComponent } from './components/asset-browser-files/asset-browser-files.component';
import { FullscreenAssetBrowserComponent } from './pages/fullscreen-asset-browser/fullscreen-asset-browser.component';
import { AssetBrowserRoutingModule } from './asset-browser-routing.module';
import { AssetCustomComponent } from './components/asset-custom/asset-custom.component';
import { EliasFormsModule } from '../../modules/forms/forms.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlLocale } from './components/asset-browser-files/MatPaginatorIntlLocale';
import { AssetDropzoneUploadComponent } from './components/asset-dropzone-upload/asset-dropzone-upload.component';
import { AssetBrowserDataResolver } from './resolvers/asset-browser-data.resolver';
import { AssetBrowserDeepLinksResolver } from './resolvers/asset-browser-deep-links.resolver';

@NgModule({
    imports: [CommonModule, SharedModule, AssetBrowserRoutingModule, EliasFormsModule],
    declarations: [
        AssetBrowserComponent,
        AssetBrowserFilesComponent,
        AssetBrowserComponent,
        FullscreenAssetBrowserComponent,
        AssetCustomComponent,
        AssetDropzoneUploadComponent,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlLocale,
        },
        AssetBrowserDataResolver,
        AssetBrowserDeepLinksResolver,
    ],
})
export class AssetBrowserModule {}
