import { FormConfig } from '../../../modules/forms/models/form-config.model';

export const KeyImageForm: FormConfig = {
    elements: [
        {
            name: 'image',
            label: 'Image',
            type: 'asset',
            required: false,
            width: 100,
            options: [],
            settings: {
                type: 'image',
            },
            validators: [],
            show: [],
        },
        {
            name: 'label1',
            label: 'keyimage.label1',
            type: 'textarea',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'value1',
            type: 'textarea',
            label: 'keyimage.value1',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'label2',
            label: 'keyimage.label2',
            type: 'textarea',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'value2',
            type: 'textarea',
            label: 'keyimage.value2',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'label3',
            label: 'keyimage.label3',
            type: 'textarea',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'value3',
            label: 'keyimage.value3',
            type: 'textarea',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
    ],
};
