import { Injectable } from '@angular/core';
import { CommentDraftDto } from '../models/comment-draft-dto.model';
import { LoggedInUserQuery } from '../../../modules/shared/state/logged-in-user/logged-in-user.query';
import { CommentsTarget } from '../state/comments.store';

@Injectable({
    providedIn: 'root',
})
export class CommentDraftsService {
    constructor(private loggedInUserQuery: LoggedInUserQuery) {}

    public getDraft(target: CommentsTarget): CommentDraftDto | null {
        const userId = this.loggedInUserQuery.getValue().id;
        const commentDraft = localStorage.getItem(this.getLocalStorageKey(userId, target));

        if (commentDraft === null) {
            return null;
        }

        const { content, repliedCommentId } = JSON.parse(commentDraft);

        return new CommentDraftDto(content, repliedCommentId);
    }

    public removeDraft(target: CommentsTarget): void {
        const userId = this.loggedInUserQuery.getValue().id;

        localStorage.removeItem(this.getLocalStorageKey(userId, target));
    }

    public saveDraft(commentDraft: CommentDraftDto, target: CommentsTarget): void {
        const userId = this.loggedInUserQuery.getValue().id;

        const content = commentDraft.getContent();
        const repliedCommentId = commentDraft.getRepliedCommentId();

        const jsonContent = {
            content,
            repliedCommentId,
        };

        // By default editor contains empty paragraph
        if (content === '<p></p>') {
            this.removeDraft(target);
            return;
        }

        localStorage.setItem(this.getLocalStorageKey(userId, target), JSON.stringify(jsonContent));
    }

    private getLocalStorageKey(userId: number | string, target: CommentsTarget) {
        const { id: targetId, type: targetType } = target;

        return `autosave/comment/${userId}/${targetType}/${targetId}`;
    }
}
