import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Section } from './section.model';
import { Injectable } from '@angular/core';

export interface SectionsState extends EntityState<Section> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Sections', resettable: true })
export class SectionsStore extends EntityStore<SectionsState, Section> {
    constructor() {
        super({ loaded: false });
    }
}

export const sectionsStore = new SectionsStore();
