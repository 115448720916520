import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { GridNode } from '../../../models/node/grid-node.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'elias-editor-display-keyfigures',
    templateUrl: './display-keyfigures.component.html',
    styleUrls: ['display-keyfigures.component.scss'],
})
export class DisplayKeyfiguresComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: GridNode;
    @Input() nodeViewModel: Observable<GridNode>;

    keyFigures: any[] = [];
    cols = 2;
    keyFigureLayout = '';

    ngOnInit() {
        this.parseContent();
        this.cols = 12 / this.node.cols;
        this.keyFigureLayout = this.node.layout;
    }

    parseContent() {
        this.keyFigures = JSON.parse(this.content);
    }

    trackByIndex(index, item) {
        return index;
    }
}
