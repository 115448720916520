<div [ngSwitch]="config.type" style="word-break: break-word">
    <blockquote [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'quote'"></blockquote>
    <div class="lead" [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'lead'"></div>
    <div
        class="{{ titleClass }}"
        [ngClass]="{ 'display-inline-block': additionalText }"
        [innerHTML]="titleContent || '&nbsp;' | html"
        *ngSwitchCase="'title'"></div>
    <ng-container *ngSwitchCase="'title'">
        <span class="additionalText" *ngIf="additionalText" [innerHTML]="additionalText | html"></span>
    </ng-container>
    <div [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'footnote'"></div>
    <div [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'disclaimer'"></div>
    <small [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'small'"></small>
    <dfn [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'big'"></dfn>
    <div
        [ngClass]="{ multicolumn: node['multiColumn'], 'custom-breaks': hasOwnColBreaks(content) }"
        class="alignment-{{ node['alignment'] }}"
        [innerHTML]="replaceColbreaks(content) || '&nbsp;' | html"
        *ngSwitchDefault></div>
</div>
