import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { Observable } from 'rxjs';
import { LineNode } from '../../../models/node/line-node.model';

@Component({
    selector: 'elias-editor-display-line',
    templateUrl: './display-line.component.html',
})
export class DisplayLineComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: LineNode;
    @Input() nodeViewModel: Observable<Node>;
    color = '';

    ngOnInit() {
        this.config = this.config || { type: 'page-break' } || { type: 'col-break' };
        this.color = this.node.color === 'custom' ? this.node.customColor : this.node.color;
        this.color = this.getCorrectFormat(this.color);
    }

    getCorrectFormat(value) {
        if (value && value.includes('#')) {
            return value;
        } else {
            return '#' + value;
        }
    }
}
