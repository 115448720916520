import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
    selector: 'elias-shared-custom-step',
    templateUrl: './custom-step.component.html',
    styleUrls: ['./custom-step.component.scss'],
})
export class CustomStepComponent extends CdkStep {
    @Input()
    title: string;

    @Input()
    subtitle = '';

    @Input()
    hideDots = false;

    @Input()
    hideBackButton = false;

    @Input()
    hideCancelButton = false;

    @Input()
    nextButtonText = false;

    @Output()
    stepIncreased = new EventEmitter<any>();

    @Input()
    isSave = false;

    @Input()
    isLoading = false;

    nextStep() {
        this.stepIncreased.emit('xyz');
    }
}
