import { Injectable } from '@angular/core';
import { LinksService } from './links.service';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { PublicationGroupQuery } from '../../../publication-group/state/publication-group/publication-group.query';
import { Comment } from '../../../editor/comments/models/comment.model';
import { CommentTargetType } from '../../../editor/comments/enums/comment-target-type.enum';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { NodeCommentLinksProvider } from '../providers/node-comment-links.provider';
import { SectionCommentLinksProvider } from '../providers/section-comment-links.provider';

@Injectable({
    providedIn: 'root',
})
export class CommentLinksService extends LinksService {
    constructor(private publicationGroupsQuery: PublicationGroupQuery, private publicationsQuery: PublicationsQuery) {
        super();
    }

    public createLinksProvider(comment: any): LinksProviderInterface | null {
        if (!this.isComment(comment)) {
            return null;
        }

        const { targetType } = comment;

        switch (targetType) {
            case CommentTargetType.Node:
                return this.createNodeCommentLinksProvider(comment);

            case CommentTargetType.Section:
                return this.createSectionCommentLinksProvider(comment);
        }

        return null;
    }

    private isComment(comment: any): comment is Comment {
        return comment.hasOwnProperty('type') && comment.type === 'comment';
    }

    private createNodeCommentLinksProvider({ routingArguments, id }: Comment): LinksProviderInterface | null {
        const publicationGroupId = this.publicationGroupsQuery.getActiveId() as string;
        const publicationId = this.publicationsQuery.getActiveId() as string;

        if (
            !publicationGroupId ||
            !publicationId ||
            !routingArguments['sectionId'] ||
            !routingArguments['nodeId'] ||
            !id
        ) {
            return null;
        }

        return new NodeCommentLinksProvider(
            publicationGroupId,
            publicationId,
            routingArguments['sectionId'],
            routingArguments['nodeId'],
            id
        );
    }

    private createSectionCommentLinksProvider({ routingArguments, id }: Comment): LinksProviderInterface | null {
        const publicationGroupId = this.publicationGroupsQuery.getActiveId() as string;
        const publicationId = this.publicationsQuery.getActiveId() as string;

        if (!publicationGroupId || !publicationId || !routingArguments['sectionId'] || !id) {
            return null;
        }

        return new SectionCommentLinksProvider(publicationGroupId, publicationId, routingArguments['sectionId'], id);
    }
}
