import { Injectable } from '@angular/core';
import { LocksService } from '../state/locks/locks.service';

@Injectable()
export class EditorService {
    constructor(private locksService: LocksService) {}

    goToSection(sectionId, saveFirst = true) {}

    resetStores(section) {
        if (section && section.id) {
            this.locksService.deleteLock(section.id).subscribe();
        }

        // We do not reset the store here anymore, it was moved to the PublicationGroupOverviewResolver in order to
        // make history filter work.
        // TODO: Rename the method to match what it is doing and check if logic can be improved
        // this.sectionsStore.reset();
    }
}
