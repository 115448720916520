import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITreeOptions, TreeComponent } from '@circlon/angular-tree-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Section } from '../../state/sections/section.model';
import {
    AspectRatiosService,
    CycleService,
    HtmlPreviewService,
    LocksService,
    NodesService,
    PreviewService,
    PropertyEditorService,
    SectionsService,
    StylesheetService,
} from '../../services';
import { Node } from '../../state/nodes/node.model';
import { SectionDto } from '../../models/section-dto.model';
import { RequestDto } from '../../models/request-dto.model';
import { NodeViewModel } from '../../viewmodels/node.viewmodel';
import { DocumentService } from '../../services/document.service';
import { TreeService } from '../../services/tree.service';
import { SelectedElement } from '../../models/selected-element.model';
import { Lock } from '../../state/locks/lock.model';
import { Subscription } from 'rxjs/Subscription';
import { TitlePipeReplacerService } from '../../services/utilities/title-pipe-replacer.service';
import { PropertyEditorComponent } from '../../components/property-editor/property-editor.component';
import { SectionsQuery } from '../../state/sections/sections.query';
import { BehaviorSubject, forkJoin, interval, of } from 'rxjs';
import { DocumentComponent } from '../document/document.component';
import { PreviewHtmlComponent } from '../../components/preview-html/preview-html.component';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { PreviewsQuery } from '../../state/previews/previews.query';
import { PropertyEditorQuery } from '../../state/property-editor/property-editor.query';
import { LocksQuery } from '../../state/locks/locks.query';
import { SaveService } from '../../services/save.service';
import { delay, first, map, tap } from 'rxjs/operators';
import { EditorService } from '../../services/editor.service';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { NodePresetsService } from '../../../../modules/shared/state/node-presets/node-presets.service';
import { NodePresetsQuery } from '../../../../modules/shared/state/node-presets/node-presets.query';
import { NodePreset } from '../../../../modules/shared/state/node-presets/node-preset.model';
import { ChannelsService } from '../../../../modules/shared/state/channels/channels.service';
import { AspectRatiosQuery } from '../../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../../../../modules/core/state/ui/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { ID } from '@datorama/akita';
import { Channel } from '../../../../modules/shared/state/channels/channel.model';
import { ChannelsQuery } from '../../../../modules/shared/state/channels/channels.query';
import { ErrorComponent } from '../../../../modules/shared/dialogs/error/error.component';
import * as _ from 'lodash';
import { LoggedInUserQuery } from '../../../../modules/shared/state/logged-in-user/logged-in-user.query';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { TreeItem, TreeUtilsService } from '../../services/utilities/tree-utils.service';

@Component({
    selector: 'elias-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'app-body';

    @ViewChild('propertyEditor') private propertyEditor: PropertyEditorComponent;

    @ViewChild(TreeComponent) private treeComponent: TreeComponent;

    treeOptions: ITreeOptions;
    sections$: Observable<TreeItem<Section>[]>;
    selectedNode$: Observable<Node>;
    activeSection$: Observable<Section>;
    nodePresets$: Observable<NodePreset[]>;
    channels$: Observable<Channel[]>;
    loading$: Observable<boolean>;
    activeSection: Section;
    selectedElement$: Observable<SelectedElement>;
    selectedElement;
    documentLoading$: Observable<boolean>;
    generatingPreview$: Observable<boolean>;
    contextMenuOptions = [];
    locks$: Observable<Lock[]>;
    userId: ID;
    userLockedSection: ID;
    focusedTreeNodeId: string;
    subscription$: Subscription;
    documentComponent: DocumentComponent;
    loaded$ = new BehaviorSubject<boolean>(false);
    subscriptions: Subscription[] = [];
    toggleView = false;
    styleTag = null;
    user;

    constructor(
        private aspectRatiosQuery: AspectRatiosQuery,
        private aspectRatiosService: AspectRatiosService,
        private channelsQuery: ChannelsQuery,
        private channelsService: ChannelsService,
        private cycleService: CycleService,
        public dialog: MatDialog,
        private documentService: DocumentService,
        private editorService: EditorService,
        private flashMessageService: FlashMessageService,
        private htmlPreviewService: HtmlPreviewService,
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private loggedInUserQuery: LoggedInUserQuery,
        private nodeService: NodesService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private nodePresetsQuery: NodePresetsQuery,
        private nodePresetsService: NodePresetsService,
        private nodeViewModel: NodeViewModel,
        private pipeReplacerService: TitlePipeReplacerService,
        private propertyEditorService: PropertyEditorService,
        private previewService: PreviewService,
        private previewsQuery: PreviewsQuery,
        private propertyEditorQuery: PropertyEditorQuery,
        private publicationsQuery: PublicationsQuery,
        private route: ActivatedRoute,
        private router: Router,
        private saveService: SaveService,
        private sectionService: SectionsService,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private stylesheetService: StylesheetService,
        private translateService: TranslateService,
        private treeService: TreeService,
        private treeUtilsService: TreeUtilsService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        this.loadData();
        this.loadStylesheet();
        this.uiService.setToolbarState('editor');

        this.user = this.loggedInUserQuery.getValue();
        this.userId = this.user.id;
        this.setUserLock();

        this.nodePresets$ = this.nodePresetsQuery.selectAll();
        this.channels$ = this.channelsQuery.selectAll();
        this.sections$ = this.sectionsQuery
            .selectAll()
            .pipe(map((sections) => this.treeUtilsService.createTreeFromArray<Section>(sections)));
        // TODO: loading observable
        this.loading$ = of(false);
        this.selectedNode$ = this.nodesQuery.select('selectedNode');
        this.activeSection$ = this.sectionsQuery.selectActive() as Observable<Section>;
        this.generatingPreview$ = this.previewsQuery.selectLoading();
        this.documentLoading$ = this.documentService.loading$;
        this.treeOptions = this.treeService.getTreeOptions();
        this.selectedElement$ = this.propertyEditorQuery.select('selectedElement');

        this.selectedElement$.pipe(delay(0)).subscribe((selectedElement: SelectedElement) => {
            this.selectedElement = selectedElement;
        });

        this.subscriptions.push(
            this.activeSection$.subscribe((section: Section) => {
                if (section) {
                    if (this.activeSection === undefined) {
                        this.treeService.loadAssets(section.id);
                    }
                    this.activeSection = section;
                }
            })
        );

        // Cycle

        this.subscriptions.push(
            interval(15000).subscribe(() => {
                this.cycleService.updateContent().subscribe((response) => {
                    if (response.body?.redirectUrl !== undefined) {
                        const returnUrl = response.body.redirectUrl;
                        this.router.navigate([returnUrl], { relativeTo: this.route });
                    }
                    if (response.body?.locks) {
                        this.locksService.resetLocks(response.body.locks);
                        this.setUserLock();
                    }
                    if (response.body?.nodes) {
                        response.body.nodes.forEach((node) => {
                            this.nodesService.updateRenderedContentInStore(node);
                        });
                    }
                    if (response.body?.sections) {
                        const activeSectionId = this.sectionsQuery.getActiveId();
                        this.sectionsService.resetSections(response.body.sections);
                        this.sectionService.setActiveSectionById(activeSectionId);
                        this.sectionsService.updateLocks();
                    }
                });
            })
        );

        this.loaded$
            .pipe(
                delay(50),
                tap((loaded) => {
                    if (loaded) {
                        if (this.treeComponent && this.treeComponent.treeModel) {
                            let node = this.treeComponent.treeModel.getNodeById(this.sectionsQuery.getActiveId());
                            if (node) {
                                node.setActiveAndVisible(true);
                            }
                        }

                        // Activate Tree Node
                        this.treeService.shouldActivate$.subscribe((sectionId: string) => {
                            this.saveOpenElementAndGoToSection(sectionId);
                        });

                        // Remove tree node focus
                        this.treeService.shouldRemoveFocus$.subscribe((sectionId: string) => {
                            if (this.treeComponent && this.treeComponent.treeModel) {
                                if (this.treeComponent.treeModel.getFocusedNode()) {
                                    this.treeComponent.treeModel.getFocusedNode().blur();
                                }
                                this.focusedTreeNodeId = '';
                            }
                        });

                        this.treeService.shouldSelect$.subscribe((sectionId: string) => {
                            if (this.treeComponent && this.treeComponent.treeModel) {
                                this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
                                    this.treeComponent.treeModel.setActiveNode(node, false);
                                });
                            }
                            setTimeout(() => {
                                if (this.treeComponent && this.treeComponent.treeModel) {
                                    let node = this.treeComponent.treeModel.getNodeById(sectionId);
                                    if (node) {
                                        node.setActiveAndVisible(true);
                                        if (!this.activeSection || this.activeSection.id != sectionId)
                                            this.saveOpenElementAndGoToSection(sectionId);
                                    }
                                }
                            }, 100);
                        });

                        // Select tree node
                        /* this.treeService.shouldSelect$.subscribe((sectionId: string) => {
                            console.log(sectionId);
                            /!*this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
                                this.treeComponent.treeModel.setActiveNode(node, false);
                            });*!/
                            if (this.treeComponent.treeModel.getNodeById(sectionId)) {
                                this.treeComponent.treeModel.getNodeById(sectionId).setActiveAndVisible(true);
                            }
                        });*/
                    }
                })
            )
            .subscribe();
    }

    loadData() {
        const observables = [];
        if (!this.locksQuery.getValue().loaded) {
            const publication = this.publicationsQuery.getActive() as Publication;
            if (publication) {
                observables.push(this.locksService.getLocksForPublication(publication.id as string));
            }
        }
        if (!this.sectionsQuery.getValue().loaded) {
            observables.push(this.sectionsService.getSections());
        }
        if (!this.nodePresetsQuery.getValue().loaded) {
            observables.push(this.nodePresetsService.getNodePresets());
        }
        if (!this.propertyEditorQuery.getValue().loaded) {
            const publication = this.publicationsQuery.getActive() as Publication;
            if (publication) {
                const rootSectionId = publication.rootSectionId;
                const requestDto = new RequestDto({ rootSectionId });
                observables.push(this.propertyEditorService.loadPropertyEditorConfigurations(requestDto));
            }
        }
        if (!this.channelsQuery.getValue().loaded) {
            observables.push(this.channelsService.getChannelsForSection());
        }
        if (!this.aspectRatiosQuery.getValue().loaded) {
            observables.push(this.aspectRatiosService.getAspectRatios());
        }

        if (observables.length > 0) {
            this.loaded$.next(false);
            forkJoin(observables)
                .pipe(first())
                .subscribe((results) => {
                    this.loaded$.next(true);
                });
        } else {
            this.loaded$.next(true);
        }
    }

    /**
     * Add style tag to DOM containing the customer style configuration provided
     * by the backend.
     */
    loadStylesheet() {
        const rootSectionId = (this.publicationsQuery.getActive() as Publication).rootSectionId;
        this.stylesheetService.getStylesheet(rootSectionId).subscribe((styleConfiguration) => {
            const style = document.createElement('style');
            style.appendChild(document.createTextNode(styleConfiguration));
            document.head.appendChild(style);

            this.styleTag = style;
        });
    }

    /**
     * Remove style tag from DOM
     */
    unloadStylesheet() {
        if (_.isElement(this.styleTag)) {
            this.styleTag.remove();
        }
    }

    onChangeProperties(data: any) {
        if (data.type === 'node') {
            this.nodeViewModel.updateProperties(data.properties);
        }
    }

    onRemoveInPropertyEditor(data: any) {
        let payload: SectionDto;
        switch (data.type) {
            case 'section':
                payload = new SectionDto(
                    { sectionId: this.selectedElement.element.id },
                    { redirect: this.selectedElement.element.parentId }
                );
                this.sectionsService.deleteSection(payload).subscribe();
                break;

            case 'node':
                payload = new RequestDto({ nodeId: this.selectedElement.element.id });
                this.nodesService.deleteNode(payload).subscribe();
        }
    }

    onGenerateWordPreview($event): void {
        this.saveService.saveAll(true).subscribe(() => {
            this.generatePreview($event);
        });
    }

    generatePreview($event) {
        const payload: RequestDto = new RequestDto({
            sectionId: this.activeSection.id,
            channelId: $event.channelId,
        });
        if ($event.renderer === 'word') {
            this.previewService.getWordSectionPreview(payload).subscribe(
                (blob) => {
                    const date = new Date();
                    const locale = this.user.locale.replace('_', '-');
                    const locDate = date.toLocaleString(locale).split(',')[0];
                    const publication = this.publicationsQuery.getActive() as Publication;
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    } else {
                        const fileUrl = window.URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = fileUrl;
                        link.download =
                            locDate +
                            '_ELIAS_' +
                            this.translateService.instant('publication.release.preview') +
                            '_' +
                            publication.subtitle.split(' ').join('_') +
                            '_' +
                            publication.title.split(' ').join('_') +
                            '.docx';
                        link.click();

                        window.setTimeout(() => {
                            window.URL.revokeObjectURL(fileUrl);
                        }, 250);
                    }
                },
                (err) => {
                    this.flashMessageService.showTranslatedError('system.channel.previewerror');
                }
            );
        } else {
            this.previewService.getHtmlSectionPreview(payload).subscribe((content) => {
                this.htmlPreviewService.setContent(content);
                // TODO: Create global configuration
                const dialogRef = this.dialog.open(PreviewHtmlComponent);
            });
        }
    }

    setUserLock(): void {
        this.locksQuery.selectAll().subscribe((locks) => {
            locks.forEach((lock) => {
                if (lock.user.id === this.userId) {
                    this.userLockedSection = lock.section.id;
                }
            });
        });
    }

    replacePipes(stringToReplace): string {
        return this.pipeReplacerService.replacePipes(stringToReplace, false);
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        this.uiService.setToolbarState('overview');
        // if(this.activeSection){
        this.editorService.resetStores(this.activeSection);
        this.subscriptions.forEach((subscription) => {
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        //   }
        this.unloadStylesheet();
    }

    addSection(event, options) {
        this.treeService.onCreateSectionChild(this.treeComponent, options.data);
    }

    deleteSection(event, options) {
        if (options.data.id == options.data.rootId) {
            const dialogRef = this.dialog.open(ErrorComponent, {
                data: {
                    type: 'root',
                },
            });
        } else {
            this.treeService.onDelete(this.treeComponent, options.data);
        }
    }

    copySection(event, options) {
        if (options.data.id == options.data.rootId) {
            const dialogRef = this.dialog.open(ErrorComponent, {
                data: {
                    type: 'root',
                },
            });
        } else {
            this.treeService.onCopy(this.treeComponent, options.data);
        }
    }

    stopEvent(event) {
        event.stopPropagation();
    }

    goToSection(sectionId) {
        this.focusedTreeNodeId = sectionId;
        this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
            this.treeComponent.treeModel.setActiveNode(node, false);
        });
        const publication = this.publicationsQuery.getActive() as Publication;
        this.router.navigateByUrl(
            '/publication-groups/' +
                publication.publicationGroup.id +
                '/publication/' +
                publication.id +
                '/editor/section/' +
                sectionId
        );
    }

    saveOpenElementAndGoToSection(sectionId) {
        if (sectionId !== this.activeSection && sectionId !== this.focusedTreeNodeId) {
            this.selectedElement$.subscribe((element) => {
                if (element !== null) {
                    switch (element.type) {
                        case 'node':
                            this.nodeService.deselectNode(element.element as Node).subscribe(() => {
                                this.goToSection(sectionId);
                            });
                            break;
                        case 'section':
                            this.goToSection(sectionId);
                            break;
                        default:
                            this.goToSection(sectionId);
                    }
                } else {
                    this.goToSection(sectionId);
                }
            });
        }
    }

    toggle() {
        this.toggleView = !this.toggleView;
    }
}
