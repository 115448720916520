import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HtmlPreviewService } from '../../services/preview/html-preview.service';
import { Observable } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'elias-editor-preview-html',
    template: `
        <div class="preview-container p-4">
            <div class="preview container" [innerHTML]="content$ | async"></div>
        </div>
    `,
    styleUrls: ['preview-html.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PreviewHtmlComponent implements OnInit {
    content$: Observable<SafeHtml>;

    constructor(private htmlPreviewService: HtmlPreviewService) {}

    ngOnInit() {
        this.content$ = this.htmlPreviewService.content$;
    }
}
