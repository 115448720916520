import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestDto } from '../../models/request-dto.model';
import { Chart } from './chart.model';
import { ChartsStore } from './charts.store';
import { DomainService } from '../../../../modules/core/services/domain.service';

@Injectable()
export class ChartsService {
    constructor(private chartsStore: ChartsStore, private domainService: DomainService, private http: HttpClient) {}

    getChartsForSection(payload: RequestDto): Observable<Chart[]> {
        this.chartsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/charts`;
        return this.http.get<Chart[]>(url).pipe(
            tap((charts) => {
                charts = charts.map((chart) => {
                    chart.id = chart.nodeId;
                    chart.content = URL.createObjectURL(this.b64toBlob(chart.content, chart.type));
                    return chart;
                });
                this.chartsStore.setLoading(false);
                this.chartsStore.upsertMany(charts);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    loadImageForChart(payload: RequestDto): Observable<Blob> {
        this.chartsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/charts/${payload.getParam('chartId')}`;

        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap((chartContent) => {
                if (chartContent.size > 0) {
                    this.chartsStore.upsert(payload.getParam('chartId'), {
                        content: URL.createObjectURL(chartContent),
                        type: chartContent.type,
                    });
                }
                this.chartsStore.setLoading(false);
            }),

            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 1024): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
