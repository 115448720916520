import { Injectable } from '@angular/core';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    public readonly apiBaseUrl: string;

    constructor(envService: EnvService) {
        const domain = (<any>window).location.hostname;

        if (envService.apiBaseUrl !== null) {
            // If api base url is set in env.js, use this one
            this.apiBaseUrl = envService.apiBaseUrl;
        } else if (domain === 'localhost') {
            // Hardcode api base url for elias development collection
            this.apiBaseUrl = 'https://api.elias.ddev.site/v1';
        } else {
            // In production, automatically use subdomain api.{domain}
            this.apiBaseUrl = `https://api.${domain}/v1`;
        }
    }
}
