import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Cycle } from '../models/cycle.model';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { Publication } from '../../../publication/state/publication/publication.model';
import { DomainService } from '../../../modules/core/services/domain.service';

@Injectable()
export class CycleService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private publicationsQuery: PublicationsQuery
    ) {}

    updateContent(): Observable<HttpResponse<Cycle>> {
        const rootSectionId = (this.publicationsQuery.getActive() as Publication).rootSectionId;
        const url = `${this.domainService.apiBaseUrl}/cycle/${rootSectionId}`;
        return this.http
            .put<Cycle>(url, '', { observe: 'response' })
            .pipe(catchError((error: any) => throwError(error)));
    }
}
