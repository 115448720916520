import { QueryEntity } from '@datorama/akita';
import { AssetsState, AssetsStore, assetsStore } from './assets.store';
import { Asset } from './asset.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AssetsQuery extends QueryEntity<AssetsState, Asset> {
    selectViewOption$ = this.select((state) => state.ui.viewOption);
    selectFilters$ = this.select((state) => state.ui.filters);
    selectSort$ = this.select((state) => state.ui.sort);
    selectCollection$ = this.select((state) => state.ui.filters.collection);
    selectFile$ = this.select((state) => state.ui.filters.fileType);

    constructor(protected store: AssetsStore) {
        super(store);
    }
}

export const assetsQuery = new AssetsQuery(assetsStore);
