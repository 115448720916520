<ng-container *ngIf="images.length > 0">
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
        <div class="grid-container" *ngFor="let image of objectData; trackBy: trackByIndex" [fxFlex]="100 / cols">
            <div>
                <ng-container *ngFor="let data of objectKeys(image); trackBy: trackByIndex">
                    <ng-container *ngIf="data !== 'link'">
                        <ng-container *ngIf="data !== 'image'; else dispImage">
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && image[data]"
                                [innerHTML]="image[data] | html"></div>
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && !image[data]"
                                [innerHTML]="'<p>&nbsp;</p>'"></div>
                        </ng-container>
                        <ng-template #dispImage>
                            <elias-shared-custom-image
                                [id]="image[data]"
                                [aspectRatio]="aspectRatio"></elias-shared-custom-image>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="space-after-line"></div>
        </div>
    </div>
</ng-container>
<div *ngIf="images.length === 0" class="col-12 pe-node-display-error">
    <i class="ei ei-error-full"></i><span>{{ 'component.imagegrid.missing' | translate }}</span>
</div>
