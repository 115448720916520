import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CommentsQuery } from '../../state/comments.query';
import { CommentStatus } from '../../enums/comment-status.enum';
import { Comment, CommentNormalized } from '../../models/comment.model';
import { CommentEditorOutputDto } from '../../models/comment-editor-output-dto.model';
import { CommentEditorAction } from '../../types/comment-editor-action.type';
import { Order } from '@datorama/akita';
import { delay, take, tap } from 'rxjs/operators';
import { CommentComponent } from '../comment/comment.component';
import { CommentsFacade } from '../../services/comments-facade.service';
import { ScrollableContainerDirective } from '../../../../modules/shared/directives/scrollable-container.directive';
import { CommentEditorComponent } from '../comment-editor/comment-editor.component';

@Component({
    selector: 'elias-comments-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
})
export class CommentListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(CommentComponent) commentComponents?: QueryList<CommentComponent>;
    @ViewChild(CommentEditorComponent) commentEditor?: CommentEditorComponent;
    @ViewChild(ScrollableContainerDirective) scrollableContainer?: ScrollableContainerDirective;

    @Input() title?: string;
    @Input() showFilters: boolean = false;
    @Input() showControls: boolean = true;
    @Input() commentStatus: CommentStatus = CommentStatus.Editable;
    @Input() preselectedCommentId?: string;

    @Output() commentsRefreshed = new EventEmitter<boolean>();
    @Output() commentCancelled = new EventEmitter();

    public comments$: Observable<Comment[]>;
    public loaded$: Observable<boolean>;
    public selectedComment$: Observable<CommentNormalized | undefined>;

    public CommentStatusEnum = CommentStatus;
    public editorAction: CommentEditorAction = 'create';
    public initialContent = '';

    constructor(private commentsFacade: CommentsFacade, private commentsQuery: CommentsQuery) {
        this.loaded$ = this.commentsQuery.select('loaded');
        this.comments$ = this.commentsQuery.selectComments({ sortBy: 'createdAt', sortByOrder: Order.DESC });
        this.selectedComment$ = this.commentsQuery.selectActive();
    }

    ngOnInit() {
        if (this.commentStatus === CommentStatus.Editable) {
            this.loadCommentDraft();
        }
    }

    ngAfterViewInit() {
        const commentsRendered$ = this.commentComponents!.changes;

        commentsRendered$
            .pipe(
                take(1),
                delay(300),
                tap(() => {
                    this.handlePreselectedComment();

                    if (this.commentStatus === CommentStatus.Editable) {
                        this.handleReplyDraft();
                    }
                })
            )
            .subscribe();
    }

    public onCommentSubmitted(output: CommentEditorOutputDto): void {
        if (this.editorAction === 'create') {
            this.commentsFacade.createComment(output);
        } else if (this.editorAction === 'reply') {
            this.commentsFacade.createReply(output);
        }

        this.resetEditorState();
    }

    public onCommentResolved(comment: Comment): void {
        this.commentsFacade.resolveComment(comment);
        this.resetEditorState();
    }

    public onCommentUnresolved(comment: Comment): void {
        this.commentsFacade.unresolveComment(comment);
        this.resetEditorState();
    }

    public async onCommentCancelled(): Promise<void> {
        this.commentsFacade.removeCommentDraft();
        this.commentCancelled.emit();
    }

    public onAnswerClicked(comment: Comment): void {
        this.scrollToComment(comment.id);

        const selectedCommentId = this.commentsQuery.getActiveId();
        const isSameCommentReplied = comment.id === selectedCommentId && this.editorAction === 'reply';

        if (!isSameCommentReplied) {
            this.commentEditor?.reset();
        }

        this.commentEditor?.focus();
        this.commentsFacade.deselectComment();

        setTimeout(() => {
            this.editorAction = 'reply';
            this.commentsFacade.selectComment(comment.id);
        });
    }

    public onCommentDraftUpdated(editorOutput: CommentEditorOutputDto): void {
        const isReply = this.editorAction === 'reply';
        this.commentsFacade.updateCommentDraft(editorOutput, isReply);
    }

    public async onCommentLinkClicked(comment: Comment): Promise<void> {
        await this.commentsFacade.navigateToComment(comment);
    }

    public onFiltersChanged(): void {
        this.commentsRefreshed.emit(true);
    }

    public loadMoreComments(): void {
        const hasNextPage = this.commentsQuery.hasNextPage();

        if (hasNextPage) {
            this.commentsFacade.nextPage();
            this.commentsRefreshed.emit(false);
        }
    }

    private resetEditorState(): void {
        this.editorAction = 'create';
        this.commentsFacade.deselectComment();
    }

    private scrollToComment(id: string): void {
        this.scrollableContainer?.scrollTo(id);
    }

    private handlePreselectedComment(): void {
        if (!this.preselectedCommentId) {
            return;
        }

        this.commentsFacade.selectComment(this.preselectedCommentId);
        this.scrollToComment(this.preselectedCommentId);
    }

    private loadCommentDraft(): void {
        const commentDraft = this.commentsFacade.getCommentDraft();

        if (!commentDraft) {
            return;
        }

        this.initialContent = commentDraft.getContent();
    }

    private handleReplyDraft(): void {
        const commentDraft = this.commentsFacade.getCommentDraft();
        const repliedCommentId = commentDraft?.getRepliedCommentId();

        if (!repliedCommentId) {
            return;
        }

        this.editorAction = 'reply';
        this.commentsFacade.selectComment(repliedCommentId);
        this.scrollToComment(repliedCommentId);
    }

    ngOnDestroy() {
        this.commentsFacade.resetStore();
    }
}
