import { PublicationsStore } from './publications.store';
import { Injectable } from '@angular/core';
import { PublicationsQuery } from './publications.query';
import { Observable, throwError } from 'rxjs';
import { Publication } from './publication.model';
import { catchError, tap } from 'rxjs/operators';
import { ID, withTransaction } from '@datorama/akita';
import { RequestDto } from '../../../editor/editor/models/request-dto.model';
import { HttpClient } from '@angular/common/http';
import { DomainService } from '../../../modules/core/services/domain.service';
import { Channel } from '../../../modules/shared/state/channels/channel.model';
import * as moment from 'moment';
import { LocalePublicationDto } from './locale-publication-dto.model';
import { PublicationGroup } from '../../../publication-group/state/publication-group/publication-group.model';
import { PublicationGroupsStore } from '../../../publication-group/state/publication-group/publication-group.store';
import { PublicationGroupQuery } from '../../../publication-group/state/publication-group/publication-group.query';

@Injectable({
    providedIn: 'root',
})
export class PublicationsService {
    constructor(
        private publicationsStore: PublicationsStore,
        private publicationGroupStore: PublicationGroupsStore,
        private publicationGroupQuery: PublicationGroupQuery,
        private publicationsQuery: PublicationsQuery,
        private domainService: DomainService,
        private http: HttpClient
    ) {}

    add(publication: Publication) {
        this.publicationsStore.add(publication);
    }

    getPublications(options: any = {}, location = null): Observable<any> {
        this.publicationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications`;
        return this.http.get<any>(url, { params: options }).pipe(
            withTransaction((result) => {
                if (location !== null) {
                    this.publicationsStore.upsertMany(result.data);
                } else {
                    this.publicationsStore.set(result.data);
                }
                this.publicationsStore.update({
                    currentPage: result.currentPage,
                    totalNumberOfPages: result.totalNumberOfPages,
                    totalDataLength: result.totalDataLength,
                });
                this.publicationsStore.setLoading(false);
                this.publicationsStore.update({ loaded: true });
            })
        );
    }

    createPublicationInsideGroup(publication: LocalePublicationDto, pubGroupId: string): Observable<any> {
        this.publicationsStore.setLoading(true);
        const payload: RequestDto = new RequestDto(null, null, publication);
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${pubGroupId}/publications`;
        return this.http.post<any>(url, payload.body).pipe(
            tap((pub) => {
                this.publicationsStore.add(pub);
                this.publicationGroupStore.update(pub.publicationGroup.id, pub.publicationGroup);
                this.publicationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updatePublication(publication: Publication, data = null) {
        this.publicationsStore.setLoading(true);
        data = data ? data : publication;
        const url = `${this.domainService.apiBaseUrl}/publications/${publication.id}`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.patch<Publication>(url, payload.body).pipe(
            tap((publication) => {
                const data = publication;
                data.publicationDate = moment.utc(data.publicationDate).add(2, 'hours').format();
                this.publicationsStore.update(publication.id, data);
                this.publicationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updatePublicationState(publication: Publication, data: Partial<Publication> = null) {
        this.publicationsStore.setLoading(true);
        data = data ? data : publication;
        const url = `${this.domainService.apiBaseUrl}/publications/${publication.id}/state`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.put<Publication>(url, payload.body).pipe(
            tap((publication) => {
                this.publicationsStore.update(publication.id, publication);
                this.publicationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updatePublicationChannelSettings(publication: Publication, channel: Channel, data = null) {
        this.publicationsStore.setLoading(true);
        data = data ? data : publication;
        const url = `${this.domainService.apiBaseUrl}/publications/${publication.id}/channels/${channel.id}/state`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.patch<Publication>(url, payload.body).pipe(
            tap((publication) => {
                this.publicationsStore.update(publication.id, publication);
                this.publicationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    clonePublicationInsideGroup(id, publication: LocalePublicationDto) {
        this.publicationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${id}`;
        const payload: RequestDto = new RequestDto(null, null, publication);
        return this.http.post<any>(url, payload.body).pipe(
            tap((pub) => {
                this.publicationsStore.add(pub);
                this.publicationGroupStore.update(pub.publicationGroup.id, pub.publicationGroup);
                this.publicationsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getSinglePublication(id) {
        const url = `${this.domainService.apiBaseUrl}/publications/${id}`;
        return this.http.get<Publication>(url).pipe(
            tap((publication) => {
                const data = publication;
                data.publicationDate = moment.utc(data.publicationDate).add(2, 'hours').format();
                this.publicationsStore.upsert(publication.id, data);
                this.publicationsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deletePublication(id: ID) {
        this.publicationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${id}`;
        return this.http.delete<PublicationGroup>(url).pipe(
            tap((publicationGroup: PublicationGroup) => {
                this.publicationGroupStore.upsert(publicationGroup.id, publicationGroup);
                this.publicationsStore.remove(id);
                this.publicationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    getPublicationFromRootId(rootId): Observable<any> {
        const url = `${this.domainService.apiBaseUrl}/publication/${rootId}`;
        return this.http.get<Publication>(url).pipe(
            tap((publication) => {
                this.publicationsStore.add(publication);
                this.publicationsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createPreview(publication: Publication, channel: Channel) {
        const url = `${this.domainService.apiBaseUrl}/preview/publication/${publication.id}/channel/${channel.id}`;
        return this.http.get(url, {
            responseType: 'blob',
        });
    }
}
