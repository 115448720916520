<ng-container *ngIf="objectData && objectData.hasOwnProperty('image')">
    <ng-container *ngFor="let data of order; trackBy: trackByIndex">
        <div class="image-container" *ngIf="data === 'image'; else dispData" (click)="openEditor()">
            <i class="ei ei-settings setting-icon"></i>
            <elias-shared-custom-image [id]="objectData[data]" [aspectRatio]="aspectRatio"></elias-shared-custom-image>
        </div>
        <ng-template #dispData>
            <div
                class="{{ data }}"
                *ngIf="itemFieldSettings[data]"
                [innerHTML]="getData(objectData[data]) | html"></div>
        </ng-template>
    </ng-container>
</ng-container>
<ng-container *ngIf="objectKeys(objectData).length === 0">
    <elias-assetbrowser-button
        #assetBrowserButton
        [text]="'document.image.uploadSingle'"
        [allowedTypes]="['image/jpeg', 'image/gif', 'image/png', 'image/jpg']"
        [single]="true"
        (assetsSelected)="onAssetsSelected($event)"></elias-assetbrowser-button>
</ng-container>
<div class="space-after-line"></div>
