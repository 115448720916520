import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Asset } from './asset.model';
import { RequestDto } from '../../../../editor/editor/models/request-dto.model';
import { AssetsStore } from './assets.store';
import { DomainService } from '../../../core/services/domain.service';
import { ImagesStore } from '../../../../editor/editor-container/state/images/images.store';
import { ExcelStore } from '../../../../editor/editor/state/excel/excel.store';
import { withTransaction } from '@datorama/akita';
import { Excel } from '../../../../editor/editor/state/excel/excel.model';
import { AssetStorageType } from '../../../../editor/asset-browser/enums/asset-storage-type.enum';

@Injectable()
export class AssetsService {
    constructor(
        private assetsStore: AssetsStore,
        private domainService: DomainService,
        private http: HttpClient,
        private imagesStore: ImagesStore,
        private excelStore: ExcelStore
    ) {}

    getAssetManagerAssets(publicationGroupId, options: any = {}): Observable<any[]> {
        this.assetsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/publication-group/${publicationGroupId}`;
        return this.http.get<any>(url, { params: options }).pipe(
            withTransaction((result) => {
                this.assetsStore.update({
                    currentPage: result.currentPage,
                    totalNumberOfPages: result.totalNumberOfPages,
                    totalDataLength: result.totalDataLength,
                });
                this.assetsStore.set(result.data);
                this.assetsStore.setLoading(false);
                this.assetsStore.update({ loaded: true });

                let data = result.data;
                data.forEach((asset) => {
                    if (this.isExcel(asset)) {
                        this.excelStore.upsert(asset.id, asset);
                        this.excelStore.setLoading(false);
                        this.excelStore.update({ loaded: true });
                    }
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    loadSingleAsset(payload: RequestDto): Observable<Asset> {
        this.assetsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;

        return this.http.get<Asset>(url, {}).pipe(
            withTransaction((asset) => {
                this.assetsStore.upsert(asset.id, asset);
                if (this.isExcel(asset)) {
                    this.excelStore.upsert(asset.id, asset as Excel);
                }
                this.assetsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateAsset(payload: RequestDto): Observable<Asset> {
        this.assetsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;
        return this.http.patch<Asset>(url, payload.body.data).pipe(
            withTransaction((asset) => {
                this.assetsStore.update(asset.id, asset);
                this.assetsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteAsset(payload: RequestDto): Observable<Asset> {
        this.assetsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;

        return this.http.delete<Asset>(url, {}).pipe(
            withTransaction((asset) => {
                this.assetsStore.remove(asset.id);
                this.assetsStore.setLoading(false);

                if (this.isImage(asset)) {
                    this.imagesStore.remove(asset.id);
                }
                if (this.isExcel(asset)) {
                    this.excelStore.remove(asset.id);
                }
            }),
            catchError((error: any) => throwError(error))
        );
    }

    downloadAsset(payload: RequestDto): Observable<any> {
        this.assetsStore.setLoading(true);
        const assetId = payload.getParam('asset').id;
        const pubGroupId = payload.getQueryParam('pubGroupId');
        const url = `${this.domainService.apiBaseUrl}/assets/download/${assetId}/${pubGroupId}`;

        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap((asset) => {
                this.assetsStore.setLoading(false);
            }),
            catchError(this.parseErrorBlob)
        );
    }

    uploadAsset(payload: FormData, assetStorageType: AssetStorageType): Observable<any> {
        const url = this.getAssetUploadUrl(assetStorageType);

        return this.http.post(url, payload);
    }

    isImage(asset: Asset): boolean {
        return asset.mimeType.includes('image');
    }

    isExcel(asset: Asset): asset is Excel {
        let allowedTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/octet-stream',
        ];
        return allowedTypes.includes(asset.mimeType);
    }

    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result as string));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }

    setFilter(filterType, filter) {
        const filters = {};
        filters[filterType] = filter;
        this.assetsStore.update((assetState) => ({
            ui: { ...assetState.ui, filters: { ...assetState.ui.filters, ...filters } },
        }));
    }

    setSorting(sort) {
        this.assetsStore.update((assetState) => ({
            ui: { ...assetState.ui, sort },
        }));
    }

    changeViewOption(viewOption: number) {
        this.assetsStore.update((assetState) => ({
            ui: { ...assetState.ui, viewOption },
        }));
    }

    getPublicationsAssetPartOf(assetId): Observable<any[]> {
        const url = `${this.domainService.apiBaseUrl}/assets/${assetId}/publications`;
        return this.http.get<any[]>(url);
    }

    hasError(asset: Asset | undefined): boolean {
        if (!asset) {
            return false;
        }

        if (this.isExcel(asset)) {
            return asset.processingError;
        }

        return false;
    }

    private getAssetUploadUrl(assetStorageType: AssetStorageType): string {
        switch (assetStorageType) {
            case AssetStorageType.General:
                return `${this.domainService.apiBaseUrl}/assets`;

            case AssetStorageType.Shared:
                return `${this.domainService.apiBaseUrl}/assets/shared`;

            case AssetStorageType.PublicationGroup:
                return `${this.domainService.apiBaseUrl}/assets/publication-only`;

            default:
                return `${this.domainService.apiBaseUrl}/assets`;
        }
    }
}
