import { CategoriesStore, CategoryState } from './categories.store';
import { Category } from './category.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CategoriesQuery extends QueryEntity<CategoryState, Category> {
    selectViewOption$ = this.select((state) => state.ui.viewOption);

    constructor(protected store: CategoriesStore) {
        super(store);
    }
}
