import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestDto } from '../../../../editor/editor/models/request-dto.model';
import { AssetCollection } from './asset-collection.model';
import { AssetCollectionsStore } from './asset-collections.store';
import { DomainService } from '../../../core/services/domain.service';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class AssetCollectionsService {
    constructor(
        private assetCollectionsStore: AssetCollectionsStore,
        private domainService: DomainService,
        private http: HttpClient
    ) {}

    /** GET assets from the server */
    getAssetCollections(options: any = {}): Observable<AssetCollection[]> {
        this.assetCollectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/asset-collections`;
        return this.http.get<AssetCollection[]>(url, { params: options }).pipe(
            withTransaction((assetCollections) => {
                this.assetCollectionsStore.set(assetCollections);
                this.assetCollectionsStore.setLoading(false);
                this.assetCollectionsStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    /** create new asset collection */
    createAssetCollection(payload: RequestDto): Observable<AssetCollection> {
        this.assetCollectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/asset-collections`;
        return this.http.post<AssetCollection>(url, payload.body).pipe(
            withTransaction((assetCollection) => {
                this.assetCollectionsStore.add(assetCollection);
                this.assetCollectionsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    /** update an asset collection */
    updateAssetCollection(payload: RequestDto): Observable<AssetCollection> {
        this.assetCollectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/asset-collections/${payload.getParam('collection')}`;
        return this.http.patch<AssetCollection>(url, payload.body).pipe(
            withTransaction((assetCollection) => {
                this.assetCollectionsStore.update(assetCollection.id, assetCollection);
                this.assetCollectionsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    /** remove an asset collection */
    removeAssetCollection(payload: RequestDto): Observable<AssetCollection> {
        this.assetCollectionsStore.setLoading(true);
        const id = payload.getParam('collection');
        const url = `${this.domainService.apiBaseUrl}/asset-collections/${payload.getParam('collection')}`;
        return this.http.delete<AssetCollection>(url, {}).pipe(
            withTransaction(() => {
                this.assetCollectionsStore.remove(id);
                this.assetCollectionsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
