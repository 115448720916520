<div class="asset-browser-files" [ngClass]="{ loading: (assetsLoading$ | async) && (assetsLoaded$ | async) }">
    <div class="loading"></div>
    <div
        class="file-list"
        *ngIf="(assetsLoaded$ | async) && assets.length > 0"
        [ngClass]="{ 'fixed-height': fixedHeight }"
        fxLayout="row wrap"
        fxLayoutGap="20px grid">
        <ng-container *ngIf="viewValue === 0">
            <div
                class="asset-file"
                *ngFor="let asset of assets; trackBy: trackByItemId"
                [ngClass]="{ selected: isSelected(assetsQuery.getEntity(asset.id)) }"
                [fxFlex]="20">
                <div
                    class="file"
                    (click)="onAssetClick(assetsQuery.getEntity(asset.id))"
                    [ngClass]="{
                        'file-border-design-height': fixedHeight,
                        styling: getAssetMimeType(assetsQuery.getEntity(asset.id).mimeType)
                    }">
                    <ng-container [ngSwitch]="assetsQuery.getEntity(asset.id).mimeType">
                        <ng-container *ngSwitchCase="'image/jpeg'">
                            <elias-shared-custom-image
                                class="custom-image"
                                [id]="asset.id"
                                [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            <div class="filename">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image/jpg'">
                            <elias-shared-custom-image
                                class="custom-image"
                                [id]="asset.id"
                                [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            <div class="filename">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image/gif'">
                            <elias-shared-custom-image
                                class="custom-image"
                                [id]="asset.id"
                                [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            <div class="filename">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image/png'">
                            <elias-shared-custom-image
                                class="custom-image"
                                [id]="asset.id"
                                [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            <div class="filename">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'application/vnd.ms-excel'">
                            <i class="ei ei-file-xls file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'application/octet-stream'">
                            <i class="ei ei-file-xls file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                            <i class="ei ei-file-xls file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'application/word'">
                            <i class="ei ei-file-doc file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'application/pdf'">
                            <i class="ei ei-file-pdf file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <i class="ei ei-file-doc file-icon"></i>
                            <div class="name">
                                {{ assetsQuery.getEntity(asset.id).name | strlen : 20 }}
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <i class="ei ei-done done" *ngIf="isSelected(assetsQuery.getEntity(asset.id))"></i>
                <i class="ei ei-error-full error-icon" *ngIf="assetsService.hasError(asset)"></i>
                <i class="ei ei-processing-full processing-icon" *ngIf="isBeingProcessed(asset)"></i>
            </div>
        </ng-container>
        <div *ngIf="viewValue === 2" style="width: 100%">
            <mat-list [ngClass]="{ 'fixed-height': fixedHeight }">
                <mat-list-item
                    *ngFor="let asset of assets; trackBy: trackByItemId"
                    class="list-view"
                    (click)="onAssetClick(assetsQuery.getEntity(asset.id))"
                    [ngClass]="{ selected: isSelected(assetsQuery.getEntity(asset.id)) }">
                    <div class="column column-title">
                        <ng-container [ngSwitch]="assetsQuery.getEntity(asset.id).mimeType">
                            <div *ngSwitchCase="'image/jpeg'" class="position-image">
                                <elias-shared-custom-image
                                    class="custom-image"
                                    [id]="asset.id"
                                    [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            </div>
                            <div *ngSwitchCase="'image/jpg'" class="position-image">
                                <elias-shared-custom-image
                                    class="custom-image"
                                    [id]="asset.id"
                                    [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            </div>
                            <div *ngSwitchCase="'image/gif'" class="position-image">
                                <elias-shared-custom-image
                                    class="custom-image"
                                    [id]="asset.id"
                                    [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            </div>
                            <div *ngSwitchCase="'image/png'" class="position-image">
                                <elias-shared-custom-image
                                    class="custom-image"
                                    [id]="asset.id"
                                    [aspectRatio]="aspectRatio.id"></elias-shared-custom-image>
                            </div>
                            <ng-container *ngSwitchCase="'application/vnd.ms-excel'">
                                <i class="ei ei-file-xls file-icon"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="'application/octet-stream'">
                                <i class="ei ei-file-xls file-icon"></i>
                            </ng-container>
                            <ng-container
                                *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                                <i class="ei ei-file-xls file-icon"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="'application/word'">
                                <i class="ei ei-file-doc file-icon"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="'application/pdf'">
                                <i class="ei ei-file-pdf file-icon"></i>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <i class="ei ei-file-doc file-icon"></i>
                            </ng-container>
                        </ng-container>
                        <h4 class="title">
                            {{ assetsQuery.getEntity(asset.id).name | strlen : 255 }}
                        </h4>
                        <i class="ei ei-error-full error-icon" *ngIf="assetsService.hasError(asset)"></i>
                        <i class="ei ei-processing-full processing-icon" *ngIf="isBeingProcessed(asset)"></i>
                    </div>
                    <div class="column column-content">
                        <div class="tags" fxLayout="row wrap" fxLayoutGap="6px">
                            <div
                                class="tag dots"
                                *ngFor="
                                    let assetCollection of ObjectKeys(asset.assetCollections);
                                    trackBy: trackByIndex
                                "
                                [ngStyle]="{
                                    'background-color': assetCollectionsQuery.getEntity(assetCollection).color
                                }"
                                matTooltip="{{ assetCollectionsQuery.getEntity(assetCollection).name }}"></div>
                        </div>
                    </div>
                    <div class="column column-date">
                        <span class="date">{{
                            assetsQuery.getEntity(asset.id).updatedAt | localizedDate : 'eliasMediumDate'
                        }}</span>
                        <button mat-flat-button color="accent" class="edit-list-button">
                            <i class="ei ei-edit"></i>
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div
        class="no-files"
        *ngIf="(assetsLoaded$ | async) === false"
        [ngClass]="{ 'no-files-fixed-height': fixedHeight }">
        <div class="google-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <p>{{ 'assetBrowser.loadFiles' | translate }}</p>
    </div>
    <div
        class="no-files"
        *ngIf="(assetsLoaded$ | async) && assets.length === 0"
        [ngClass]="{ 'no-files-fixed-height': fixedHeight }">
        {{ 'assetBrowser.nofiles' | translate }}
    </div>
</div>
