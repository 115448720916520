<div [formGroup]="formGroup">
    <mat-radio-group
        [formControlName]="name"
        [required]="required"
        [ngClass]="{
            'error-circle': formGroup.get(name).hasError('required') && formGroup.get(name).touched,
            styling: name === 'gender'
        }">
        <ng-container *ngFor="let option of options; trackBy: trackByIndex">
            <div fxLayout="row">
                <mat-radio-button
                    color="primary"
                    [value]="option.key"
                    class="alignment"
                    [ngClass]="{ position: name === 'gender' }">
                    <ng-container *ngIf="option.text">
                        <h1>{{ option.value | translate }}</h1>
                        <ng-container *ngIf="option.icon"><i class="ei {{ option.icon }}"></i></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!option.text">
                        <span class="value">{{ option.value | translate }}</span>
                    </ng-container>
                </mat-radio-button>
            </div>
            <p class="description" *ngIf="option.text">{{ option.text | translate }}</p>
        </ng-container>
    </mat-radio-group>
    <div
        class="errors"
        [ngClass]="{
            error: formGroup.get(name).hasError('required') && formGroup.get(name).touched
        }">
        {{ 'error.' + name | translate }}
    </div>
</div>
