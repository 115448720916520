import { QueryEntity } from '@datorama/akita';
import { AspectRatiosState, AspectRatiosStore, aspectRatiosStore } from './aspect-ratios.store';
import { AspectRatio } from './aspect-ratio.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AspectRatiosQuery extends QueryEntity<AspectRatiosState, AspectRatio> {
    constructor(protected store: AspectRatiosStore) {
        super(store);
    }
}

export const aspectRatiosQuery = new AspectRatiosQuery(aspectRatiosStore);
