<ng-container *ngIf="(loaded$ | async) === false">
    <elias-shared-loader></elias-shared-loader>
</ng-container>

<ng-container *ngIf="loaded$ | async">
    <elias-shared-sidebar-left [ngClass]="{ overlay: toggleView }">
        <!-- FILTERING FOR LATER -->
        <!--<mat-form-field class="filter-value" floatLabel="always" >
            <mat-label>{{'core.toolbar.filter' | translate}}</mat-label>
            <input matInput id="filter" #filter (keyup)="treeComponent.treeModel.filterNodes(filter.value)" placeholder="filter nodes by fuzzy search"/>
        </mat-form-field>-->
        <elias-shared-sidebar-widget-scrollable data-position="fullheight-with-button-bottom" class="editor-tree-data">
            <i
                class="ei ei-arrows-left arrow-right"
                *ngIf="toggleView"
                (click)="toggle()"
                [ngClass]="{ 'overlay-position': toggleView }"></i>
            <i
                class="ei ei-arrows-right arrow-left"
                *ngIf="!toggleView"
                (click)="toggle()"
                [ngClass]="{ 'initial-position': !toggleView }"></i>
            <tree-root
                #treeComponent
                [nodes]="sections$ | async"
                [options]="treeOptions"
                class="tree-root"
                [ngClass]="{ visible: toggleView }">
                <ng-template #treeNodeTemplate let-node let-index="index">
                    <div class="node-value">
                        <span
                            [ngClass]="{
                                'icon-edit-no': node.data.locked && node.data.id !== userLockedSection
                            }"
                            [innerHTML]="
                                node.data.navigationTitle ? node.data.navigationTitle : replacePipes(node.data.title)
                            ">
                        </span>
                        <div class="icons">
                            <i
                                *ngIf="node.data.locked && node.data.id !== userLockedSection"
                                class="ei ei-lock lock-icon"></i>
                            <i
                                [matMenuTriggerFor]="menu"
                                (click)="stopEvent($event)"
                                class="ei ei-more-vert menu-icon"></i>
                            <mat-menu #menu="matMenu" class="action-button">
                                <button mat-menu-item (click)="addSection($event, node)">
                                    <i class="ei ei-plus-full add"></i>{{ 'actions.addsection' | translate }}
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="deleteSection($event, node)"
                                    *ngIf="node.data.rootId !== node.data.id">
                                    <i class="ei ei-trash delete"></i>{{ 'actions.deletesection' | translate }}
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="copySection($event, node)"
                                    *ngIf="node.data.rootId !== node.data.id">
                                    <i class="ei ei-copy copy"></i>{{ 'actions.copysection' | translate }}
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </ng-template>
            </tree-root>
        </elias-shared-sidebar-widget-scrollable>

        <elias-shared-sidebar-widget data-position="button-bottom">
            <elias-editor-preview-button
                class="preview-button"
                [channels]="channels$ | async"
                [generating]="generatingPreview$ | async"
                (generate)="onGenerateWordPreview($event)"></elias-editor-preview-button>
        </elias-shared-sidebar-widget>
    </elias-shared-sidebar-left>

    <div class="scroll-container">
        <div class="scroll-container-spacer">
            <router-outlet></router-outlet>
        </div>
    </div>

    <elias-shared-sidebar-right>
        <elias-shared-sidebar-widget-scrollable data-position="fullheight">
            <elias-editor-preset-browser
                [nodePresets]="nodePresets$"
                [documentLoading$]="documentLoading$"></elias-editor-preset-browser>
        </elias-shared-sidebar-widget-scrollable>
    </elias-shared-sidebar-right>
</ng-container>
