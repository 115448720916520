<div class="menu-section menu-section-user">
    <div class="user-menu">
        <elias-shared-user-avatar [hideToolTip]="true" [user]="user" [size]="'small'"></elias-shared-user-avatar>
        <div class="user-menu__details">
            <div class="user-menu__name">{{ user.firstName + ' ' + user.lastName }}</div>
            <div class="user-menu__email">{{ user.email }}</div>
            <div class="user-menu__profile-link" routerLink="/accounts">
                <span>{{ 'core.toolbar.account' | translate }}</span>
                <i class="ei ei-arrows-right-thin"></i>
            </div>
        </div>
    </div>
</div>

<mat-divider></mat-divider>

<div class="menu-section menu-section-main">
    <div class="icon-menu">
        <div
            routerLink="/publication-groups"
            routerLinkActive="link--active"
            [routerLinkActiveOptions]="{ exact: false }"
            class="icon-menu__link">
            <div class="icon-menu__icon">
                <i class="ei ei-dashboard"></i>
            </div>
            <div class="icon-menu__text">{{ 'core.toolbar.publications' | translate }}</div>
        </div>
        <div
            routerLink="/notifications"
            routerLinkActive="link--active"
            [routerLinkActiveOptions]="{ exact: false }"
            class="icon-menu__link">
            <div class="icon-menu__icon">
                <i class="ei ei-bell"></i>
            </div>
            <div
                [matBadgeHidden]="numberOfUnreadNotifications === 0"
                class="mat-badge"
                matBadge="{{ numberOfUnreadNotifications }}"
                matBadgeOverlap="false">
                <div class="icon-menu__text">{{ 'core.toolbar.notifications' | translate }}</div>
            </div>
        </div>
        <div
            routerLink="/dictionaries"
            routerLinkActive="link--active"
            [routerLinkActiveOptions]="{ exact: false }"
            class="icon-menu__link">
            <div class="icon-menu__icon">
                <i class="ei ei-book"></i>
            </div>
            <div class="icon-menu__text">{{ 'core.toolbar.dictionaries' | translate }}</div>
        </div>
        <ng-container *ngIf="isAdmin">
            <div
                routerLink="/users"
                routerLinkActive="link--active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="icon-menu__link">
                <div class="icon-menu__icon">
                    <i class="ei ei-user"></i>
                </div>
                <div class="icon-menu__text">{{ 'core.toolbar.users' | translate }}</div>
            </div>
            <div
                routerLink="/settings"
                routerLinkActive="link--active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="icon-menu__link">
                <div class="icon-menu__icon">
                    <i class="ei ei-settings"></i>
                </div>
                <div class="icon-menu__text">{{ 'core.toolbar.settings' | translate }}</div>
            </div>
        </ng-container>
    </div>

    <div class="application-version">Version {{ version }}</div>
</div>

<div class="spacer"></div>

<mat-divider class="divider"></mat-divider>

<div class="menu-section menu-section-logout">
    <div class="icon-menu">
        <div (click)="logout()" class="icon-menu__link">
            <div class="icon-menu__icon">
                <i class="ei ei-logout"></i>
            </div>
            <div class="icon-menu__text">{{ 'core.toolbar.logout' | translate }}</div>
        </div>
    </div>
</div>
