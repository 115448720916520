<div class="content-wrapper-for-hover" [ngClass]="{ 'unresolved-comments-border': section.hasUnresolvedComments }">
    <div class="section-headline" *ngIf="section$ | async as section">
        <div>
            <h1 [innerHTML]="title" [ngClass]="{ 'visible-grey': section.hideTitle }"></h1>
            <p class="subtitle" *ngIf="section.subTitle">{{ section.subTitle }}</p>
        </div>
    </div>
    <div class="overlay" (click)="openPropertyEditor()">
        <button mat-flat-button color="accent" (click)="openPropertyEditor()">
            {{ 'propertyEditor.sectionProperties' | translate }}
        </button>
    </div>
    <div class="section-buttons section-buttons-right">
        <i
            class="ei icons"
            [ngClass]="section.hasUnresolvedComments ? 'ei-comment-full unresolved-comments-icon' : 'ei-comment'"
            (click)="openComments()"></i>
    </div>
</div>
