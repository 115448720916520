<button
    mat-stroked-button
    color="accent"
    id="assetManagerButton"
    (click)="openAssetManager()"
    class="button"
    type="button">
    <ng-container *ngIf="text">
        <i class="ei ei-plus-full"></i>
    </ng-container>
    <ng-container *ngIf="!text">
        <i class="ei ei-copy"></i>
    </ng-container>
    <span *ngIf="text" class="assetButtonText">{{ text | translate }}</span>
    <span *ngIf="!text" class="assetButtonText">{{ 'assetBrowser.change.' + type | translate }}</span>
</button>
