<form novalidate #keyImageForm="ngForm" class="form-document">
    <div class="row node-type-key-image">
        <div class="keyimage-{{ keyImageLayout }} layout-style">
            <div
                *ngFor="let keyImageCol of keyImages; let i = index"
                class="col-{{ getColClass() }}"
                [attr.data-col]="i"
                [dragula]="'keyimage-draggable'">
                <ng-container *ngFor="let keyImageItem of keyImageCol; let k = index">
                    <div class="keyimage" [attr.data-index]="k">
                        <div class="pe-element-inner keyimage-inner" (click)="openEditor(keyImageItem, i, k)">
                            <i class="ei ei-settings setting-icon"></i>
                            <div class="col-12 image">
                                <elias-shared-custom-image
                                    [id]="keyImageItem.image"
                                    [aspectRatio]="aspectRatio"></elias-shared-custom-image>
                            </div>
                            <div class="row row-text">
                                <div class="col-8 label" [innerHTML]="keyImageItem.label1"></div>
                                <div class="col-4 value" [innerHTML]="keyImageItem.value1"></div>
                            </div>
                            <div class="row row-text">
                                <div class="col-8 label" [innerHTML]="keyImageItem.label2"></div>
                                <div class="col-4 value" [innerHTML]="keyImageItem.value2"></div>
                            </div>
                            <div class="row row-text">
                                <div class="col-8 label" [innerHTML]="keyImageItem.label3"></div>
                                <div class="col-4 value" [innerHTML]="keyImageItem.value3"></div>
                            </div>
                            <button mat-icon-button class="element-button-drag">
                                <i class="ei ei-drag element-button-drag-icon"></i>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="pe-element-button-container">
                    <button mat-stroked-button color="accent" (click)="addElement(i)">
                        <i class="ei ei-plus-full"></i>{{ 'component.keyimages.add' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
