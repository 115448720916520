import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { Publication } from '../../../publication/state/publication/publication.model';
import { PublicationGroupQuery } from '../../../publication-group/state/publication-group/publication-group.query';
import { PublicationGroup } from '../../../publication-group/state/publication-group/publication-group.model';
import { LocksService } from '../state/locks/locks.service';
import { first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocksQuery } from '../state/locks/locks.query';
import { LoggedInUserQuery } from '../../../modules/shared/state/logged-in-user/logged-in-user.query';

@Injectable()
export class EditorResolver implements Resolve<any> {
    constructor(
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private loggedInUserQuery: LoggedInUserQuery,
        private publicationGroupsQuery: PublicationGroupQuery,
        private publicationsQuery: PublicationsQuery,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const sectionId = route.firstChild?.params['sectionId'];

        if (sectionId) {
            return of(true);
        }

        const publicationGroup = this.publicationGroupsQuery.getActive() as PublicationGroup;
        const publication = this.publicationsQuery.getActive() as Publication;
        const rootSectionId = publication.rootSectionId;

        return this.locksService.getLocksForPublication(publication.id as string).pipe(
            switchMap(() => this.locksQuery.getSectionLock(rootSectionId).pipe(first())),
            switchMap((lock) => {
                const loggedInUser = this.loggedInUserQuery.getValue();

                /**
                 * Don't redirect to root section when it is locked by another user
                 * Redirect to the editor container instead
                 */
                if (lock && lock.user.id !== loggedInUser.id) {
                    return of(true);
                }

                return this.router.navigateByUrl(
                    `/publication-groups/${publicationGroup.id}/publication/${publication.id}/editor/section/${rootSectionId}`
                );
            })
        );
    }
}
