import { Injectable } from '@angular/core';
import { SystemInformationState, systemInformationStore, SystemInformationStore } from './system-information.store';
import { Query } from '@datorama/akita';
import { Language } from './language.model';
import { KeyValueOption } from '../../models/key-value-option.model';

@Injectable({
    providedIn: 'root',
})
export class SystemInformationQuery extends Query<SystemInformationState> {
    version$ = this.select((state) => state.version);

    constructor(protected store: SystemInformationStore) {
        super(store);
    }

    public getLanguages(): Map<string, Language> {
        const systemInformation = this.getValue();
        if (systemInformation.languages === null) {
            return new Map();
        }

        const sortedLanguages = systemInformation.languages.sort((a, b) => a.name.localeCompare(b.name));

        return new Map<string, Language>(sortedLanguages.map((language) => [language.locale, language]));
    }

    public getLanguageOptions(): KeyValueOption[] {
        const languageOptions: KeyValueOption[] = [];
        this.getLanguages().forEach((language) => {
            languageOptions.push({ key: language.locale, value: language.name });
        });
        return languageOptions;
    }
}

export const systemInformationQuery = new SystemInformationQuery(systemInformationStore);
