import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '../../../../modules/shared/state/assets/asset.model';
import { AspectRatio } from '../../../../modules/shared/state/aspect-ratios/aspect-ratio.model';
import { AssetCollection } from '../../../../modules/shared/state/asset-collections/asset-collection.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AssetsQuery } from '../../../../modules/shared/state/assets/assets.query';
import { AssetsService } from '../../../../modules/shared/state/assets/assets.service';
import { RequestDto } from '../../../editor/models/request-dto.model';
import { SectionDto } from '../../../editor/models/section-dto.model';
import { AssetCollectionsQuery } from '../../../../modules/shared/state/asset-collections/asset-collections.query';
import { PageEvent } from '@angular/material/paginator';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { AssetForm } from '../../forms/asset.form';
import * as _ from 'lodash';
import { AssetCustomComponent } from '../asset-custom/asset-custom.component';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetBrowserService } from '../../services/asset-browser.service';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { FormGroupConfig } from '../../../../modules/forms/models/form-group-config.model';
import { FormFieldConfig } from '../../../../modules/forms/models/form-field-config.model';
import { delay, first, switchMap, tap } from 'rxjs/operators';
import { UrlParamsHandlerInterface } from '../../../../modules/links/interfaces/url-params-handler.interface';
import { ExcelUpdateListener } from '../../listeners/excel-update.listener';
import { UrlParamsService } from '../../../../modules/links/services/url-params.service';

@Component({
    selector: 'elias-assetbrowser-files',
    styleUrls: ['./asset-browser-files.component.scss'],
    templateUrl: './asset-browser-files.component.html',
})
export class AssetBrowserFilesComponent implements OnInit, UrlParamsHandlerInterface {
    @Input()
    assets: Asset[];

    @Input()
    selectedAssets: Asset[];

    @Input()
    aspectRatio: AspectRatio;

    @Input()
    assetsPerPage: number;

    @Input()
    selectedCollection: AssetCollection;

    @Input()
    showPagination: boolean;

    @Input()
    assetsInRow = 5;

    @Input()
    fixedHeight = true;

    @Output()
    clickAsset = new EventEmitter<Asset>();

    @Output()
    selectAsset = new EventEmitter<Asset>();

    @Output()
    clearSelection = new EventEmitter();

    @Output()
    changePage = new EventEmitter<number>();

    @Output()
    fileReplaced = new EventEmitter<number>();

    @Input()
    viewValue = 0;

    headings: string[] = ['Image', 'Name', 'Date', 'Edit'];

    public assetsLoading$ = this.assetsQuery.selectLoading();
    public assetsLoaded$ = this.assetsQuery.select('loaded');

    public loadingImage = environment.assetsRootPath + 'images/loading2.gif';
    public formConfig: FormConfig;
    ObjectKeys = Object.keys;

    constructor(
        public assetCollectionsQuery: AssetCollectionsQuery,
        public assetsQuery: AssetsQuery,
        public assetsService: AssetsService,
        public translateService: TranslateService,
        private assetBrowserService: AssetBrowserService,
        private assetForm: AssetForm,
        private excelUpdateListener: ExcelUpdateListener,
        private flashMessageService: FlashMessageService,
        private sideNavService: SideNavService,
        private urlParamsService: UrlParamsService
    ) {}

    ngOnInit() {
        this.formConfig = this.assetForm.getConfiguration();
        this.assetCollectionsQuery
            .selectAll()
            .pipe(
                tap((collections) => {
                    this.formConfig.elements.map((config: FormFieldConfig | FormGroupConfig) => {
                        if (config.name == 'assetCollections') {
                            const form = config as FormFieldConfig;
                            form.options = collections.map((collection) => {
                                return {
                                    key: collection.id,
                                    value: collection.name,
                                    color: collection.color,
                                };
                            });
                        }
                    });
                }),
                switchMap(() => this.handleUrlParams())
            )
            .subscribe();

        this.excelUpdateListener.excelUpdate$.subscribe();
    }

    handleUrlParams(): Observable<any> {
        return this.urlParamsService.getParamFromURL('asset').pipe(
            delay(400),
            first(),
            tap(({ value: assetId }) => {
                const asset = this.assetsQuery.getEntity(assetId);
                if (asset !== undefined) {
                    this.onAssetClick(asset);
                }
            })
        );
    }

    onAssetClick(asset: Asset) {
        if (!this.fixedHeight) {
            let data = {};
            data = _.cloneDeep(asset);
            data['image'] = asset.id;
            const formData = _.cloneDeep(data);
            const Ids = Object.keys(formData.assetCollections);
            formData.assetCollections = Ids.map((ac) => ac);

            const onSave = new EventEmitter<any>();
            const onDelete = new EventEmitter<any>();
            const onReplace = new EventEmitter<any>();

            const inputs = {
                data: formData,
                formConfig: this.formConfig,
            };
            const outputs = {
                save: onSave,
                delete: onDelete,
                replace: onReplace,
            };
            this.sideNavService.setComponent(AssetCustomComponent, inputs, outputs);

            onSave.subscribe((data) => {
                if (data) {
                    const payload = new RequestDto({ assetId: asset.id }, {}, { data });
                    this.assetsService.updateAsset(payload).subscribe(() => {
                        this.flashMessageService.showTranslatedInfo('propertyEditor.asset.success');
                        this.close();
                    });
                }
            });

            onDelete.subscribe((value) => {
                if (value) {
                    const params = {
                        assetId: asset.id,
                    };
                    const queryParams = {};

                    const payload: SectionDto = new SectionDto(params, queryParams);
                    this.selectedAssets = [];

                    this.clearSelection.emit();
                    this.close();
                    this.assetsService.deleteAsset(payload).subscribe(
                        () => {
                            this.flashMessageService.showTranslatedInfo('propertyEditor.asset.deletefile');
                        },
                        (error) => {
                            const displayErrorMessageFromApi =
                                error.error.message && error.status > 0 && error.status < 500;
                            if (displayErrorMessageFromApi) {
                                const errorMessage = displayErrorMessageFromApi
                                    ? error.error.message
                                    : this.translateService.instant('propertyEditor.remove.error');
                                this.flashMessageService.showError(errorMessage);
                            } else {
                                throwError(error);
                            }
                        }
                    );
                }
            });

            onReplace.subscribe((value) => {
                this.replace(value);
                this.close();
            });
        } else {
            this.clickAsset.emit(asset);
        }
    }

    close() {
        this.sideNavService.toggle();
    }

    isSelected(asset) {
        return this.selectedAssets.map((a: Asset) => a.id).includes(asset.id);
    }

    isBeingProcessed(asset: Asset): boolean {
        if (this.assetsService.isExcel(asset)) {
            return asset.processedAt === null;
        }

        return false;
    }

    onPageChange(pageEvent: PageEvent) {
        this.changePage.emit(pageEvent.pageIndex + 1);
        return false;
    }

    onReplaceFile(event: any) {
        this.fileReplaced.emit(event.item);
    }

    replace(asset) {
        this.fileReplaced.emit(asset);
    }

    stopEvent(event) {
        event.stopPropagation();
    }

    getAssetMimeType(value) {
        return this.assetBrowserService.getAssetMimeType(value);
    }

    trackByItemId(index, item) {
        return item.id;
    }

    trackByIndex(index, item) {
        return index;
    }
}
