import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestDto } from '../../models/request-dto.model';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { MapStore } from './map.store';
import { Map } from './map.model';

@Injectable()
export class MapService {
    constructor(private mapStore: MapStore, private domainService: DomainService, private http: HttpClient) {}

    getMapsForSection(payload: RequestDto): Observable<Map[]> {
        this.mapStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/maps`;
        return this.http.get<Map[]>(url).pipe(
            tap((maps) => {
                maps = maps.map((map) => {
                    map.id = map.nodeId;
                    map.content = URL.createObjectURL(this.b64toBlob(map.content));
                    return map;
                });
                this.mapStore.setLoading(false);
                this.mapStore.upsertMany(maps);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    loadImageForMap(payload: RequestDto): Observable<Blob> {
        this.mapStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/maps/${payload.getParam('mapId')}`;

        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap((mapContent) => {
                if (mapContent.size > 0) {
                    this.mapStore.upsert(payload.getParam('mapId'), {
                        content: URL.createObjectURL(mapContent),
                    });
                }
                this.mapStore.setLoading(false);
            }),

            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 1024): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
