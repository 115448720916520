import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Map } from './map.model';

export interface MapState extends EntityState<Map> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Maps', resettable: true })
export class MapStore extends EntityStore<MapState, Map> {
    constructor() {
        super({ loaded: false });
    }
}

export const mapStore = new MapStore();
