import { NodePreset } from './node-preset.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { NodePresetsState, NodePresetsStore } from './node-presets.store';

@Injectable({
    providedIn: 'root',
})
export class NodePresetsQuery extends QueryEntity<NodePresetsState, NodePreset> {
    constructor(protected store: NodePresetsStore) {
        super(store);
    }
}
