import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { SectionsQuery } from '../state/sections/sections.query';
import { SectionsService } from '../state/sections/sections.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ChannelGuard implements CanActivate {
    constructor(
        private router: Router,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private publicationsQuery: PublicationsQuery
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAvailChannels(route.params.sectionId).pipe(
            switchMap((checkSuccess) => {
                if (checkSuccess) {
                    return of(true);
                } else {
                    return of(false);
                }
            })
        );
    }

    checkAvailChannels(sectionId): Observable<boolean> {
        const section = this.sectionsQuery.getEntity(sectionId);
        if (section) {
            const pub = this.publicationsQuery.getAll({
                filterBy: (entity) => entity.rootSectionId == section.rootId,
            });
            const publication = pub[0];

            let channelLength = 0;
            for (const key of Object.keys(publication.channelSettings)) {
                if (publication.channelSettings[key]['active'] && publication.channelSettings[key]['channel'].enabled) {
                    channelLength = channelLength + 1;
                }
            }

            if (channelLength > 0) {
                return of(true);
            } else {
                this.router.navigate(['/publications', publication.id]);
                return of(false);
            }
        } else {
            return new Observable<boolean>((observer) => {
                return this.sectionsService.getPublicationBySectionId(sectionId).subscribe((publication) => {
                    let channelLength = 0;
                    for (const key of Object.keys(publication.channelSettings)) {
                        if (
                            publication.channelSettings[key]['active'] &&
                            publication.channelSettings[key]['channel'].enabled
                        ) {
                            channelLength = channelLength + 1;
                        }
                    }
                    if (channelLength > 0) {
                        observer.next(true);
                        observer.complete();
                        return of(true);
                    } else {
                        this.router.navigate(['/publications', publication.id]);
                        return of(false);
                    }
                });
            });
        }
    }
}
