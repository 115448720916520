import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { SectionDto } from '../../models/section-dto.model';
import { Section } from './section.model';
import { SectionsStore } from './sections.store';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { ID } from '@datorama/akita';
import { SectionsQuery } from './sections.query';
import { LocksQuery } from '../locks/locks.query';

@Injectable()
export class SectionsService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private sectionsStore: SectionsStore,
        private sectionsQuery: SectionsQuery,
        private locksQuery: LocksQuery,
        private publicationsQuery: PublicationsQuery
    ) {}

    getSections(): Observable<Section[]> {
        this.sectionsStore.setLoading(true);
        const rootSectionId = (this.publicationsQuery.getActive() as Publication).rootSectionId;
        const url = `${this.domainService.apiBaseUrl}/sections/${rootSectionId}/subsections`;
        return this.http.get<Section[]>(url).pipe(
            tap((sections) => {
                this.sectionsStore.set(sections);
                this.sectionsStore.update({ loaded: true });
                this.sectionsStore.setLoading(false);
                this.sectionsStore.update(null, { locked: false });
                this.updateLocks();
            }),
            catchError((error: any) => throwError(error))
        );
    }

    addSection(parentId: string, body: any): Observable<{ newSection: Section; sections: Section[] }> {
        this.sectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${parentId}`;

        return this.http.post<{ newSection: Section; sections: Section[] }>(url, body).pipe(
            tap((response) => {
                this.sectionsStore.add(response.sections);
                this.sectionsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateSection(payload: SectionDto): Observable<Section> {
        this.sectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}`;
        return this.http.patch<Section>(url, payload.body).pipe(
            tap((section) => {
                this.sectionsStore.update(section.id, section);
                this.sectionsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    moveSection(payload: SectionDto): Observable<Section[]> {
        this.sectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam(
            'sectionId'
        )}/position/${payload.getParam('parentSectionId')}${payload.getQueryString()}`;
        return this.http.post<Section[]>(url, payload.body).pipe(
            tap((sections) => {
                this.sectionsStore.set(sections);
                this.sectionsStore.setLoading(false);
                this.updateLocks();
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteSection(payload: SectionDto): Observable<any> {
        this.sectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}`;
        return this.http.delete<Section[]>(url).pipe(
            tap((sections) => {
                this.sectionsStore.set(sections);
                this.sectionsStore.setLoading(false);
                this.updateLocks();
            }),
            catchError((error: any) => throwError(error))
        );
    }

    copySection(payload: SectionDto, id: ID): Observable<{ newSection: Section; sections: Section[] }> {
        this.sectionsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${id}/clone/${payload.getParam(
            'parentSectionId'
        )}${payload.getQueryString()}`;
        return this.http.post<{ newSection: Section; sections: Section[] }>(url, payload.body).pipe(
            tap((response) => {
                this.sectionsStore.set(response.sections);
                this.sectionsStore.setLoading(false);
                this.updateLocks();
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getPublicationBySectionId(id) {
        const url = `${this.domainService.apiBaseUrl}/sections/${id}/publication`;
        return this.http.get<Section>(url).pipe(catchError((error: any) => throwError(error)));
    }

    setLoaded(loaded) {
        this.sectionsStore.update({
            loaded,
        });
    }

    setActiveSection(section: Section) {
        this.setActiveSectionById(section.id);
    }

    setActiveSectionById(id: ID) {
        this.sectionsStore.setActive(id);
    }

    getActiveSectionById() {
        return this.sectionsQuery.getActiveId();
    }

    resetSections(sections: Section[]) {
        this.sectionsStore.setLoading(true);
        this.sectionsStore.set(sections);
        this.sectionsStore.setLoading(false);
        this.sectionsStore.update(null, { locked: false });
    }

    updateLock(id) {
        this.sectionsStore.update(id, { locked: true });
    }

    updateLocks() {
        this.locksQuery.selectAll().subscribe((locks) => {
            locks.forEach((lock) => {
                this.sectionsStore.update(lock['sectionId'], { locked: true });
            });
        });
    }
}
