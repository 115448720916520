import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AssetStorage } from './asset-storage.model';
import { RequestDto } from '../../../editor/models/request-dto.model';
import { AssetStoragesState, AssetStoragesStore } from './asset-storages.store';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { Order, withTransaction } from '@datorama/akita';
import { AkitaFiltersPlugin, searchFilterIn } from 'akita-filters-plugin';
import { AssetStoragesQuery } from './asset-storages.query';
import { ItemCounter } from '../../../../modules/shared/models/item-counter.model';

@Injectable()
export class AssetStoragesService {
    filterStorages: AkitaFiltersPlugin<AssetStoragesState>;

    constructor(
        private assetStoragesStore: AssetStoragesStore,
        private assetStoragesQuery: AssetStoragesQuery,
        private domainService: DomainService,
        private http: HttpClient
    ) {
        this.assetStoragesQuery = new AssetStoragesQuery(this.assetStoragesStore);
        this.filterStorages = new AkitaFiltersPlugin<AssetStoragesState>(this.assetStoragesQuery);
    }

    /** GET asset storages from the server */
    getAssetStorages(payload: RequestDto): Observable<AssetStorage[]> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/asset-storages/${payload.getParam('pubGroupId')}`;
        return this.http.get<AssetStorage[]>(url).pipe(
            withTransaction((assetStorages) => {
                this.assetStoragesStore.set(assetStorages);
                this.assetStoragesStore.update({ loaded: true });
                this.assetStoragesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getUnapprovedAssetStorages(payload: RequestDto): Observable<AssetStorage[]> {
        const url = `${this.domainService.apiBaseUrl}/asset-storages/${payload.getParam('pubGroupId')}`;
        return this.http.get<AssetStorage[]>(url, { params: { approved: 0 } });
    }

    createSharedStorages(payload: RequestDto): Observable<AssetStorage> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${payload.getParam('pubGroupId')}`;
        return this.http.post<AssetStorage>(url, payload.body).pipe(
            withTransaction((assetStorage) => {
                this.assetStoragesStore.add(assetStorage);
                this.assetStoragesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    leaveSharedStorage(payload: RequestDto): Observable<AssetStorage> {
        this.assetStoragesStore.setLoading(true);
        const id = payload.getParam('id');
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${payload.getParam(
            'id'
        )}/publication-group/${payload.getParam('pubGroupId')}`;

        return this.http.delete<AssetStorage>(url, {}).pipe(
            withTransaction(() => {
                this.assetStoragesStore.remove(id);
                this.assetStoragesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getAllSharedAssetStorages(): Observable<AssetStorage[]> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages`;

        return this.http.get<AssetStorage[]>(url);
    }

    getNumberOfApprovedPublicationGroupSharedStorages(sharedStorageId: string): Observable<ItemCounter> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${sharedStorageId}/publication-group-shared-storages?approved=1&count=1`;

        return this.http.get<ItemCounter>(url);
    }

    joinSharedAssetStorages(payload): Observable<AssetStorage> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/publication-group/${payload.getParam(
            'pubGroupId'
        )}`;

        return this.http.post<AssetStorage>(url, payload.body).pipe(
            withTransaction((assetStorage) => {
                this.assetStoragesStore.add(assetStorage);
                this.assetStoragesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    saveSharedStorage(payload): Observable<AssetStorage> {
        this.assetStoragesStore.setLoading(true);
        const id = payload.getParam('id');
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${payload.getParam('id')}`;
        return this.http.patch<AssetStorage>(url, payload.body).pipe(
            withTransaction((assetStorage) => {
                this.assetStoragesStore.upsert(id, assetStorage);
                this.assetStoragesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    approveStorageForPublication(payload): Observable<AssetStorage> {
        this.assetStoragesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${payload.getParam(
            'id'
        )}/publication-group/${payload.getParam('pubGroupId')}/approve`;
        return this.http.patch<AssetStorage>(url, payload.body);
    }

    selectAll(): Observable<AssetStorage[]> {
        // @ts-ignore
        return this.filterStorages.selectAllByFilters();
    }

    initialFilter() {
        this.getFilterValue('search');
        this.getFilterValue('type');
    }

    getFilterValue(id: string): any | null {
        return this.filterStorages.getFilterValue(id);
    }

    setSearchFilter(filterValue) {
        this.filterStorages.setFilter({
            id: 'search',
            value: filterValue,
            name: `" ${filterValue} "`,
            predicate: (entity) => searchFilterIn(filterValue, entity, 'name'),
        });
    }

    removeFilter(id: string) {
        this.filterStorages.removeFilter(id);
    }

    removeAllFilter() {
        this.filterStorages.clearFilters();
    }

    setSorting(sort) {
        this.setOrderBy(sort.key);
    }

    setOrderBy(by: any) {
        if (by == 'name') this.filterStorages.setSortBy({ sortBy: by, sortByOrder: Order.ASC });
        else if (by == 'updatedAt') this.filterStorages.setSortBy({ sortBy: by, sortByOrder: Order.DESC });
        else this.filterStorages.setSortBy({ sortBy: 'type', sortByOrder: Order.ASC });
    }

    getSortValue() {
        const sortValue = this.filterStorages.getSortValue();
        if (!sortValue) {
            return 'type';
        }
        return sortValue.sortBy;
    }
}
