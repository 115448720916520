import { QueryEntity } from '@datorama/akita';
import { ExcelStore, ExcelState, excelStore } from './excel.store';
import { Excel } from './excel.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ExcelQuery extends QueryEntity<ExcelState, Excel> {
    constructor(protected store: ExcelStore) {
        super(store);
    }
}

export const excelQuery = new ExcelQuery(excelStore);
