import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { NodeType } from '../../../types/node-types.type';
import { environment } from '../../../../../../environments/environment';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/visualchars';
import 'tinymce/icons/default';
import '../../../../../../assets/tinymce/plugins/colbreak/index';
import '../../../../../../assets/tinymce/plugins/spellchecker/index';
import { Node } from '../../../state/nodes/node.model';
import { TextNode } from '../../../models/node/text-node.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoggedInUserQuery } from '../../../../../modules/shared/state/logged-in-user/logged-in-user.query';
import { SystemInformationQuery } from '../../../../../modules/shared/state/system-information/system-information.query';

/* eslint-disable camelcase */
@Component({
    selector: 'elias-editor-edit-text',
    templateUrl: './edit-text.component.html',
    styleUrls: ['./edit-text.component.scss'],
})
export class EditTextComponent implements NodeEdit, AfterViewInit, OnDestroy, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings: any };
    @Input() node: TextNode | Node;
    @Input() nodeViewModel: Observable<TextNode | Node>;

    @Output() contentChange = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    localContent: string;
    type: NodeType;
    containerId = 'tinymce';
    inlineEditor: typeof tinymce;
    titleClass = 'h2';
    currLang = '';
    currLangUrl = '';
    tinymceSettings;

    setup: object = {
        setup: (editor) => {
            this.inlineEditor = editor;

            const handler = (event: Event) => {
                // event.stopPropagation();
                this.onContentChange();
            };

            editor.on('keyup', handler);
            editor.on('execcommand', handler);

            editor.on('mouseout', handler);

            editor.on('PreInit', function () {
                for (let i = 0; i < tinymce.settings.style_formats.length; i++) {
                    if (tinymce.settings.style_formats[i].block)
                        editor.formatter.register(
                            tinymce.settings.style_formats[i].block,
                            tinymce.settings.style_formats[i]
                        );
                }
            });

            editor.ui.registry.addMenuButton('custom_styleselect', {
                text: 'formatting',
                fetch(callback) {
                    const items = [];
                    for (let i = 0; i < tinymce.settings.style_formats.length; i++) {
                        items.push({
                            type: 'menuitem',
                            text: tinymce.settings.style_formats[i].title,
                            onAction(e) {
                                for (let ii = 0; ii < tinymce.settings.style_formats.length; ii++) {
                                    if (
                                        ii != i &&
                                        tinymce.settings.style_formats[ii].block &&
                                        editor.formatter.match(tinymce.settings.style_formats[ii].block)
                                    ) {
                                        editor.formatter.toggle(tinymce.settings.style_formats[ii].block);
                                    }
                                }
                                editor.focus(); /* focus first to assure format applied to an existing root block */
                                if (!editor.formatter.match(tinymce.settings.style_formats[i].block)) {
                                    editor.formatter.toggle(tinymce.settings.style_formats[i].block);
                                }
                                handler(e);
                            },
                        });
                    }
                    callback(items);
                },
            });
        },
    };

    constructor(
        private hostElement: ElementRef,
        private loggedInUserQuery: LoggedInUserQuery,
        private systemInformationQuery: SystemInformationQuery,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.containerId = 'tinymce-' + this.node.id;
        this.localContent = this.content;
        this.type = this.config.type;

        this.nodeViewModel.subscribe((nodeViewModel) => {
            if (nodeViewModel !== null && nodeViewModel.hasOwnProperty('level')) {
                const levelInt = parseInt(nodeViewModel['level']);
                this.titleClass = levelInt > 0 && levelInt <= 6 ? 'h' + levelInt : 'h2';
            }
        });
        this.currLang = this.loggedInUserQuery.getValue().locale;
        this.currLangUrl = environment.assetsRootPath + 'tinymce/js/langs/' + this.currLang + '.js';
    }

    ngAfterViewInit(): void {
        const settings = Object.assign(this.defaultSettings(), this.config.settings, this.setup);
        if (this.currLang !== 'en_US') {
            settings['language'] = this.currLang;
            settings['language_url'] = this.currLangUrl;
        }

        // Verify feature flag for spellchecking
        if (!this.systemInformationQuery.getValue().featureFlags['spellchecker']) {
            settings.plugins?.splice('spellchecker');
        }

        tinymce.init(settings);
        this.tinymceSettings = settings;
        this.runAutomaticSpellCheck();
    }

    ngOnDestroy(): void {
        tinymce.remove(this.inlineEditor);
    }

    defaultSettings(): object {
        return {
            selector: '#' + this.containerId,
            plugins: [
                'spellchecker',
                'lists',
                'advlist',
                'paste',
                'nonbreaking',
                'link',
                'hr',
                'colbreak',
                'quickbars',
                'visualchars',
            ],
            menubar: false,
            toolbar: 'spellchecker',
            style_formats: [
                {
                    title: this.translateService.instant('component.text.styles.small'),
                    block: 'small',
                },
                { title: this.translateService.instant('component.text.styles.big'), block: 'dfn' },
                {
                    title: this.translateService.instant('component.text.styles.author'),
                    block: 'abbr',
                },
                {
                    title: this.translateService.instant('component.text.styles.paragraph'),
                    block: 'p',
                },
                {
                    title: this.translateService.instant('component.text.styles.quote'),
                    block: 'blockquote',
                },
                {
                    title: this.translateService.instant('component.text.styles.title') + ' 2',
                    block: 'h2',
                },
                {
                    title: this.translateService.instant('component.text.styles.title') + ' 3',
                    block: 'h3',
                },
                {
                    title: this.translateService.instant('component.text.styles.title') + ' 4',
                    block: 'h4',
                },
                {
                    title: this.translateService.instant('component.text.styles.title') + ' 5',
                    block: 'h5',
                },
                {
                    title: this.translateService.instant('component.text.styles.title') + ' 6',
                    block: 'h6',
                },
            ],
            inline: true,
            quickbars_selection_toolbar: 'bold italic underline | link',
            quickbars_insert_toolbar: false,
            default_link_target: '_blank',
            link_default_protocol: 'https',
            link_title: false,
            auto_focus: this.containerId,
            skin_url: environment.assetsRootPath + 'tinymce/skins/ui/elias',
            paste_word_valid_elements: 'a,p,ul,ol,li,b,strong,i,u,h2,h3,h4,h5,h6,blockquote,sub,sup,br,em',
            valid_elements: 'p,br',
            fixed_toolbar_container: '#toolbar-content',
            valid_styles: {
                span: 'color,background-color,text-decoration',
                ol: 'list-style-type',
                ul: 'list-style-type',
            },
            nonbreaking_wrap: true,
            visualchars_default_state: true,
            // changed to color map
            color_map: ['FF0000', 'Red', 'FFFF00', 'Yellow'],
            advlist_bullet_styles: 'default',
            advlist_number_styles: 'default,lower-alpha',
            deprecation_warnings: false,

            // The paste preprocess function was introduced to remove data and aria attributes. This should be removed
            // once there is a build in solution for https://github.com/tinymce/tinymce/issues/8046
            paste_preprocess: (plugin, args: { content }): void => {
                let html = args.content as string;
                const tags = html.match(/(<\/?[\S][^>]*>)/gi);
                if (tags !== null) {
                    tags.forEach((tag) => {
                        html = html.replace(
                            tag,
                            tag.replace(
                                /(data-.+?=".*?")|(data-.+?='.*?')|(data-[a-zA-Z0-9-]+)|(aria-.+?=".*?")|(aria-.+?='.*?')|(aria-[a-zA-Z0-9-]+)/g,
                                ''
                            )
                        );
                    });
                }

                args.content = html;
            },
        };
    }

    onContentChange() {
        this.content = this.inlineEditor.getContent();
        this.contentChange.emit(this.content);
    }

    onSave(event: Event) {
        event.stopPropagation();
        this.save.emit(this.content);
    }

    runAutomaticSpellCheck(): void {
        setTimeout(
            function (that) {
                if (that.inlineEditor) {
                    that.inlineEditor.execCommand('mceSpellCheck');
                }
            },
            500,
            this
        );
    }
}
