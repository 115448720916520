import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';

@Injectable({
    providedIn: 'root',
})
export class NameStepControlForm {
    constructor() {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    name: 'sectionName',
                    type: 'text',
                    label: 'modal.createSection.title',
                    width: 100,
                    required: true,
                    validators: [],
                },
                {
                    name: 'sectionSubtitle',
                    type: 'text',
                    label: 'modal.createSection.subtitle',
                    required: false,
                    width: 100,
                    settings: {},
                    validators: [],
                },
            ],
        };
    }
}
