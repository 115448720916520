<div class="document-container layout-{{ publication.publicationGroup.layout }}">
    <ng-container *ngIf="loaded$ | async">
        <div class="document" [ngClass]="{ 'node-selected': selectedNode !== null }">
            <elias-shared-custom-image
                [id]="section.backgroundAssetImage"
                [byCSS]="true"
                *ngIf="section && section.backgroundAssetImage"></elias-shared-custom-image>
            <elias-editor-section-headline
                [section$]="section$"
                [ngClass]="{ 'pe-node': true }"
                (click)="nullFunction($event)"></elias-editor-section-headline>
            <div
                id="droppable"
                [dragula]="'bag-draggable'"
                [ngClass]="{ 'document-without-nodes': (nodes$ | async).length === 0 }">
                <ng-container *ngFor="let node of nodes$ | async">
                    <ng-container *ngIf="node.type !== 'undefined'; else createComponent">
                        <elias-editor-node-editable
                            [ngClass]="{
                                'pe-node': true,
                                'pe-node-active': node.editing,
                                'pe-node-inactive': !node.editing,
                                'pe-needs-review': node.needsReview
                            }"
                            [node]="node"
                            [editing]="selectedNode !== null || active === 1"
                            data-id="{{ node.id }}"
                            class="node-type-{{ node.type }}"
                            (click)="onSelect($event, node)"
                            (update)="deselectNode($event)"
                            (remove)="onRemove($event)"
                            (contentChange)="onContentChange($event)">
                        </elias-editor-node-editable>
                    </ng-container>
                    <ng-template #createComponent>
                        <elias-editor-node-selection
                            [ngClass]="{ 'pe-node': true }"
                            [showSpinner]="showSpinner"
                            (selection)="selectedOption($event)"
                            (remove)="removeOptions($event)">
                        </elias-editor-node-selection>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="(loaded$ | async) === false">
        <elias-shared-loader></elias-shared-loader>
    </ng-container>
</div>
