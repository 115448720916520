import { User } from './user.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface UserState extends EntityState<User> {
    loaded: boolean;
    ui: {
        viewOption: number;
        filters: {
            role: string | null;
            state: string | null;
        };
        search: string;
    };
}

const initalState = {
    loaded: false,
    ui: {
        viewOption: 0,
        filters: {
            role: null,
            state: null,
        },
        search: null,
    },
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Users' })
export class UsersStore extends EntityStore<UserState, User> {
    constructor() {
        super(initalState);
    }
}
