import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Editor, Node } from '@tiptap/core';
import { Mention, MentionOptions } from '@tiptap/extension-mention';
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import { MentionMenuComponent } from '../../components/mention-menu/mention-menu.component';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { first } from 'rxjs/operators';
import { PublicationGroupQuery } from '../../../../publication-group/state/publication-group/publication-group.query';
import { TranslateService } from '@ngx-translate/core';
import { Mentionable } from '../../models/mentionable.model';

@Injectable({
    providedIn: 'root',
})
export class TiptapMentionService {
    constructor(
        private publicationGroupQuery: PublicationGroupQuery,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    public getConfiguration(viewContainerRef: ViewContainerRef): Node<MentionOptions> {
        return Mention.extend({
            addAttributes() {
                const defaultAttributes = this.parent?.();

                return {
                    ...defaultAttributes,
                    mentionType: {
                        default: null,
                        parseHTML: (element) => element.getAttribute('data-mention-type'),
                        renderHTML: (attributes) => {
                            if (!attributes['mentionType']) {
                                return {};
                            }

                            return {
                                'data-mention-type': attributes['mentionType'],
                            };
                        },
                    },
                };
            },
        }).configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: {
                items: this.queryMentionItems(),
                render: this.renderMentionMenu(viewContainerRef),
                allowSpaces: true,
            },
        });
    }

    public getMentionedUsers({ state: { doc } }: Editor): string[] {
        const usersIds: string[] = [];

        doc.descendants((node) => {
            if (node.type.name === 'mention' && node.attrs['mentionType'] === 'user') {
                usersIds.push(node.attrs['id']);
            }
        });

        return [...new Set(usersIds)];
    }

    public getMentionedGroups({ state: { doc } }: Editor): string[] {
        const groupIds: string[] = [];

        doc.descendants((node) => {
            if (node.type.name === 'mention' && node.attrs['mentionType'] === 'group') {
                groupIds.push(node.attrs['id']);
            }
        });

        return [...new Set(groupIds)];
    }

    private queryMentionItems(): (context: { editor: Editor; query: string }) => Mentionable[] {
        const users = this.publicationGroupQuery.getEditorsAndChiefEditorsForActive();

        const mentionableUsers: Mentionable[] = users.map((user) => ({
            id: user.id as string,
            label: `${user.firstName} ${user.lastName}`,
            mentionType: 'user',
        }));

        const mentionableGroups: Mentionable[] = [
            {
                id: 'chiefEditors',
                label: this.translateService.instant('comments.mentions.groups.chiefEditors'),
                mentionType: 'group',
            },
            {
                id: 'editors',
                label: this.translateService.instant('comments.mentions.groups.editors'),
                mentionType: 'group',
            },
            {
                id: 'all',
                label: this.translateService.instant('comments.mentions.groups.all'),
                mentionType: 'group',
            },
        ];

        const mentionable = [...mentionableUsers, ...mentionableGroups];

        return ({ editor, query }: { editor: Editor; query: string }) => {
            return mentionable.filter(({ label }) => label.toLowerCase().startsWith(query.toLowerCase()));
        };
    }

    private renderMentionMenu(viewContainerRef: ViewContainerRef) {
        return () => {
            let component: ComponentRef<MentionMenuComponent> | undefined;

            return {
                onStart: (props: SuggestionProps) => {
                    if (props.clientRect === null) {
                        return;
                    }

                    // Setup component and inputs
                    component = viewContainerRef.createComponent(MentionMenuComponent);

                    component.instance.mentionableItems = props.items as Mentionable[];
                    component.instance.selectFunction = props.command;
                    component.instance.clientRectFunction = props.clientRect;

                    /*
                        TODO: Remove after upgrade to Angular 14.1
                        https://github.com/angular/angular/pull/46641
                     */
                    component.instance.ngOnChanges();

                    document.body.appendChild(component.location.nativeElement);

                    // Remove menu component when sidenav is closed
                    this.sideNavService
                        .getClosedStartObservable()
                        .pipe(first())
                        .subscribe(() => {
                            component?.destroy();
                        });
                },
                onUpdate(props: SuggestionProps) {
                    if (props.clientRect === null || component === undefined) {
                        return;
                    }

                    // Update inputs
                    component.instance.mentionableItems = props.items;
                    component.instance.selectFunction = props.command;
                    component.instance.clientRectFunction = props.clientRect;

                    /*
                        TODO: Remove after upgrade to Angular 14.1
                        https://github.com/angular/angular/pull/46641
                     */
                    component.instance.ngOnChanges();
                },
                onKeyDown(props: SuggestionKeyDownProps) {
                    if (props.event.key === 'Escape' && component) {
                        props.event.stopPropagation();

                        component?.destroy();
                        component = undefined;

                        return true;
                    }

                    return !!component?.instance.onKeyDown(props);
                },
                onExit() {
                    component?.destroy();
                },
            };
        };
    }
}
