import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Node } from '../../../state/nodes/node.model';
import { TextNode } from '../../../models/node/text-node.model';
import { Observable } from 'rxjs';
import { TitlePipeReplacerService } from '../../../services/utilities/title-pipe-replacer.service';

@Component({
    selector: 'elias-editor-display-text',
    templateUrl: './display-text.component.html',
    styleUrls: ['./display-text.component.scss'],
})
export class DisplayTextComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() titleContent: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: TextNode | Node;
    @Input() nodeViewModel: Observable<Node>;

    constructor(private titleReplacerService: TitlePipeReplacerService) {}

    titleClass = 'h2';
    additionalText: string;

    ngOnInit() {
        this.config = this.config || { type: 'text' };
        this.titleContent = this.titleReplacerService.replacePipes(this.content, true);
        if (this.node.hasOwnProperty('level')) {
            const levelInt = parseInt(this.node['level']);
            this.titleClass = levelInt > 0 && levelInt <= 6 ? 'h' + levelInt : 'h2';
        }
        if (this.node.hasOwnProperty('additionalText')) {
            this.additionalText = this.node['additionalText'].toString();
        }
    }

    hasOwnColBreaks(content): boolean {
        return content.indexOf('<hr class="col-break"') > -1;
    }

    replaceColbreaks(content) {
        if (this.hasOwnColBreaks(content)) {
            let replaceContent = "<div class='col-wrap'>";

            replaceContent += content.replace(/<hr class="col-break" \/>/g, '</div><div class="col-wrap">');

            replaceContent += '</div>';

            return replaceContent;
        }

        return content;
    }
}
