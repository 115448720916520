import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'elias-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    twoFactor: FormGroup;
    returnUrl: string;
    loginErrorMessage?: string;
    secureLoginErrorMessage?: string;
    isVisible = true;
    isLoggedIn = false;
    codeError = false;
    loading$: Observable<boolean> = of(false);
    images: any[] = ['elias_login_background_1.jpg', 'elias_login_background_2.jpg', 'elias_login_background_3.jpg'];
    homeImage: any;
    isSaving;

    constructor(
        public authService: AuthService,
        public router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private translateService: TranslateService
    ) {
        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading$ = of(true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading$ = of(false);
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngOnInit() {
        const value = Math.floor(Math.random() * this.images.length + 1);
        this.homeImage = "url('/../../../assets/images/" + this.images[value - 1] + "')";

        this.loginForm = this.fb.group({
            username: ['', [Validators.required, Validators.maxLength(255)]],
            password: ['', [Validators.required, Validators.maxLength(255)]],
        });

        this.twoFactor = this.fb.group({
            code: ['', [Validators.required]],
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/publication-groups';
    }

    login() {
        this.isSaving = true;
        const username = this.loginForm.controls['username'].value;
        const password = this.loginForm.controls['password'].value;

        this.authService.login(username, password).subscribe(
            (authenticated) => {
                this.isSaving = false;
                this.isLoggedIn = !this.isLoggedIn;
                // If authenticated is true, the request was sent from a whitelisted ip
                if (authenticated) {
                    this.router.navigate([this.returnUrl], { relativeTo: this.route });
                }
            },
            (error) => {
                if (error.status === 401) {
                    this.loginErrorMessage = this.translateService.instant('login.invalidLogin');
                } else {
                    this.loginErrorMessage = this.translateService.instant('login.networkError');
                }

                this.isSaving = false;
            }
        );
    }

    secureLogin() {
        const securityCode = this.twoFactor.controls['code'].value;

        this.authService.send2FACode(securityCode).subscribe(
            (data) => {
                this.codeError = false;
                this.router.navigate([this.returnUrl], { relativeTo: this.route });
            },
            (error) => {
                if (error.status === 401) {
                    this.codeError = true;
                    this.secureLoginErrorMessage = this.translateService.instant('login.invalidCode');
                } else {
                    this.loginErrorMessage = this.translateService.instant('login.networkError');
                }
            }
        );
    }

    startAgain() {
        this.authService.startAgain().subscribe((data) => {
            const username = this.loginForm.controls['username'].value;
            const password = this.loginForm.controls['password'].value;
            this.authService.login(username, password).subscribe(() => {});
        });
    }
}
