import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { PropertyEditorConfigurationCollection } from '../../models/property-editor-configuration-collection.model';
import { RequestDto } from '../../models/request-dto.model';
import { PropertyEditorStore } from './property-editor.store';
import { SelectedElement } from '../../models/selected-element.model';
import { PropertyEditorConfiguration } from '../../models/property-editor-configuration.model';
import { SectionsStore } from '../sections/sections.store';
import { Property } from '../../models/property.model';
import { PropertyEditorQuery } from './property-editor.query';
import { Observable, throwError } from 'rxjs';
import { DomainService } from '../../../../modules/core/services/domain.service';

@Injectable()
export class PropertyEditorService {
    constructor(
        private domainService: DomainService,
        private propertyEditorStore: PropertyEditorStore,
        private sectionsStore: SectionsStore,
        private propertyEditorQuery: PropertyEditorQuery,
        private http: HttpClient
    ) {}

    /** GET nodes for specified section from the server */
    loadPropertyEditorConfigurations(requestDto: RequestDto): Observable<any> {
        this.propertyEditorStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/property-editor-configurations/${requestDto.getParam(
            'rootSectionId'
        )}`;
        return this.http.get<PropertyEditorConfigurationCollection>(url).pipe(
            tap((configuration: any) => {
                const nodeProperties = configuration.nodes.reduce(
                    (
                        entities: { [type: string]: Property },
                        propertyEditorConfiguration: PropertyEditorConfiguration
                    ) => {
                        return {
                            ...entities,
                            [propertyEditorConfiguration.type]: propertyEditorConfiguration,
                        };
                    },
                    {
                        ...this.propertyEditorQuery.getValue().nodeConfigurations,
                    }
                );

                const sectionProperties = configuration.sections.reduce(
                    (
                        entities: { [type: string]: Property },
                        propertyEditorConfiguration: PropertyEditorConfiguration
                    ) => {
                        return {
                            ...entities,
                            [propertyEditorConfiguration.type]: propertyEditorConfiguration,
                        };
                    },
                    {
                        ...this.propertyEditorQuery.getValue().sectionConfigurations,
                    }
                );

                this.propertyEditorStore.update({
                    nodeConfigurations: nodeProperties,
                    sectionConfigurations: sectionProperties,
                    loading: false,
                    loaded: true,
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    unselectElement() {
        this.propertyEditorStore.update({
            selectedElement: null,
        });
    }

    selectElement(selectedElement: SelectedElement) {
        this.propertyEditorStore.update({
            selectedElement,
        });
    }
}
