import { UsersStore, UserState } from './users.store';
import { User } from './user.model';
import { QueryConfig, QueryEntity, SortBy } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Creators } from '../../models/creator.model';

const sortBy: SortBy<User> = (a: User, b: User) => {
    return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
};

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy,
})
export class UsersQuery extends QueryEntity<UserState, User> {
    selectViewOption$ = this.select((state) => state.ui.viewOption);
    selectFilters$ = this.select((state) => state.ui.filters);
    selectSearch$ = this.select((state) => state.ui.search);

    constructor(protected store: UsersStore) {
        super(store);
    }

    getCreators(): Creators {
        return Object.fromEntries(
            this.getAll().map((user) => {
                const userId = user.id;
                const name = `${user.firstName} ${user.lastName}`;
                return [
                    userId,
                    {
                        name,
                        user,
                    },
                ];
            })
        );
    }
}
