import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '../../../../modules/shared/state/assets/asset.model';
import { AssetBrowserComponent } from '../asset-browser/asset-browser.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'elias-assetbrowser-button',
    styleUrls: ['asset-browser-button.component.scss'],
    templateUrl: './asset-browser-button.component.html',
})
export class AssetBrowserButtonComponent {
    @Input()
    text: string;

    @Input()
    single = false;

    @Input()
    allowedTypes: string[] = null;

    @Input()
    block = false;

    @Input()
    selectedAssets;

    @Output()
    assetsSelected = new EventEmitter<Asset[]>();

    @Input() type = 'images';

    constructor(public dialog: MatDialog) {}

    openAssetManager() {
        const modalRef = this.dialog.open(AssetBrowserComponent, {
            width: '1574px',
            height: '798px',
            data: {
                single: this.single,
                allowedTypes: this.allowedTypes,
                selectable: true,
                fullscreen: false,
                type: this.type,
                preSelectedAsset: this.selectedAssets,
            },
            autoFocus: false,
        });

        modalRef.afterClosed().subscribe((result) => {
            if (result) {
                this.assetsSelected.emit(result);
            }
        });
    }
}
