import { Component, Input } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';

@Component({
    selector: 'elias-form-element-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
})
export class FormCheckboxComponent extends BasicFormElementComponent {
    @Input()
    text: any;
}
