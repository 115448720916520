import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UsersQuery } from '../state/users/users.query';
import { Injectable } from '@angular/core';
import { User } from '../state/users/user.model';
import { LoggedInUserQuery } from '../state/logged-in-user/logged-in-user.query';

@Injectable({ providedIn: 'root' })
export class UserDetailValidator {
    constructor(private userQuery: UsersQuery, private loggedInUserQuery: LoggedInUserQuery) {}

    uniqueUsername(control: AbstractControl): ValidationErrors | boolean | null {
        const value = control.value as String;
        const user = this.userQuery.getAll({ filterBy: (entity) => entity.username === value });
        if (this.loggedInUserQuery.getValue() && this.loggedInUserQuery.getValue().username === value) {
            return null;
        } else if (this.userQuery.getActive() && (this.userQuery.getActive() as User).username === value) {
            return null;
        } else if (user.length > 0) {
            return { username: true };
        }
        return null;
    }

    uniqueEmail(control: AbstractControl): ValidationErrors | boolean | null {
        const value = control.value as String;
        const users = this.userQuery.getAll({ filterBy: (entity) => entity.email === value });

        if (users.length > 0) {
            const user = users[0];
            return { uniqueemail: true, existsUserFullName: user.firstName + ' ' + user.lastName };
        }
        return null;
    }

    passwordMatch(control: AbstractControl): ValidationErrors | boolean | null {
        if (control.parent) {
            if (control.parent.controls['password'].value == control.parent.controls['repeatPassword'].value) {
                return null;
            }
        }
        return { passwordmatch: true };
    }
}
