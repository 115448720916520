import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AssetStorage } from './asset-storage.model';
import { Injectable } from '@angular/core';

export interface AssetStoragesState extends EntityState<AssetStorage> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'AssetStorages', resettable: true })
export class AssetStoragesStore extends EntityStore<AssetStoragesState, AssetStorage> {
    constructor() {
        super({ loaded: false });
    }
}

export const assetStoragesStore = new AssetStoragesStore();
