import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'elias-editor-node-selection',
    templateUrl: './node-selection.component.html',
    styleUrls: ['./node-selection.component.scss'],
})
export class NodeSelectionComponent implements OnInit {
    @Input() showSpinner = false;

    @Output() selection = new EventEmitter();
    @Output() remove = new EventEmitter();
    newData;

    constructor(private data: DataService) {}

    ngOnInit(): void {
        this.data.data$.subscribe((response) => {
            if (response && response.hasOwnProperty('data')) {
                this.newData = response.data;
            }
        });
    }

    selectOption(node) {
        this.selection.emit(node);
    }

    onRemove() {
        this.remove.emit(this.newData);
    }

    trackByIndex(index, item) {
        return index;
    }
}
