import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { PublicationsService } from '../../../publication/state/publication/publications.service';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { PublicationsStore } from '../../../publication/state/publication/publications.store';
import { SectionsQuery } from '../../editor/state/sections/sections.query';
import { SectionsService } from '../../editor/state/sections/sections.service';

@Injectable()
export class AssetBrowserDataResolver implements Resolve<any> {
    constructor(
        private publicationsService: PublicationsService,
        private publicationsQuery: PublicationsQuery,
        private publicationsStore: PublicationsStore,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const observables = [];
        let id = this.publicationsQuery.getActiveId();

        if (!this.publicationsQuery.hasEntity(id)) {
            observables.push(this.publicationsService.getSinglePublication(id));
        }
        if (!this.sectionsQuery.getValue().loaded) {
            observables.push(this.sectionsService.getSections());
        }

        if (observables.length > 0) {
            return forkJoin(observables).pipe(
                first(),
                tap(() => {
                    this.publicationsStore.setActive(id);
                })
            );
        } else {
            this.publicationsStore.setActive(id);
            return of(true);
        }
    }
}
