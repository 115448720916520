import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { ImagesQuery } from '../../../../editor/editor-container/state/images/images.query';
import { AspectRatiosQuery } from '../../state/aspect-ratios/aspect-ratios.query';

@Component({
    selector: 'elias-shared-custom-image',
    styleUrls: ['./custom-image.component.scss'],
    templateUrl: './custom-image.component.html',
})
export class CustomImageComponent implements OnInit {
    internId!: string;
    path: SafeUrl | string = this.getLoadingImage();

    @Input()
    set id(id: string) {
        this.internId = id;
        this.path = this.getPath();
    }

    get id() {
        return this.internId;
    }

    @Input()
    aspectRatio = null;

    @Input()
    allowFallbackImage = false;

    @Input()
    byCSS = false;

    constructor(
        private sanitizer: DomSanitizer,
        private imagesQuery: ImagesQuery,
        private aspectRatiosQuery: AspectRatiosQuery
    ) {}

    ngOnInit() {
        this.imagesQuery.selectAll().subscribe((images) => {
            this.path = this.getPath();
        });
    }

    getPath() {
        if (!this.internId || !this.imagesQuery.hasEntity(this.id)) {
            return this.getLoadingImage();
        }

        const image = this.imagesQuery.getEntity(this.id);
        if (image === undefined || typeof image.aspectRatios !== 'object') {
            return this.getLoadingImage();
        }

        const aspectRatio = this.getAspectRatioId();
        if (aspectRatio && image.aspectRatios[aspectRatio]) {
            return this.getImagePath(this.id, aspectRatio);
        }

        if (this.allowFallbackImage) {
            // Fallback to aspect ratio the image is available for
            const availableAspectRatios = Object.keys(image.aspectRatios);
            return this.getImagePath(this.id, availableAspectRatios[0]);
        }

        return this.getLoadingImage();
    }

    getAspectRatioId(): string | null {
        if (typeof this.aspectRatio === 'string') {
            return this.aspectRatio;
        }

        const aspectRatios = this.aspectRatiosQuery.getAll();
        const defaultAspectRatio = aspectRatios.filter((aspectRatio) => aspectRatio.default === true);

        if (defaultAspectRatio.length > 0) {
            return defaultAspectRatio[0].id;
        }

        if (aspectRatios.length > 0) {
            return aspectRatios[0].id;
        }

        return null;
    }

    getImagePath(id, aspectRatio) {
        if (this.byCSS) {
            return this.sanitizer.bypassSecurityTrustStyle(
                'url("' + this.imagesQuery.getEntity(this.id).aspectRatios[aspectRatio].content + '")'
            );
        }
        return this.sanitizer.bypassSecurityTrustUrl(
            this.imagesQuery.getEntity(this.id).aspectRatios[aspectRatio].content
        );
    }

    getLoadingImage() {
        if (this.byCSS) {
            return this.sanitizer.bypassSecurityTrustStyle(
                'url("' + environment.assetsRootPath + 'images/loading2.gif")'
            );
        }
        return this.sanitizer.bypassSecurityTrustUrl(environment.assetsRootPath + 'images/loading2.gif');
    }
}
