import { QueryEntity } from '@datorama/akita';
import { ImagesState, ImagesStore, imagesStore } from './images.store';
import { Image } from './image.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ImagesQuery extends QueryEntity<ImagesState, Image> {
    constructor(protected store: ImagesStore) {
        super(store);
    }
}

export const imagesQuery = new ImagesQuery(imagesStore);
