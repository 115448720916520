import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { NodesQuery } from '../state/nodes/nodes.query';
import { of, Observable } from 'rxjs';
import { Node } from '../state/nodes/node.model';
import { Section } from '../state/sections/section.model';
import { NodesService } from '../state/nodes/nodes.service';
import { NodeDto } from '../models/node-dto.model';
import { SectionsService } from '../state/sections/sections.service';
import { SectionDto } from '../models/section-dto.model';
import { GridNode } from '../models/node/grid-node.model';
import { ColumnsReorganizerService } from './utilities/columns-reorganizer.service';
import { SelectedElement, SelectedNode } from '../models/selected-element.model';
import { SelectedElementType } from '../enums/selected-element-type.enum';

@Injectable()
export class SaveService {
    content: string | null = null;
    selectedNode: SelectedNode | null = null;
    selectedNode$ = this.nodesQuery.selectAll({ filterBy: (node) => node.editing === true });

    constructor(
        private columnsReorganizerService: ColumnsReorganizerService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private sectionsService: SectionsService
    ) {
        this.selectedNode$.subscribe((elements: Node[]) => {
            if (elements.length > 0) {
                this.selectedNode = new SelectedNode(elements[0]);
            } else {
                this.selectedNode = null;
            }
        });
    }

    setContent(content: string) {
        this.content = content;
    }

    resetContent(): void {
        this.content = null;
    }

    /* save selected node or section with all its property editor properties */
    saveAll(deselectAfter = true): Observable<any> {
        // check if element is selected
        if (this.selectedNode) {
            if (!this.content) {
                this.content = this.selectedNode.element.content;
            }
            return this.saveNode(deselectAfter);
        }
        return of(true);
    }

    /* save properties */
    saveProperties(selectedElement: SelectedElement, properties: any): Observable<Node | Section | boolean> {
        const body = this.preprocessProperties(selectedElement, properties);

        if (selectedElement.type === SelectedElementType.Node) {
            const payload = new NodeDto({ nodeId: selectedElement.element.id }, {}, body);
            return this.nodesService.updateNode(payload).pipe(
                tap((node) => {
                    this.nodesService.deselect(node);
                })
            );
        } else {
            const payload = new SectionDto({ sectionId: selectedElement.element.id }, {}, body);
            return this.sectionsService.updateSection(payload);
        }
    }

    /** save node content and properties */
    saveNode(deselectAfter = false): Observable<Node | boolean> {
        if (this.selectedNode) {
            const body = {
                content: this.content ? this.content : this.selectedNode.element.content,
            };
            const payload = new NodeDto({ nodeId: this.selectedNode.element.id }, {}, body);
            return this.nodesService.updateNode(payload).pipe(
                tap((node) => {
                    if (deselectAfter) {
                        this.nodesService.deselect(node);
                        this.resetContent();
                    }
                })
            );
        }
        return of(true);
    }

    private preprocessProperties(selectedElement: SelectedElement, properties: any): any {
        if (selectedElement.type === SelectedElementType.Section) {
            return properties;
        }

        const element = selectedElement.element as Node;

        switch (element.type) {
            case 'key-figure':
            case 'key-image': {
                if ((element as GridNode).cols !== properties.cols) {
                    const parsedContent = JSON.parse(element.content);
                    const result = this.columnsReorganizerService.reorganizeColumns(parsedContent, properties.cols);

                    properties['content'] = JSON.stringify(result);
                }
            }
        }

        return properties;
    }
}
