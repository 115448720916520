import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { SharedModule } from '../modules/shared/shared.module';
import { MaterialModule } from '../modules/material/material.module';
import { JwtHelperServiceProvider } from '../modules/core/providers/jwt-helper-service.provider';

@NgModule({
    declarations: [LoginComponent],
    imports: [CommonModule, AuthRoutingModule, SharedModule, MaterialModule],
    providers: [JwtHelperServiceProvider],
})
export class AuthModule {}
