<div [formGroup]="formGroup">
    <mat-checkbox [formControlName]="name" color="primary" [ngClass]="{ 'full-width': true }">
        <span [ngClass]="{ styling: !text }">{{ label }}</span>
    </mat-checkbox>
    <!--<ng-container *ngIf="text==='ROLE_ADMIN_DESCRIPTION'">
        <p class="description">{{'user.roledescription.'+label | translate}}</p>
    </ng-container>-->
    <p class="description" *ngIf="text">{{ text }}</p>
    <ng-container *ngIf="label === 'Admin'">
        <mat-divider></mat-divider>
    </ng-container>
</div>
