import { Injectable } from '@angular/core';
import { ITreeOptions, TREE_ACTIONS, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { SectionDto } from '../models/section-dto.model';
import { SaveService } from './save.service';
import { SectionsService } from '../state/sections/sections.service';
import { Router } from '@angular/router';
import { NewSectionComponent } from '../dialogs/new-section/new-section.component';
import { MatDialog } from '@angular/material/dialog';
import { ID } from '@datorama/akita';
import { DeleteComponent } from '../../../modules/shared/dialogs/delete/delete.component';
import { PublicationsQuery } from '../../../publication/state/publication/publications.query';
import { LocksQuery } from '../state/locks/locks.query';
import { LoggedInUserQuery } from '../../../modules/shared/state/logged-in-user/logged-in-user.query';
import { FlashMessageService } from '../../../modules/core/services/flash-message.service';
import { ChartsService, ExcelService, ImagesService } from '.';
import { RequestDto } from '../models/request-dto.model';
import { forkJoin, Subject } from 'rxjs';
import { MapService } from '../state/maps/map.service';
import { Publication } from '../../../publication/state/publication/publication.model';

@Injectable()
export class TreeService {
    private shouldActivate = new Subject<string>();
    private shouldSelect = new Subject<ID>();
    private shouldRemoveFocus = new Subject<string>();
    private shouldMoveTreeNode = new Subject<SectionDto>();
    public shouldActivate$ = this.shouldActivate.asObservable();
    public shouldSelect$ = this.shouldSelect.asObservable();
    public shouldRemoveFocus$ = this.shouldRemoveFocus.asObservable();
    public shouldMoveTreeNode$ = this.shouldMoveTreeNode.asObservable();
    private isRequested = true;

    constructor(
        private chartsService: ChartsService,
        public dialog: MatDialog,
        private excelService: ExcelService,
        private flashMessageService: FlashMessageService,
        private imagesService: ImagesService,
        private locksQuery: LocksQuery,
        private loggedInUserQuery: LoggedInUserQuery,
        private mapService: MapService,
        private publicationsQuery: PublicationsQuery,
        private router: Router,
        private saveService: SaveService,
        private sectionsService: SectionsService
    ) {}

    public getTreeOptions(): ITreeOptions {
        return {
            displayField: 'title',
            actionMapping: {
                mouse: {
                    drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
                        // prevent dropping node on itself
                        if (from.id === to.parent.id) {
                            return;
                        }
                        this.onMoveTreeNode({ node: from, to });
                    },
                    click: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForActivation(tree, node, $event);
                    },
                },
                keys: {
                    13: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForActivation(tree, node, $event);
                    },
                    32: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForActivation(tree, node, $event);
                    },
                },
            },
            allowDrag: (node) => {
                // avoid dragging root element
                return node.parent !== null && !node.isRoot;
            },
            allowDrop: (node, { parent, index }) => {
                // avoid dropping outside root element
                return parent.parent != null;
            },
        };
    }

    checkForActivation(tree: TreeModel, node: TreeNode, $event) {
        if (tree.getActiveNodes().length === 0 || node.id !== tree.getActiveNodes()[0].id) {
            if (this.isRequested) {
                this.isRequested = false;
                TREE_ACTIONS.FOCUS(tree, node, $event);
                this.activate(node.id);
            }
        }
    }

    onMoveTreeNode($event): void {
        let dragValue = true;
        let allLocks;
        this.locksQuery.selectAll().subscribe((locks) => {
            allLocks = locks;
        });
        allLocks.forEach((lock) => {
            if ($event.node.data.id == lock.section.id && lock.user.id != this.loggedInUserQuery.getValue().id)
                dragValue = false;
        });

        if (dragValue) {
            let previousSectionId = null;
            if ($event.to.index > 0) {
                previousSectionId = $event.to.parent.children[$event.to.index - 1].id;
            }
            // add after last child when dropping on node
            if ($event.to.dropOnNode) {
                if ($event.to.parent.children.length > 0) {
                    previousSectionId = $event.to.parent.children[$event.to.parent.children.length - 1].id;
                }
            }

            const params = {
                sectionId: $event.node.id,
                parentSectionId: $event.to.parent.id,
            };
            const queryParams = {
                previousSectionId,
            };

            const payload: SectionDto = new SectionDto(params, queryParams);

            this.sectionsService.moveSection(payload).subscribe();
        } else {
            this.flashMessageService.showTranslatedError('sectionTree.lockedSectionNoDrag');
        }
    }

    activate(sectionId: string) {
        this.saveService.saveAll(true).subscribe(() => {
            const publication = this.publicationsQuery.getActive() as Publication;
            this.router
                .navigateByUrl(
                    '/publication-groups/' +
                        publication.publicationGroup.id +
                        '/publication/' +
                        this.publicationsQuery.getActiveId() +
                        '/editor/section/' +
                        sectionId
                )
                .then((value) => {
                    this.loadAssets(sectionId);
                });
        });
    }

    loadAssets(sectionId) {
        if (sectionId) {
            const payload = new RequestDto({ sectionId });
            const observables = [];
            observables.push(this.imagesService.loadImagesForSection(payload));
            observables.push(this.chartsService.getChartsForSection(payload));
            observables.push(this.excelService.getExcelForSection(payload));
            observables.push(this.mapService.getMapsForSection(payload));
            forkJoin(observables).subscribe(
                () => {},
                () => {},
                () => {
                    this.isRequested = true;
                }
            );
        }
    }

    setActive(sectionId: ID) {
        this.shouldSelect.next(sectionId);
    }

    removeFocus(sectionId: string) {
        this.shouldRemoveFocus.next(sectionId);
    }

    public onCreateSectionChild(treeComponent, section) {
        const modalRef = this.dialog.open(NewSectionComponent, {
            width: '788px',
            height: '637px',
            autoFocus: false,
        });
        if (!modalRef) {
            return;
        }
        modalRef.componentInstance.type = 'child';
        modalRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    const parentId: string = section.id;

                    const body = {
                        title: result.sectionName,
                        subTitle: result.sectionSubtitle,
                        navigationTitle: result.navigationTitle,
                    };

                    this.sectionsService.addSection(parentId, body).subscribe((payload) => {
                        this.setActive(payload.newSection.id);
                    });
                }
            },
            () => {}
        );
    }

    onDelete(treeComponent, section): void {
        const modalRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'section',
            },
        });
        if (!modalRef) {
            return;
        }
        modalRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    const params = {
                        sectionId: section.id,
                    };

                    const activeId = this.sectionsService.getActiveSectionById();
                    const node = treeComponent.treeModel.getNodeById(activeId);

                    treeComponent.treeModel.focusPreviousNode();

                    const prevId = treeComponent.treeModel.getFocusedNode().id;
                    const payload: SectionDto = new SectionDto(params);

                    this.sectionsService.deleteSection(payload).subscribe(() => {
                        if (activeId === section.id) {
                            const publication = this.publicationsQuery.getActive() as Publication;
                            this.router.navigateByUrl(
                                '/publication-groups/' +
                                    publication.publicationGroup.id +
                                    '/publication/' +
                                    this.publicationsQuery.getActiveId() +
                                    '/editor/section/' +
                                    prevId
                            );
                        } else {
                            treeComponent.treeModel.setFocusedNode(node);
                        }
                    });
                }
            },
            () => {}
        );
    }

    onCopy(treeComponent, section): void {
        const parentId: string = section.parentId;
        const previousId: string = section.id;

        const data = {
            sectionName: section.title,
            subTitle: section.subTitle,
            navigationTitle: section.navigationTitle,
        };
        const payload = this.getSectionDto(data, parentId, previousId);
        this.sectionsService.copySection(payload, section.id).subscribe((payload) => {
            this.setActive(payload.newSection.id);
        });
    }

    private getSectionDto(text, parentId, previousId): SectionDto {
        const params = {
            parentSectionId: parentId,
        };
        const queryParams = {
            previousSectionId: previousId,
        };
        const body = {
            title: text.sectionName,
            subTitle: text.sectionSubtitle,
            navigationTitle: text.navigationTitle,
        };

        return new SectionDto(params, queryParams, body);
    }
}
