<form novalidate #keyFiguresForm="ngForm" class="form-document">
    <div class="row node-type-key-figure">
        <div class="keyfigure-{{ keyFigureLayout }} layout-style">
            <div
                *ngFor="let keyFigureCol of keyFigures; let i = index"
                class="col-{{ getColClass() }}"
                [attr.data-col]="i"
                [dragula]="'keyfigure-draggable'">
                <ng-container *ngFor="let keyFigureItem of keyFigureCol; let k = index">
                    <div class="keyfigure" [attr.data-index]="k">
                        <div class="pe-element-inner keyfigure-inner" (click)="openEditor(keyFigureItem, i, k)">
                            <i class="ei ei-settings setting-icon"></i>
                            <div class="title" [innerHTML]="keyFigureItem.title"></div>
                            <div>
                                <span class="number" [innerHTML]="keyFigureItem.number"></span>
                                <span class="unit" [innerHTML]="keyFigureItem.unit"></span>
                            </div>
                            <div class="description" [innerHTML]="keyFigureItem.description"></div>
                            <button mat-icon-button class="element-button-drag">
                                <i class="ei ei-drag element-button-drag-icon"></i>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="pe-element-button-container">
                    <button mat-stroked-button color="accent" (click)="addElement(i)">
                        <i class="ei ei-plus-full"></i>{{ 'component.keyfigures.add' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
