<div class="comment comment-{{ status }}">
    <div class="comment-container">
        <div class="avatar">
            <elias-shared-user-avatar
                [hideToolTip]="true"
                [user]="comment.createdBy"
                *ngIf="
                    comment.createdBy !== SpecialUsersEnum.EliasSupport;
                    else eliasSupport
                "></elias-shared-user-avatar>
            <ng-template #eliasSupport>
                <elias-shared-user-avatar [hideToolTip]="true"></elias-shared-user-avatar>
            </ng-template>
        </div>

        <div class="comment-box comment-box-{{ status }}" (click)="onCommentClicked()">
            <div class="comment-header">
                <div class="comment-title">
                    <div class="comment-creator">{{ creatorName }}</div>
                    <div
                        class="comment-date"
                        matTooltip="{{ comment.createdAt | localizedDate : 'eliasMediumDate' }}"
                        [matTooltipPosition]="'above'">
                        ({{ comment.createdAt | timeAgo }})
                    </div>
                </div>

                <ng-content select="[actions]"></ng-content>
            </div>

            <elias-collapsible-content [contentHeight]="60">
                <div class="content" [innerHTML]="comment.content | commentContent"></div>
            </elias-collapsible-content>
        </div>
    </div>
</div>
