import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PublicationGroupsStore, PublicationGroupState } from './publication-group.store';
import { PublicationGroup } from './publication-group.model';
import { UsersQuery } from '../../../modules/shared/state/users/users.query';
import { User } from '../../../modules/shared/state/users/user.model';

@Injectable({
    providedIn: 'root',
})
export class PublicationGroupQuery extends QueryEntity<PublicationGroupState, PublicationGroup> {
    selectFilters$ = this.select((state) => state.ui.filters);
    selectSort$ = this.select((state) => state.ui.sort);
    selectGenCategory$ = this.select((state) => state.ui.filters.genCategory);
    selectPubTypeCategory$ = this.select((state) => state.ui.filters.pubTypeCategory);
    selectSearch$ = this.select((state) => state.ui.search);

    constructor(protected override store: PublicationGroupsStore, private usersQuery: UsersQuery) {
        super(store);
    }

    getEditorsAndChiefEditorsForActive(): User[] {
        const publicationGroup = this.getActive() as PublicationGroup;

        if (!publicationGroup) {
            return [];
        }

        const editorIds = Object.keys(publicationGroup.editors);
        const chiefEditorIds = Object.keys(publicationGroup.chiefEditors);

        const ids = [...editorIds, ...chiefEditorIds];

        return this.usersQuery.getAll().filter((user) => {
            return ids.includes(user.id as string);
        });
    }

    getEditorsForActive(): User[] {
        const publicationGroup = this.getActive() as PublicationGroup;

        if (!publicationGroup) {
            return [];
        }

        const editorIds = Object.keys(publicationGroup.editors);

        return this.usersQuery.getAll().filter((user) => {
            return editorIds.includes(user.id as string);
        });
    }

    getChiefEditorsForActive(): User[] {
        const publicationGroup = this.getActive() as PublicationGroup;

        if (!publicationGroup) {
            return [];
        }

        const chiefEditorIds = Object.keys(publicationGroup.chiefEditors);

        return this.usersQuery.getAll().filter((user) => {
            return chiefEditorIds.includes(user.id as string);
        });
    }
}
