<mat-toolbar>
    <div class="toolbar-container">
        <a
            routerLink="/publication-groups"
            routerLinkActive="active"
            class="logo"
            (mouseover)="startAnimation()"
            (mouseleave)="stopAnimation()">
            <ng-lottie [options]="options" width="150px" (animationCreated)="animationCreated($event)"></ng-lottie>
        </a>
        <elias-core-breadcrumb></elias-core-breadcrumb>
    </div>

    <div class="toolbar-container">
        <ng-container *ngIf="toolbarState === 'editor'">
            <div class="nav-extras">
                <a
                    routerLink="{{ targetUrl + '/section/' + (activeSection$ | async).id }}"
                    *ngIf="this.activeSection$ | async"
                    routerLinkActive="active"
                    matTooltip="{{ 'core.toolbar.editor' | translate }}"
                    matTooltipShowDelay="500">
                    <i class="ei ei-create"></i>
                </a>
                <a
                    routerLink="{{ targetUrl + '/section' }}"
                    *ngIf="(this.activeSection$ | async) === undefined"
                    matTooltip="{{ 'core.toolbar.editor' | translate }}"
                    matTooltipShowDelay="500">
                    <i class="ei ei-create"></i>
                </a>
                <a
                    routerLink="{{ targetUrl + '/files' }}"
                    matTooltip="{{ 'core.toolbar.assetBrowser' | translate }}"
                    routerLinkActive="active"
                    matTooltipShowDelay="500">
                    <i class="ei ei-folder"></i>
                </a>
                <a
                    routerLink="{{ targetUrl + '/history' }}"
                    matTooltip="{{ 'core.toolbar.history' | translate }}"
                    routerLinkActive="active"
                    matTooltipShowDelay="500">
                    <i class="ei ei-assignment"></i>
                </a>
                <a
                    href="javascript:void(0);"
                    (click)="closeEditor()"
                    matTooltip="{{ 'core.toolbar.exit' | translate }}">
                    <i class="ei ei-close"></i>
                </a>
            </div>
        </ng-container>
        <div class="avatar" (click)="openMainNav()">
            <div
                [matBadgeHidden]="numberOfUnreadNotifications === 0"
                matBadge="{{ numberOfUnreadNotifications }}"
                matBadgeColor="warn">
                <elias-shared-user-avatar
                    [hideToolTip]="true"
                    [user]="userDetail"
                    [size]="'small'"></elias-shared-user-avatar>
            </div>
        </div>
    </div>
</mat-toolbar>
