import { Component, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { Color } from '../../../../shared/enums/color.enum';

@Component({
    selector: 'elias-form-element-color',
    templateUrl: './form-color.component.html',
    styleUrls: ['./form-color.component.scss'],
})
export class FormColorComponent extends BasicFormElementComponent implements OnInit {
    @Input()
    options: any;

    @Input()
    settings;

    selectedValue;
    Color = Color;
    selectedColor = '';
    renderType = 'normal';
    type = 'normal';
    matPrefix = '';

    ngOnInit() {
        super.ngOnInit();
        if (!this.selectedValue) {
            this.selectedValue = this.formGroup.get(this.name).value;
        }

        if (this.options && this.options[0] && this.options[0].colors && this.options[0].colors.length > 0) {
            this.type = 'theme';
        }

        if (Object.keys(this.settings).length > 0) {
            if (this.settings['renderType']) this.renderType = this.settings['renderType'];
            if (this.settings.hasOwnProperty('matPrefix')) this.matPrefix = this.settings['matPrefix'] + ' ';
        }
    }

    getCorrectFormat(value) {
        if (value && value.includes('#')) {
            return value;
        } else {
            return '#' + value;
        }
    }

    trackByIndex(index, item) {
        return index;
    }
}
