import { Injectable } from '@angular/core';
import { NotificationsStore } from './notifications.store';
import { NotificationsQuery } from './notifications.query';
import { Notification } from '../models/notification.model';
import { HttpClient } from '@angular/common/http';
import { DomainService } from '../../modules/core/services/domain.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { withTransaction } from '@datorama/akita';
import { AdditionalNotificationData, PaginatedNotifications } from '../models/paginated-notifications';
import { QueryParametersBuilder } from '../../modules/core/builders/query-parameters.builder';
import { FilterOperators } from '../../modules/core/enums/filter-operators.enum';
import { QueryParameters } from '../../modules/core/models/query-parameters.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private notificationsQuery: NotificationsQuery,
        private notificationsStore: NotificationsStore
    ) {}

    getNotifications(options: any = {}, resetStore: boolean = true): Observable<PaginatedNotifications> {
        this.notificationsStore.setLoadingNextPage(true);
        const url = `${this.domainService.apiBaseUrl}/notifications`;
        return this.http.get<PaginatedNotifications>(url, { params: options }).pipe(
            withTransaction((notifications) => {
                this.notificationsStore.update({
                    currentPage: notifications.currentPage,
                    totalNumberOfPages: notifications.totalNumberOfPages,
                    totalDataLength: notifications.totalDataLength,
                    additionalData: notifications.additionalData,
                    hasNextPage: notifications.hasNextPage,
                });
                if (resetStore) {
                    this.notificationsStore.set(notifications.data);
                } else {
                    this.notificationsStore.add(notifications.data);
                }
                this.notificationsStore.setLoadingNextPage(false);
                this.notificationsStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getNumberOfUnreadNotifications(): Observable<AdditionalNotificationData> {
        const url = `${this.domainService.apiBaseUrl}/notifications`;
        return this.http.get<AdditionalNotificationData>(url, { params: { count: 1 } }).pipe(
            tap(({ numberOfUnreadNotifications }) => {
                this.notificationsStore.update((state) => ({
                    additionalData: {
                        ...state.additionalData,
                        numberOfUnreadNotifications,
                    },
                }));
            }),
            catchError((error: any) => throwError(error))
        );
    }

    markSingleNotificationAsRead(
        notification: Notification,
        additionalData: AdditionalNotificationData
    ): Observable<Notification> {
        this.notificationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/notifications/${notification.id}`;
        return this.http.put<Notification>(url, notification.id).pipe(
            tap((notification) => {
                this.notificationsStore.update(notification.id, notification);
                this.notificationsStore.update({ additionalData });

                this.notificationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    markSingleNotificationAsUnread(
        notification: Notification,
        additionalData: AdditionalNotificationData
    ): Observable<Notification> {
        this.notificationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/notifications/${notification.id}`;
        return this.http.delete<Notification>(url).pipe(
            tap((notification) => {
                this.notificationsStore.update(notification.id, notification);
                this.notificationsStore.update({ additionalData });
                this.notificationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    markAllNotificationsAsRead(additionalData: AdditionalNotificationData): Observable<Notification> {
        this.notificationsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/notifications`;

        return this.http.put<Notification>(url, this.notificationsQuery.getAll()).pipe(
            tap(() => {
                this.notificationsStore.update(null, { readAt: new Date() });
                this.notificationsStore.update({ additionalData });
                this.notificationsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateFilter(filterType: string, value: any) {
        const filters: { [k: string]: any } = {};
        filters[filterType] = value;

        this.notificationsStore.update((notificationState) => ({
            ui: {
                ...notificationState.ui,
                filters: { ...notificationState.ui.filters, ...filters },
            },
        }));
    }

    nextPage(): void {
        const nextPage = this.notificationsQuery.getCurrentPage() + 1;

        this.notificationsStore.update((notificationsState) => ({
            ui: { ...notificationsState.ui, page: nextPage },
        }));
    }

    resetPage(): void {
        this.notificationsStore.update((notificationsState) => ({
            ui: { ...notificationsState.ui, page: null },
        }));
    }

    getCurrentRequestOptions(): QueryParameters {
        const queryParametersBuilder = new QueryParametersBuilder();

        this.addFiltersToQueryBuilder(queryParametersBuilder);
        queryParametersBuilder.addPage(this.notificationsQuery.getPage());

        return queryParametersBuilder.getQueryOptions();
    }

    private addFiltersToQueryBuilder(builder: QueryParametersBuilder): void {
        const filters = this.notificationsQuery.getFilters();
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'readAt') {
                this.addReadAtFilter(value, builder);
            } else {
                builder.addFilter(key, value);
            }
        }
    }

    private addReadAtFilter(value: string | null, builder: QueryParametersBuilder): void {
        if (value === 'read') {
            builder.addFilter('readAt', '', FilterOperators.NotEqual);
        } else if (value === 'unread') {
            builder.addFilter('readAt', '');
        }
    }
}
