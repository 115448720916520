import { Injectable } from '@angular/core';
import { Extension } from '@tiptap/core';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TranslateService } from '@ngx-translate/core';
import { CommentEditorAction } from '../../types/comment-editor-action.type';

@Injectable({
    providedIn: 'root',
})
export class TiptapPlaceholderService {
    constructor(private translateService: TranslateService) {}

    /*  We need a callback here for getting an action because it can change over time,
        and since primitives are passed by value - it wouldn't be reevaluated. */
    public getConfiguration(getEditorActionCallback: () => CommentEditorAction): Extension {
        return Placeholder.configure({
            placeholder: () => {
                const action = getEditorActionCallback();

                if (action === 'create') {
                    return this.translateService.instant('comments.placeholder.comment');
                }

                return this.translateService.instant('comments.placeholder.reply');
            },
        });
    }
}
