import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Notification } from '../models/notification.model';

export interface NotificationsState extends EntityState<Notification> {
    loaded: boolean;
    loadingNextPage: boolean;
    totalNumberOfPages: number;
    currentPage: number;
    hasNextPage: boolean;
    totalDataLength: number;
    additionalData: {
        numberOfUnreadNotifications: number;
    };
    ui: {
        filters: {
            readAt: string | null;
        };
        page: number | null;
    };
}

export function createInitialState(): NotificationsState {
    return {
        totalNumberOfPages: 0,
        totalDataLength: 0,
        currentPage: 1,
        hasNextPage: true,
        loaded: false,
        loadingNextPage: false,
        additionalData: {
            numberOfUnreadNotifications: 0,
        },
        ui: {
            filters: {
                readAt: null,
            },
            page: null,
        },
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Notifications' })
export class NotificationsStore extends EntityStore<NotificationsState, Notification> {
    constructor() {
        super(createInitialState());
    }

    public setLoadingNextPage(loadingNextPage: boolean): void {
        this.update({ loadingNextPage, loading: loadingNextPage });
    }
}

export const notificationsStore = new NotificationsStore();
