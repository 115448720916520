import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Reply } from '../../models/comment.model';
import { CommentStatus } from '../../enums/comment-status.enum';

@Component({
    selector: 'elias-reply',
    templateUrl: './reply.component.html',
})
export class ReplyComponent {
    public CommentStatusEnum = CommentStatus;

    @Input() reply?: Reply;
    @Input() status: CommentStatus = CommentStatus.Editable;
    @Input() showControls: boolean = true;
    @Input() isResolved: boolean = false;

    @Output() replyLinkClicked = new EventEmitter<void>();

    get hasDefaultAction(): boolean {
        if (!this.showControls || !this.reply) {
            return false;
        }

        return this.status === CommentStatus.ReadOnly;
    }

    public onReplyClicked(): void {
        if (!this.hasDefaultAction) {
            return;
        }

        if (this.status === CommentStatus.ReadOnly) {
            this.onReplyLinkClicked();
        }
    }

    public onReplyLinkClicked(): void {
        this.replyLinkClicked.emit();
    }
}
