import { Category } from './category.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface CategoryState extends EntityState<Category> {
    loaded: boolean;
    ui: {
        viewOption: number;
    };
}

const InitialState = {
    loaded: false,
    ui: {
        viewOption: 2,
    },
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Categories' })
export class CategoriesStore extends EntityStore<CategoryState, Category> {
    constructor() {
        super(InitialState);
    }
}
