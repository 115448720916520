import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../../../auth/service/auth.service';
import { LoggedInUserQuery } from '../../../shared/state/logged-in-user/logged-in-user.query';
import { User } from '../../../shared/state/users/user.model';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { MainSideNavService } from '../../services/main-side-nav.service';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';
import { SystemInformationQuery } from '../../../shared/state/system-information/system-information.query';
import { first } from 'rxjs/operators';
import { NotificationsQuery } from '../../../../notifications/state/notifications.query';

@Component({
    selector: 'elias-core-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
    @ViewChild('mainnav') public sidenav!: MatSidenav;

    public isAdmin = false;
    public version!: string;
    public user!: User;
    public numberOfUnreadNotifications!: number;

    constructor(
        private authService: AuthService,
        private loggedInUserQuery: LoggedInUserQuery,
        private mainSideNavService: MainSideNavService,
        private router: Router,
        private systemInformationQuery: SystemInformationQuery,
        private userUtilService: UsersUtilsService,
        private notificationsQuery: NotificationsQuery
    ) {}

    ngOnInit(): void {
        this.user = this.loggedInUserQuery.getValue();
        if (this.user && (this.userUtilService.isAdmin(this.user) || this.userUtilService.isSuperAdmin(this.user))) {
            this.isAdmin = true;
        }

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError:
                    {
                        if (this.sidenav !== undefined && this.sidenav.opened) {
                            this.mainSideNavService.toggle();
                        }
                    }
                    break;
            }
        });

        this.systemInformationQuery.version$.pipe(first()).subscribe((version) => {
            this.version = version;
        });

        this.notificationsQuery.select('additionalData').subscribe((additionalData) => {
            this.numberOfUnreadNotifications = additionalData.numberOfUnreadNotifications;
        });
    }

    logout(): void {
        this.authService.logout();
    }
}
