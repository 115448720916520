import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, pluck, tap } from 'rxjs/operators';
import { ExcelStore } from '../../editor/state/excel/excel.store';
import { ExcelUpdate } from '../models/excel-update.model';
import { UpdateService } from '../../../modules/core/services/update.service';
import { Excel } from '../../editor/state/excel/excel.model';
import { UpdateKey } from '../../../modules/core/enums/update-key.enum';
import { AssetsStore } from '../../../modules/shared/state/assets/assets.store';

@Injectable({
    providedIn: 'root',
})
export class ExcelUpdateListener {
    public excelUpdate$: Observable<Excel[]>;

    constructor(
        private assetsStore: AssetsStore,
        private excelStore: ExcelStore,
        private updateService: UpdateService
    ) {
        this.excelUpdate$ = this.updateService.getUpdates<ExcelUpdate>(UpdateKey.Excel).pipe(
            pluck('entities'),
            filter((excelAssets) => excelAssets.length > 0),
            tap((excelAssets) => {
                excelAssets.forEach((excel) => {
                    this.assetsStore.update(excel.id, excel);
                    this.excelStore.update(excel.id, excel);
                });
            })
        );
    }
}
