import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Excel } from './excel.model';
import { Injectable } from '@angular/core';

export interface ExcelState extends EntityState<Excel> {
    loaded: boolean;
}

const initialState = {
    loaded: false,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Excel', resettable: true })
export class ExcelStore extends EntityStore<ExcelState, Excel> {
    constructor() {
        super(initialState);
    }
}

export const excelStore = new ExcelStore();
