import {
    AfterViewInit,
    Component,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Node } from '../../state/nodes/node.model';
import { EditableNodeComponent } from '../../components/node-editable/node-editable.component';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { NodeDto } from '../../models/node-dto.model';
import { Section } from '../../state/sections/section.model';
import { Chart } from '../../state/charts/chart.model';
import { NodeViewModel } from '../../viewmodels/node.viewmodel';
import { DocumentService } from '../../services/document.service';
import { SelectedNode } from '../../models/selected-element.model';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImagesService, NodesService, PropertyEditorService, SectionsService } from '../../services';
import { SharedEventsService } from '../../services/shared-events.service';
import { TreeService } from '../../services/tree.service';
import { DragulaService } from 'ng2-dragula';
import { SectionsQuery } from '../../state/sections/sections.query';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { PropertyEditorQuery } from '../../state/property-editor/property-editor.query';
import { ChartsQuery } from '../../state/charts/charts.query';
import { SaveService } from '../../services/save.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { delay, first, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable } from 'rxjs';
import { RequestDto } from '../../models/request-dto.model';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { Location } from '@angular/common';
import { UiService } from '../../../../modules/core/state/ui/ui.service';
import { NodePresetsQuery } from '../../../../modules/shared/state/node-presets/node-presets.query';
import { NodesStore } from '../../state/nodes/nodes.store';
import { DataService } from '../../services/data.service';
import { SectionHeadlineComponent } from '../../components/section-headline/section-headline.component';
import { UrlParamsHandlerInterface } from '../../../../modules/links/interfaces/url-params-handler.interface';
import { UrlParamsService } from '../../../../modules/links/services/url-params.service';

@Component({
    selector: 'elias-editor-document',
    styleUrls: ['./document.component.scss'],
    templateUrl: './document.component.html',
})
export class DocumentComponent implements OnInit, OnDestroy, AfterViewInit, UrlParamsHandlerInterface {
    @ViewChildren(EditableNodeComponent) editableNodes?: QueryList<EditableNodeComponent>;
    @ViewChild(SectionHeadlineComponent) sectionHeadline?: SectionHeadlineComponent;
    @HostBinding('lang') langAttribute?: string;

    nodes$: Observable<Node[]>;
    section$: Observable<Section>;
    charts$: Observable<Chart[]>;
    section: Section;
    backgroundImageId: string;
    backgroundImagePath: SafeUrl | string;

    selectedNode: Node | null;
    nextSelectedNode: SelectedNode | null;
    loaded$ = new BehaviorSubject<boolean>(true);
    nodePresetType;
    position;
    globalSource;
    inEditable = false;
    showSpinner = false;
    active: number;
    publication: Publication;

    private sectionId: string;
    private waitForSaving = false;

    constructor(
        private chartsQuery: ChartsQuery,
        private data: DataService,
        private documentService: DocumentService,
        private dragulaService: DragulaService,
        private imageService: ImagesService,
        private nodePresetQuery: NodePresetsQuery,
        private nodeViewModel: NodeViewModel,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private nodesStore: NodesStore,
        private propertyEditorQuery: PropertyEditorQuery,
        private propertyEditorService: PropertyEditorService,
        private publicationsQuery: PublicationsQuery,
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private sharedEvents: SharedEventsService,
        private translateService: TranslateService,
        private treeService: TreeService,
        private uiService: UiService,
        private urlParamsService: UrlParamsService,
        public dialog: MatDialog,
        public location: Location
    ) {
        this.showSpinner = false;
        this.publication = this.publicationsQuery.getActive() as Publication;
    }

    ngOnInit() {
        this.langAttribute = this.publication.locale;

        this.nodes$ = this.nodesQuery.selectAll();
        this.section$ = this.sectionsQuery.selectActive() as Observable<Section>;
        this.section$.subscribe((section) => {
            if (section) {
                this.section = section;
            }
        });

        this.nodesQuery
            .selectAll({
                filterBy: (entity) => entity.editing === true,
            })
            .pipe(map((res) => res))
            .subscribe((data) => {
                this.active = data.length;
            });

        this.nodesQuery.select('selectedNode').subscribe((selectedNode: Node) => {
            this.selectedNode = selectedNode;
        });

        // TODO: Move to EditorComponent if possible
        this.route.params.pipe(first()).subscribe((params) => {
            if (params.sectionId) {
                if (!params.type || !params.elementId) {
                    this.treeService.setActive(params.sectionId);
                }
            }
        });

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loaded$.next(false);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loaded$.next(true);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        this.charts$ = this.chartsQuery.selectAll();
        this.nodesQuery.select('loadedNodesForSectionId').subscribe((sectionId) => {
            if (sectionId) {
                this.sectionId = sectionId;
            }
        });
        const bag: any = this.dragulaService.find('bag-draggable');
        if (bag === undefined) {
            this.dragulaService.createGroup('bag-draggable', {
                accepts: (el, target, source, sibling) => {
                    if (this.nodesQuery.hasEntity('temp-100')) {
                        this.nodesStore.remove('temp-100');
                    }
                    return target.id === 'droppable' || (source.id === 'droppable' && target.id === 'droppable');
                },

                copy: (item) => {
                    return item.id === 'draggable';
                },

                moves: (el, source, handle, siblings) => {
                    return (
                        handle.classList.contains('node-button-drag') ||
                        handle.classList.contains('pe-preset') ||
                        handle.classList.contains('label') ||
                        handle.classList.contains('ei-150')
                    );
                },
            });

            this.dragulaService.drag('bag-draggable').subscribe(({ name, el, source }) => {
                document.onmousemove = (e) => {
                    this.globalSource = source;

                    const event = e || window.event;
                    const mouseY = event['pageY'];
                    const scrollTop =
                        window.scrollY ||
                        window.pageYOffset ||
                        document.body.scrollTop +
                            ((document.documentElement && document.documentElement.scrollTop) || 0);
                    const scrollBottom = scrollTop + window.innerHeight;
                    const elementHeight = (el as HTMLElement).getBoundingClientRect().height;

                    if (mouseY < scrollTop + 93) {
                        document.querySelector('.scroll-container').scrollBy(0, -15);
                    } else if (mouseY + elementHeight > scrollBottom) {
                        document.querySelector('.scroll-container').scrollBy(0, 15);
                    }
                };
            });

            this.dragulaService.dragend('bag-draggable').subscribe((value) => {
                document.onmousemove = null;
            });

            this.dragulaService.drop('bag-draggable').subscribe(({ name, el, target, source, sibling }) => {
                if (el != null) {
                    if (target != null) {
                        if ((el as HTMLElement).dataset.items && target.id === 'droppable') {
                            this.onDrop(el, target);
                        } else {
                            this.onNodeMove(el, target);
                        }
                    }
                }
            });

            this.dragulaService.cloned('bag-draggable').subscribe(({ name, clone, original, cloneType }) => {
                // console.log(name, clone, original, cloneType);
                if (cloneType === 'copy') {
                    // TODO: replace icon
                    clone.innerHTML =
                        '<p class="pe-node-placeholder"><i class="' +
                        (original.childNodes[0] as Element).classList.value +
                        '"></p>';
                }
            });
        }

        this.documentService.setLoading(false);
    }

    ngAfterViewInit() {
        this.loaded$.pipe(switchMap(() => this.handleUrlParams())).subscribe();
    }

    loadData(sectionId) {
        const observables = [];
        if (!this.propertyEditorQuery.getValue().loaded) {
            const rootSectionId = (this.publicationsQuery.getActive() as Publication).rootSectionId;
            const requestDto = new RequestDto({ rootSectionId });
            observables.push(this.propertyEditorService.loadPropertyEditorConfigurations(requestDto));
        }
        if (this.nodesQuery.getValue().loadedNodesForSectionId !== sectionId) {
            const payload: NodeDto = new NodeDto({ sectionId });
            observables.push(this.nodesService.getNodesForSection(payload));
        }

        if (observables.length > 0) {
            this.loaded$.next(false);
            forkJoin(observables)
                .pipe(first())
                .subscribe((results) => {
                    this.loaded$.next(true);
                });
        } else {
            this.loaded$.next(true);
        }
    }

    deselectNode(node: Node): Observable<Node> {
        return this.nodesService.deselectNode(node);
    }

    handleUrlParams(): Observable<any> {
        const scrollToNode$ = this.urlParamsService.getParamFromURL('node').pipe(
            first(),
            tap(({ value: nodeId }) => {
                this.scrollToNode(nodeId);
            })
        );

        const $openCommentsForNode = combineLatest([
            this.urlParamsService.getParamFromURL('node'),
            this.urlParamsService.getParamFromURL('comment'),
        ]).pipe(
            first(),
            delay(100),
            map(([{ value: nodeId }, { value: commentId }]) => ({ nodeId, commentId })),
            tap(({ nodeId, commentId }) => {
                this.openCommentsForNode(nodeId, commentId);
            })
        );

        const $openCommentsForSection = combineLatest([
            this.urlParamsService.urlParamNotFound('node'),
            this.urlParamsService.getParamFromURL('comment'),
        ]).pipe(
            first(),
            delay(100),
            map(([empty, { value: commentId }]) => commentId),
            tap((commentId) => {
                this.openCommentsForSection(commentId);
            })
        );

        return merge(scrollToNode$, $openCommentsForNode, $openCommentsForSection);
    }

    private scrollToNode(id: string): void {
        const nodeEditableComponent = this.getEditableNodeComponentById(id);
        if (!nodeEditableComponent) {
            return;
        }

        const nativeElement = nodeEditableComponent.elementRef.nativeElement;
        nativeElement.scrollIntoView();
    }

    private openCommentsForNode(id: string, commentId: string): void {
        const nodeEditableComponent = this.getEditableNodeComponentById(id);
        if (!nodeEditableComponent) {
            return;
        }

        nodeEditableComponent.openComments(new MouseEvent('click'), commentId);
    }

    private openCommentsForSection(commentId: string): void {
        this.sectionHeadline?.openComments(commentId);
    }

    private getEditableNodeComponentById(id: string): EditableNodeComponent | undefined {
        return this.editableNodes?.find((component) => component.node.id === id);
    }

    @HostListener('document:mousedown', ['$event'])
    public onMouseDown(event: Event): void {
        if (this.isTargetInNodeEditable(event.target)) {
            this.inEditable = true;
            return;
        } else {
            this.inEditable = false;
        }
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        if (this.targetHasIgnoreClickClass(event.target)) {
            return;
        }
        if (this.targetInMenuButton(event.target)) {
            return;
        }
        if (this.isTargetAMceButton(event.target)) {
            return;
        }
        if (this.isTargetInMce(event.target)) {
            return;
        }
        if (this.isTargetInToxWindow(event.target)) {
            return;
        }
        if (this.isTargetInHandsontable(event.target)) {
            return;
        }
        if (this.isTargetInPropertyEditor(event.target)) {
            return;
        }
        if (this.isTargetOnSidebarToggleButton(event.target)) {
            return;
        }
        if (this.isTargetInToggleViewModeButton(event.target)) {
            return;
        }
        if (this.isTargetInUnloadButton(event.target)) {
            return;
        }
        if (this.isTargetInDeleteButton(event.target)) {
            return;
        }
        if (this.isTargetInModal(event.target)) {
            return;
        }
        if (this.isTargetInAssetManager(event.target)) {
            return;
        }
        if (this.isTargetInPropertyEditorSelect(event.target)) {
            return;
        }
        if (this.isTargetInBackdrop(event.target)) {
            return;
        }
        if (this.isTargetInExtrasNav(event.target)) {
            return;
        }
        if (this.isTargetInTextArea(event.target)) {
            return;
        }
        if (this.isTargetInMatDrawerBackDrop(event.target)) {
            return;
        }
        if (this.isTargetInPropertyEditorMatOptionText(event.target)) {
            return;
        }
        if (this.isTargetInMatDrawerContainer(event.target)) {
            return;
        }
        if (this.isTargetInSectionTree(event.target)) {
            return;
        }
        if (this.inEditable) {
            this.inEditable = false;
            return;
        }

        const container = (document.getElementsByClassName('cdk-overlay-container') as unknown as HTMLElement)[0];
        if (container && container.querySelector('.mat-dialog-container')) {
            return;
        }

        // save and deselect
        this.saveService.saveAll(true).subscribe();
    }

    targetInMenuButton(target: EventTarget) {
        if (target instanceof Element) {
            return (
                !!this.checkIfAnyParentHasClass(target, 'ei-more-vert') ||
                !!this.checkIfAnyParentHasClass(target, 'mat-menu-item') ||
                !!this.checkIfAnyParentHasClass(target, 'ei-trash')
            );
        }
        return false;
    }

    isTargetInSectionTree(target: EventTarget) {
        if (target instanceof Element) {
            return (
                !!this.checkIfAnyParentHasClass(target, 'node-value') ||
                !!this.checkIfAnyParentHasClass(target, 'node-content-wrapper')
            );
        }
        return false;
    }

    isTargetInNodeEditable(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'content-wrapper-for-hover');
        }
        return false;
    }

    isTargetInMatDrawerContainer(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'mat-drawer-inner-container');
        }
        return false;
    }

    isTargetInPropertyEditorMatOptionText(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'mat-option-text');
        }
        return false;
    }

    isTargetInMatDrawerBackDrop(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'mat-drawer-backdrop');
        }
        return false;
    }

    isTargetAMceButton(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'mce-widget');
        }
        return false;
    }

    isTargetInMce(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'mce-container');
        }
        return false;
    }

    isTargetInToxWindow(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'tox-dialog');
        }
        return false;
    }

    isTargetInHandsontable(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'handsontable-container');
        }
        return false;
    }

    isTargetInPropertyEditor(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'property-editor-container');
        }
        return false;
    }

    isTargetOnSidebarToggleButton(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'btn-toggle-sidebar');
        }
        return false;
    }

    isTargetInModal(target: EventTarget) {
        if (target instanceof Element) {
            return (
                !!this.checkIfAnyParentHasClass(target, 'mat-dialog-container') ||
                !!this.checkIfAnyParentHasClass(target, 'mat-button-wrapper')
            );
        }
        return false;
    }

    isTargetInAssetManager(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'asset-browser');
        }
        return false;
    }

    isTargetInBackdrop(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'cdk-overlay-backdrop');
        }
        return false;
    }

    isTargetInDeleteButton(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'delete');
        }
        return false;
    }

    targetHasIgnoreClickClass(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'ignore-click');
        }
        return false;
    }

    isTargetInToggleViewModeButton(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'btn-toggle-view-mode');
        }
        return false;
    }

    isTargetInUnloadButton(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'nav-link-unload');
        }
        return false;
    }

    isTargetInPropertyEditorSelect(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'ng-dropdown-panel');
        }
        return false;
    }

    isTargetInExtrasNav(target: EventTarget) {
        if (target instanceof Element) {
            return !!this.checkIfAnyParentHasClass(target, 'nav-extras');
        }
        return false;
    }

    isTargetInTextArea(target: EventTarget) {
        const container = (document.getElementsByClassName('text-area') as unknown as HTMLElement)[0];
        if (container) {
            return;
        }
        return false;
    }

    checkIfAnyParentHasClass(element: Element, cssClass: string, stopAtTag = 'html') {
        if (element && element.classList.contains(cssClass)) {
            return element.tagName;
        }
        if (!element || element.tagName === stopAtTag) {
            return;
        }
        return this.checkIfAnyParentHasClass(element.parentElement, cssClass, stopAtTag);
    }

    private onDrop(el, target) {
        let data;

        if (el.dataset.items) {
            data = JSON.parse(el.dataset.items);

            this.nodePresetType = data;

            if (data.length == 1) {
                this.createNode(el, target, data);
            } else {
                this.addTemporaryNode(el, target);
            }
        }
    }

    private addTemporaryNode(el, target) {
        const position = this.getElementIndex(el);
        this.position = position;
        const newData = {
            data: this.nodePresetType,
            pos: position,
            src: this.globalSource,
        };
        this.data.setData(newData);
        const node = new Node();

        node.sectionId = this.sectionId;
        node.type = 'undefined';
        node.position = position - 0.1;
        node.id = 'temp-100';

        this.saveService.saveNode(true).subscribe();
        this.nodesStore.add(node);

        el.remove();
    }

    selectedOption($event) {
        let newData;
        this.data.data$.subscribe((x) => (newData = x));
        const position = newData.pos;
        const source = newData.src;

        const params = {
            sectionId: this.sectionId,
            type: $event.nodeType,
        };

        const queryParams = { position };

        const content = $event.content || '';

        const body = { content };

        const payload = new NodeDto(params, queryParams, body);
        // this.saveService.saveAll(true).subscribe(() => {
        this.nodesService.createNode(payload).subscribe(() => {
            this.nodesStore.remove('temp-100');
            this.showSpinner = false;
        });
        // });
        this.showSpinner = true;

        if (source) source.classList.remove('active');
    }

    removeOptions($event) {
        this.nodesStore.remove('temp-100');
    }

    private createNode(el, target, data) {
        const position = this.getElementIndex(el);
        const params = {
            sectionId: this.sectionId,
            type: data[0].nodeType,
        };

        const queryParams = {
            position,
        };

        const content = data[0].content || '';

        const body = {
            content,
        };

        const payload = new NodeDto(params, queryParams, body);
        this.nodesService.createNode(payload).subscribe();

        el.remove();

        if (this.nodesQuery.hasEntity('temp-100')) {
            this.nodesStore.remove('temp-100');
        }
    }

    private onNodeMove(el, target) {
        const position = this.getElementIndex(el);
        const nodeId = el.id;

        const payload = new NodeDto({ nodeId, position });
        this.nodesService.moveNode(payload).subscribe();
    }

    private getElementIndex(el: any) {
        return [].slice.call(el.parentElement.children).indexOf(el);
    }

    private getParentByTag(element, tag) {
        if (element.tagName == tag) {
            return element;
        } else if (element.parentElement instanceof Element) {
            return this.getParentByTag(element.parentElement, tag);
        }
        return false;
    }

    onSelect(event: MouseEvent, node: Node): void {
        event.stopPropagation();
        if (this.isTargetInDeleteButton(event.target)) {
            return;
        }
        if (node.editing) {
            return;
        }

        // Keep track of the latest clicked node
        this.nextSelectedNode = new SelectedNode(node);
        if (this.waitForSaving) {
            return;
        }
        this.waitForSaving = true;

        this.saveService
            .saveAll(true)
            .pipe(take(1))
            .subscribe(() => {
                if (this.nextSelectedNode !== null) {
                    this.nodesService.selectNode(this.nextSelectedNode);
                    this.saveService.setContent(this.nextSelectedNode.element.content);
                }
                this.waitForSaving = false;
            });

        if (this.nodesQuery.hasEntity('temp-100')) {
            this.nodesStore.remove('temp-100');
        }
    }

    onRemove(node) {
        const dialogRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'node',
            },
        });

        dialogRef.afterClosed().subscribe((remove) => {
            if (remove) {
                const payload: NodeDto = new NodeDto({ nodeId: node.id });
                this.nodesService.deleteNode(payload).subscribe();
            }
        });
    }

    onNodeMoveBack(el, position) {
        const nodeId = el.id;

        const payload = new NodeDto({ nodeId, position });
        this.nodesService.moveNode(payload).subscribe();
    }

    onContentChange(content) {
        this.nodeViewModel.updateContent(content);
    }

    nullFunction(event) {
        event.stopPropagation();
        return;
    }

    ngOnDestroy() {
        this.uiService.setToolbarState('overview');
    }
}
