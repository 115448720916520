import { Injectable } from '@angular/core';
import { LoggedInUserState, LoggedInUserStore } from './logged-in-user.store';
import { Query } from '@datorama/akita';

@Injectable({
    providedIn: 'root',
})
export class LoggedInUserQuery extends Query<LoggedInUserState> {
    constructor(protected override store: LoggedInUserStore) {
        super(store);
    }
}
