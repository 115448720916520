import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserDetailValidator } from '../../../shared/validators/user-detail.validator';
import { NumberValidator } from '../../../shared/validators/number.validator';
import { FormFieldConfig } from '../../models/form-field-config.model';
import { FormValidatorsFieldConfig } from '../../models/form-validators-field-config.model';
import { FormConfig } from '../../models/form-config.model';
import { FormGroupConfig } from '../../models/form-group-config.model';

@Component({
    selector: 'elias-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    providers: [UserDetailValidator],
})
export class FormComponent implements OnInit {
    arrayTypes = ['multi-textfield', 'multi-selectfield'];
    dateTypes = ['date'];
    formGroup: FormGroup = new FormGroup({});
    initData: string[];
    lastState: any;

    @Input() data = {};
    @Input() disabledOptions;
    @Input() formConfig: FormConfig = { elements: [] };
    @Input() hideButtons = false;
    @Input() hideSaveButton = true;
    @Input() hideResetButton = true;
    @Input() hint;
    @Input() translationKey = '';
    @Input() settings;

    @Output() dirty = new EventEmitter<any>();
    @Output() formSubmitted = new EventEmitter<any>();

    countChanges = 0;

    constructor(private userDetailValidator: UserDetailValidator) {}

    ngOnInit(): void {
        this.createForm(this.formConfig, this.data);
    }

    createForm(formConfig: FormConfig, data): void {
        this.formGroup = new FormGroup({});
        this.generateFormGroup(this.formConfig.elements);
        this.addChangeListeners(this.formConfig.elements);
        this.formGroup.patchValue(Object.assign({}, data));
        this.initData = this.formGroup.value;
    }

    generateFormGroup(elements: (FormFieldConfig | FormGroupConfig)[]): void {
        elements.map((property: FormFieldConfig | FormGroupConfig) => {
            if (typeof property.name === 'undefined') {
                // Don't add properties without a name property to the form controls.
                // These are static text, static heading etc.
                return;
            }

            if (property.type === 'group') {
                const formGroup = property as FormGroupConfig;
                this.generateFormGroup(formGroup.properties);
            } else {
                const formField = property as FormFieldConfig;
                if (this.arrayTypes.includes(formField.type)) {
                    this.formGroup.addControl(formField.name, new FormArray([]));
                } else {
                    const formGroup = property as FormFieldConfig;
                    const validators: ValidatorFn[] = this.getValidators(property);
                    this.formGroup.addControl(formGroup.name, new FormControl(formGroup.value, validators));
                }
            }
        });
    }

    addChangeListeners(properties): void {
        this.formGroup.valueChanges.subscribe((value) => {
            if (JSON.stringify(value) !== JSON.stringify(this.lastState)) {
                this.lastState = value;

                this.fixInvisibleInvalidElements(properties);
                this.addAndRemoveValidators();
            }
        });
    }

    /**
     * Set value to default if an element is invalid and not visible.
     * For required conditional fields.
     */
    fixInvisibleInvalidElements(properties: (FormFieldConfig | FormGroupConfig)[]): void {
        for (const [key, formControl] of Object.entries(this.formGroup.controls)) {
            if (formControl.errors !== null) {
                const element = properties.find((el) => el.name === key);
                if (element && !this.showElement(element)) {
                    const formElement = element as FormFieldConfig;
                    formControl.setValue(formElement.defaultValue);
                }
            }
        }
    }

    addAndRemoveValidators() {
        this.formConfig.elements.forEach((property) => {
            if (typeof property.name === 'undefined') {
                // Don't add properties without a name property to the form controls.
                // These are static text, static heading etc.
                return;
            }

            if (property.type === 'group') {
                const formGroup = property as FormGroupConfig;
                if (this.showElement(property)) {
                    formGroup.properties.forEach((childProperty: FormFieldConfig) => {
                        this.addAndRemoveElementValidators(childProperty);
                    });
                } else {
                    formGroup.properties.forEach((childProperty: FormFieldConfig) => {
                        this.formGroup.get(childProperty.name).setValidators(null);
                        this.formGroup.get(childProperty.name).updateValueAndValidity();
                    });
                }
            } else {
                const formGrp = property as FormFieldConfig;
                this.addAndRemoveElementValidators(formGrp);
            }
            this.formGroup.updateValueAndValidity();
        });
    }

    addAndRemoveElementValidators(property: FormFieldConfig): void {
        let showProperty = false;
        if (property.show && property.show.length > 0) {
            property.show.forEach((show) => {
                if (this.formGroup.get(show.element).value === show.value) {
                    showProperty = true;
                }
            });
        } else {
            showProperty = true;
        }
        if (showProperty) {
            if (typeof property.name === 'undefined') {
                // Don't add properties without a name property to the form controls.
                // These are static text, static heading etc.
                return;
            }

            if (property.type === 'group') {
                const formGroup = property as FormGroupConfig;
                formGroup.properties.forEach((childProperty) => {
                    if (this.showElement(childProperty)) {
                        this.formGroup.get(childProperty.name).setValidators(this.getValidators(childProperty));
                        this.formGroup.get(childProperty.name).updateValueAndValidity();
                    }
                });
            } else {
                if (this.showElement(property)) {
                    this.formGroup.get(property.name).setValidators(this.getValidators(property));
                    this.formGroup.get(property.name).updateValueAndValidity();
                }
            }
        } else {
            if (property.type === 'group') {
                const formGroup = property as FormGroupConfig;
                formGroup.properties.forEach((childProperty) => {
                    this.formGroup.get(childProperty.name).setValidators(null);
                    this.formGroup.get(childProperty.name).updateValueAndValidity();
                });
            } else {
                this.formGroup.get(property.name).setValidators(null);
                this.formGroup.get(property.name).updateValueAndValidity();
            }
        }
    }

    getPropertyByName(name, properties: (FormFieldConfig | FormGroupConfig)[] = this.formConfig.elements) {
        for (let i = 0; i < properties.length; i++) {
            if (properties[i].type === 'group') {
                const formGroup = properties[i] as FormGroupConfig;
                return this.getPropertyByName(name, formGroup.properties);
            }
            if (properties[i] === name) {
                return properties[i];
            }
        }
        return null;
    }

    getValidators(property: FormFieldConfig): ValidatorFn[] {
        const validators = [];
        if (property.required) {
            validators.push(Validators.required);
        }
        if (property.validators) {
            property.validators.forEach((validator: FormValidatorsFieldConfig) => {
                switch (validator.name) {
                    case 'email':
                        validators.push(Validators.email);
                        break;
                    case 'maxLength':
                        validators.push(Validators.maxLength(validator.options));
                        break;
                    case 'minLength':
                        validators.push(Validators.minLength(validator.options));
                        break;
                    case 'pattern':
                        const regex = new RegExp(validator.options);
                        validators.push(Validators.pattern(regex));
                        break;
                    case 'uniqueUsername':
                        validators.push(this.userDetailValidator.uniqueUsername.bind(this.userDetailValidator));
                        break;
                    case 'uniqueEmail':
                        validators.push(this.userDetailValidator.uniqueEmail.bind(this.userDetailValidator));
                        break;
                    case 'maxValue':
                        validators.push(Validators.max(validator.options), NumberValidator.createNumberValidator(true));
                        break;
                    case 'minValue':
                        validators.push(Validators.min(validator.options), NumberValidator.createNumberValidator(true));
                        break;
                    case 'passwordMatch':
                        validators.push(this.userDetailValidator.passwordMatch.bind(this.userDetailValidator));
                        break;
                    case 'number':
                        validators.push(NumberValidator.createNumberValidator(false));
                        break;
                }
            });
        }
        return validators;
    }

    onSubmit(): void {
        this.formSubmitted.emit(this.formGroup.value);
    }

    onReset(): void {
        this.createForm(this.formConfig, this.data);
        // this.reset.emit(this.formGroup.value);
    }

    isDirty(): boolean {
        if (!this.initData || !this.formGroup || !this.formGroup.value) {
            return false;
        }

        return JSON.stringify(this.initData) !== JSON.stringify(this.formGroup.value);
    }

    isValid(): boolean {
        return this.formGroup.valid;
    }

    canSave(): boolean {
        return this.isDirty() && this.isValid();
    }

    getNumberOfChanges(): number {
        let count = 0;
        if (this.isDirty()) {
            Object.keys(this.initData).map((key) => {
                if (JSON.stringify(this.initData[key]) !== JSON.stringify(this.formGroup.value[key])) {
                    count++;
                }
            });
        }
        return count;
    }

    showElement(element: FormFieldConfig): boolean {
        if (!element.show || element.show.length === 0) {
            return true;
        }

        // If every "show" condition is satisfied, we can return true.
        let showState = true;

        element.show.forEach((show) => {
            if (
                !this.formGroup.get(show.element) ||
                !this.checkElementCondition(this.formGroup.get(show.element).value, show.value, show.operator)
            ) {
                showState = false;
            }
        });

        return showState;
    }

    checkElementCondition(value, checkAgainst, operator = '='): boolean {
        switch (operator) {
            case '=':
                return value === checkAgainst;
            default:
                return eval(parseInt(value) + ' ' + operator + ' ' + parseInt(checkAgainst));
        }
    }

    /* only for debugging */
    getFormValidationErrors(): string[] {
        const errors = [];
        Object.keys(this.formGroup.controls).forEach((key) => {
            const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    errors.push(
                        'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                        controlErrors[keyError]
                    );
                });
            }
        });
        return errors;
    }
}
