import { NodePresetsStore } from './node-presets.store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NodePreset } from './node-preset.model';
import { DomainService } from '../../../core/services/domain.service';

@Injectable({
    providedIn: 'root',
})
export class NodePresetsService {
    baseUrl: string = this.domainService.apiBaseUrl + '/node-presets';

    constructor(
        private domainService: DomainService,
        private nodePresetsStore: NodePresetsStore,
        private http: HttpClient
    ) {}

    /** GET nodes for specified section from the server */
    getNodePresets(): Observable<NodePreset[]> {
        this.nodePresetsStore.setLoading(true);
        const url = `${this.baseUrl}`;

        return this.http.get<NodePreset[]>(url).pipe(
            tap((nodePresets) => {
                this.nodePresetsStore.set(nodePresets);
                this.nodePresetsStore.update({ loaded: true });
                this.nodePresetsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateNodePreset(nodePreset: NodePreset, data: any) {
        this.nodePresetsStore.setLoading(true);
        const url = `${this.baseUrl}/${nodePreset.nodeType}/enable`;

        return this.http.patch<NodePreset>(url, data).pipe(
            tap((nodePreset) => {
                this.nodePresetsStore.update(nodePreset.nodeType, nodePreset);
                this.nodePresetsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
