import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { Asset } from '../../../../shared/state/assets/asset.model';
import { RequestDto } from '../../../../../editor/editor/models/request-dto.model';
import { AssetsQuery } from '../../../../shared/state/assets/assets.query';
import { AssetsService } from '../../../../shared/state/assets/assets.service';

@Component({
    selector: 'elias-form-element-asset',
    templateUrl: './form-asset.component.html',
    styleUrls: ['./form-asset.component.scss'],
})
export class FormAssetComponent extends BasicFormElementComponent implements OnInit {
    @Input()
    allowedTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

    @Input()
    settings;

    asset: Asset;
    aspectRatio = null;
    fileUpload: boolean;
    text = 'section';

    constructor(injector: Injector, private assetsQuery: AssetsQuery, private assetsService: AssetsService) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            const payload = new RequestDto({ assetId: this.initValue }, {}, {});
            if (!(this.asset = this.assetsQuery.getEntity(this.initValue))) {
                this.assetsService.loadSingleAsset(payload).subscribe((asset) => {
                    this.asset = asset;
                    this.formGroup.get(this.name).setValue(this.asset.id);
                });
            } else {
                this.formGroup.get(this.name).setValue(this.asset.id);
            }
        }
        if (Object.keys(this.settings).length > 0) {
            this.fileUpload = this.settings['fileUpload'];
            if (this.settings['type']) {
                this.text = this.settings['type'];
            }
        }
    }

    onSelect(assets: Asset) {
        this.asset = assets[0];
        this.formGroup.get(this.name).setValue(this.asset.id);
    }

    removeAsset() {
        event.preventDefault();
        this.formGroup.get(this.name).setValue(null);
        this.asset = null;
    }
}
