<div *ngIf="!loading">
    <!--<div class="pe-preset-group-header pe-heading pe-heading-2 pe-accordion-header">Content Elements</div>-->

    <div class="pe-preset-group" *ngFor="let group of presetGroups; trackBy: trackByIndex">
        <!--<div class="pe-preset-group-header pe-heading pe-heading-2 pe-accordion-header" (click)="toggleGroupVisibility(group)"><i
                [ngClass]="{'icon-arrows-up': group['visible'], 'icon-arrows-down': !group['visible']}"></i> {{
            'presetBrowser.' + group['name'] | translate}}
        </div>-->
        <div *ngIf="group['size'] > 0">
            <div
                class="pe-preset-container"
                [dragula]="'bag-draggable'"
                [@slideInOut]="getGroupVisibility(group)"
                fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <div
                    id="draggable"
                    class="pe-preset"
                    fxFlex="0 1"
                    [attr.data-items]="group['data'] | json"
                    [attr.data-presetGroup]="group['name']"
                    fxLayoutGap="10px">
                    <i class="ei {{ group['icon'] }} ei-150"></i>
                    <span class="label">{{ 'presetBrowser.' + group['name'] | translate }}</span>
                </div>
            </div>
        </div>
        <br />
    </div>
</div>
