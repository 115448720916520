import { Query } from '@datorama/akita';
import { PropertyEditorState, PropertyEditorStore, propertyEditorStore } from './property-editor.store';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PropertyEditorQuery extends Query<PropertyEditorState> {
    constructor(protected store: PropertyEditorStore) {
        super(store);
    }
}

export const propertyEditorQuery = new PropertyEditorQuery(propertyEditorStore);
