import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentStatus } from '../../enums/comment-status.enum';
import { Comment } from '../../models/comment.model';

@Component({
    selector: 'elias-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
    public CommentStatusEnum = CommentStatus;

    @Input() comment?: Comment;
    @Input() status: CommentStatus = CommentStatus.Editable;
    @Input() isSelected: boolean = false;
    @Input() isReplied: boolean = false;
    @Input() showControls: boolean = true;

    @Output() answerClicked = new EventEmitter<Comment>();
    @Output() commentResolved = new EventEmitter<Comment>();
    @Output() commentUnresolved = new EventEmitter<Comment>();
    @Output() commentLinkClicked = new EventEmitter<Comment>();

    get hasDefaultAction(): boolean {
        if (!this.showControls || !this.comment) {
            return false;
        }

        if (this.status === CommentStatus.Editable && this.comment.resolvedAt) {
            return true;
        }

        return this.status === CommentStatus.ReadOnly;
    }

    public onCommentClicked(): void {
        if (!this.hasDefaultAction) {
            return;
        }

        if (this.status === CommentStatus.Editable) {
            this.onCommentUnresolved();
        }

        if (this.status === CommentStatus.ReadOnly) {
            this.onCommentLinkClicked();
        }
    }

    public onCommentResolved(): void {
        this.commentResolved.emit(this.comment);
    }

    public onCommentUnresolved(): void {
        this.commentUnresolved.emit(this.comment);
    }

    public onCommentReplied(): void {
        this.answerClicked.emit(this.comment);
    }

    public onCommentLinkClicked(): void {
        this.commentLinkClicked.emit(this.comment);
    }

    public onReplyLinkClicked() {
        this.commentLinkClicked.emit(this.comment);
    }
}
