<div *ngIf="loaded$ | async; else isLoading" class="comments-wrapper">
    <div class="container">
        <elias-shared-module-title [title]="title"></elias-shared-module-title>
        <elias-comment-list-filters
            *ngIf="showFilters"
            (filtersChanged)="onFiltersChanged()"></elias-comment-list-filters>
    </div>
    <div class="comment-list">
        <div
            class="container"
            (lastItemReached)="loadMoreComments()"
            eliasInfiniteScrollingContainer
            eliasScrollableContainer>
            <elias-comment
                *ngFor="let comment of comments$ | async; let commentIndex = index"
                [comment]="comment"
                [status]="commentStatus"
                [isSelected]="(selectedComment$ | async)?.id === comment.id"
                [isReplied]="(selectedComment$ | async)?.id === comment.id && editorAction === 'reply'"
                [showControls]="showControls"
                [id]="comment.id"
                (commentResolved)="onCommentResolved($event)"
                (commentUnresolved)="onCommentUnresolved($event)"
                (commentLinkClicked)="onCommentLinkClicked($event)"
                (answerClicked)="onAnswerClicked($event)"
                eliasInfiniteScrollingItem
                eliasScrollableItem></elias-comment>
            <elias-shared-placeholder
                [message]="'comments.empty' | translate"
                *ngIf="!(comments$ | async)?.length"></elias-shared-placeholder>
        </div>
    </div>
    <elias-comment-editor
        *ngIf="commentStatus === CommentStatusEnum.Editable"
        [action]="editorAction"
        [initialContent]="initialContent"
        (commentCancelled)="onCommentCancelled()"
        (commentSubmitted)="onCommentSubmitted($event)"
        (commentDraftUpdated)="onCommentDraftUpdated($event)"></elias-comment-editor>
</div>
<ng-template #isLoading>
    <elias-shared-loader [includeText]="false"></elias-shared-loader>
</ng-template>
