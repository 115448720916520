import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { CategoriesService } from '../state/categories/categories.service';
import { LoggedInUserService } from '../state/logged-in-user/logged-in-user.service';
import { SystemInformationService } from '../state/system-information/system-information.service';
import { LoggedInUserQuery } from '../state/logged-in-user/logged-in-user.query';
import { SystemInformationQuery } from '../state/system-information/system-information.query';
import { CategoriesQuery } from '../state/categories/categories.query';
import { UsersService } from '../state/users/users.service';
import { UsersQuery } from '../state/users/users.query';
import { NotificationsQuery } from '../../../notifications/state/notifications.query';
import { NotificationsService } from '../../../notifications/state/notifications.service';

@Injectable()
export class InitDataResolver implements Resolve<any> {
    constructor(
        private categoriesService: CategoriesService,
        private categoriesQuery: CategoriesQuery,
        private loggedInUserService: LoggedInUserService,
        private loggedInUserQuery: LoggedInUserQuery,
        private sysInfoService: SystemInformationService,
        private sysInfoQuery: SystemInformationQuery,
        private userService: UsersService,
        private userQuery: UsersQuery,
        private notificationsQuery: NotificationsQuery,
        private notificationsService: NotificationsService
    ) {}

    resolve() {
        const observables = [];
        if (!this.userQuery.getValue().loaded) {
            observables.push(this.userService.getUsers());
        }
        if (!this.loggedInUserQuery.getValue().loaded) {
            observables.push(this.loggedInUserService.getLoggedInUser());
        }
        if (!this.sysInfoQuery.getValue().loaded) {
            observables.push(this.sysInfoService.getSystemInformation());
        }
        if (!this.categoriesQuery.getValue().loaded) {
            observables.push(this.categoriesService.getCategories());
        }
        if (!this.notificationsQuery.getValue().loaded) {
            observables.push(this.notificationsService.getNumberOfUnreadNotifications());
        }

        if (observables.length > 0) {
            return forkJoin(observables);
        } else {
            return of(true);
        }
    }
}
