import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Observable } from 'rxjs';
import { RequestDto } from '../../../models/request-dto.model';
import { ImagesService } from '../../../../editor-container/state/images/images.service';

@Component({
    selector: 'elias-editor-display-keyimages',
    templateUrl: './display-keyimages.component.html',
    styleUrls: ['display-keyimages.component.scss'],
})
export class DisplayKeyImagesComponent implements NodeRepresentation, OnInit {
    @Input() content: string;
    @Input() config: { type: NodeType; settings?: any };
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    keyImages = [];
    cols = 2;
    aspectRatio;
    keyImageLayout;

    constructor(private imageService: ImagesService) {}

    ngOnInit() {
        this.parseContent();
        this.cols = 12 / this.node.cols;
        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }
        this.keyImageLayout = this.node.layout;
        this.loadKeyImagesAssets();
    }

    parseContent() {
        this.keyImages = JSON.parse(this.content);
    }

    trackByIndex(index, item) {
        return index;
    }

    loadKeyImagesAssets() {
        for (let j = 0; j < this.node.cols; j++) {
            for (let i = 0; i < this.keyImages[j].length; i++) {
                if (
                    this.keyImages[j][i] !== [] &&
                    this.keyImages[j][i].hasOwnProperty('image') &&
                    (this.keyImages[j][i].image !== undefined || this.keyImages[j][i].image !== null)
                ) {
                    const keyImage = this.keyImages[j][i];
                    const payload = new RequestDto(
                        {
                            assetId: keyImage.image,
                            aspectRatio: this.node.aspectRatio,
                        },
                        {},
                        {}
                    );

                    this.imageService.loadSingleImage(payload).subscribe();
                }
            }
        }
    }
}
