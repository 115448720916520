import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NodePreset } from '../../../../modules/shared/state/node-presets/node-preset.model';
import { NodePresetsQuery } from '../../../../modules/shared/state/node-presets/node-presets.query';

@Component({
    selector: 'elias-editor-preset-browser',
    styleUrls: ['./preset-browser.component.scss'],
    templateUrl: './preset-browser.component.html',
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    opacity: '1',
                    height: '*',
                })
            ),
            state(
                'out',
                style({
                    opacity: '0',
                    height: '0px',
                })
            ),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out')),
        ]),
    ],
})
export class PresetBrowserComponent implements OnInit, OnDestroy {
    @Input() documentLoading$: Observable<boolean>;
    loading = true;
    subscription: ISubscription;
    presetGroups: any[];
    groupedNodePresets: any[];

    @Input() set nodePresets(nodePresets: Observable<NodePreset[]>) {
        const groupedNodePresets = [];
        const groups = [];
        nodePresets.subscribe((presets) => {
            presets.forEach((nodePreset: NodePreset) => {
                if (nodePreset.enabled == true) {
                    if (!groupedNodePresets.hasOwnProperty(nodePreset.presetGroup)) {
                        groupedNodePresets[nodePreset.presetGroup] = [];
                        const icon = this.formatIcons(nodePreset.presetGroup);
                        let size;
                        this.nodePresetQuery
                            .selectCount(
                                (entity) => entity.presetGroup === nodePreset.presetGroup && entity.enabled == true
                            )
                            .subscribe((x) => (size = x));
                        const data = this.nodePresetQuery.getAll({
                            filterBy: (entity) =>
                                entity.presetGroup === nodePreset.presetGroup && entity.enabled === true,
                        });
                        groups.push({
                            name: nodePreset.presetGroup,
                            visible: true,
                            index: groups.length,
                            icon,
                            size,
                            data,
                        });
                    }
                    groupedNodePresets[nodePreset.presetGroup].push(nodePreset);
                }
            });
            this.presetGroups = groups;
            this.groupedNodePresets = groupedNodePresets;
        });
    }

    // TODO Use NodeTypes to get the icon directly
    formatIcons(groups) {
        if (groups === 'table') return 'ei-tools-table';
        else if (groups === 'text') return 'ei-tools-title';
        else if (groups === 'structure') return 'ei-tools-page-break';
        else if (groups === 'image') return 'ei-tools-image';
        else if (groups === 'chart') return 'ei-tools-graphs-bar';
        else if (groups === 'maps') return 'ei-tools-google-maps';
    }

    constructor(private ref: ChangeDetectorRef, private nodePresetQuery: NodePresetsQuery) {}

    ngOnInit() {
        this.subscription = this.documentLoading$.subscribe((loading) => {
            this.loading = loading;
            this.ref.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.ref.detach();
    }

    toggleGroupVisibility(group) {
        this.presetGroups[group.index].visible = !group.visible;
    }

    getGroupVisibility(group) {
        return this.presetGroups[group.index].visible ? 'in' : 'out';
    }

    trackByIndex(index, item) {
        return index;
    }
}
