import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Channel } from './channel.model';
import { Injectable } from '@angular/core';

export interface ChannelsState extends EntityState<Channel> {
    loaded: boolean;
}

const InitialState = {
    loaded: false,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Channels' })
export class ChannelsStore extends EntityStore<ChannelsState, Channel> {
    constructor() {
        super(InitialState);
    }
}
