import { Component, Input } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { Observable } from 'rxjs/Observable';
import { TableNode } from '../../../models/node/table-node.model';
import { Node } from '../../../state/nodes/node.model';

@Component({
    selector: 'elias-editor-display-table',
    styleUrls: ['./display-table.component.scss'],
    templateUrl: './display-table.component.html',
})
export class DisplayTableComponent implements NodeRepresentation {
    @Input() content!: string;
    @Input() config!: { type: NodeType; settings?: any };
    @Input() node!: TableNode;
    @Input() nodeViewModel!: Observable<Node>;

    get renderedContent(): string | undefined {
        return this.node.renderedContent;
    }

    get hasRenderedContent(): boolean {
        return !!this.renderedContent;
    }
}
