<div class="container property-editor-container">
    <ng-container *ngIf="loaded$ | async">
        <elias-shared-sidebar-title
            title="{{ 'propertyEditor.widgetTitle' | translate }}"
            subtitle="{{ 'assetBrowser.assetfile.description' | translate }}"
            [buttonFlexLayout]="'end'">
            <button *ngIf="isDelete" mat-icon-button [matMenuTriggerFor]="menu" class="menu-button-icon">
                <i class="ei ei-more-vert"></i>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="remove()">
                    <i class="ei ei-trash"></i>{{ 'actions.delete' | translate }}
                </button>
                <button mat-menu-item (click)="download()">
                    <i class="ei ei-download"></i>{{ 'actions.download' | translate }}
                </button>
                <button mat-menu-item (click)="replaceFileInput.click()">
                    <i class="ei ei-copy"></i>{{ 'actions.replace' | translate }}
                </button>
            </mat-menu>
        </elias-shared-sidebar-title>

        <div>
            <elias-shared-asset-view [selectedAsset]="data"></elias-shared-asset-view>
            <elias-form
                [formConfig]="formConfig"
                translationKey="propertyEditor"
                [data]="data"
                [hideButtons]="true"
                (formSubmitted)="onFormSubmitted($event)"
                #form></elias-form>
        </div>

        <ng-container *ngIf="assetPublicationsPartOf && assetPublicationsPartOf.length > 0">
            <h3 class="title">{{ 'assetStorages.publicationPartOf.title' | translate }}</h3>
            <div class="attention-box">
                <span class="attention-details">{{ 'assetStorages.publicationPartOf.attention' | translate }}</span>
            </div>
            <mat-list>
                <mat-list-item *ngFor="let pub of assetPublicationsPartOf" class="list-view">
                    <div class="column column-title">
                        <h3 class="title">{{ pub.pubName }} / {{ pub.sectionName }}</h3>
                    </div>
                    <div class="column column-language">
                        <span>{{ getLanguage(pub) }}</span>
                    </div>
                    <div class="column column-content">
                        <div class="tags" fxLayout="row wrap" fxLayoutGap="6px">
                            <div
                                class="tag dots"
                                *ngFor="let category of ObjectKeys(pub.categories)"
                                [ngStyle]="{
                                    'background-color': categoryQuery.getEntity(category).color
                                }"
                                matTooltip="{{ categoryQuery.getEntity(category).name }}"></div>
                        </div>
                    </div>
                    <div class="column column-meta">
                        <button
                            mat-flat-button
                            color="accent"
                            class="edit-list-button"
                            (click)="navigateToPublicationSection(pub)">
                            {{ 'actions.go' | translate }}
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </ng-container>

        <div fxLayout="row" class="action-buttons" fxLayoutGap="15%" *ngIf="form.canSave()" [@slideInOut]>
            <button
                color="accent"
                class="btn-save"
                mat-flat-button
                [disabled]="!form.canSave() || isSaving"
                (click)="form.onSubmit()">
                <ng-container *ngIf="!isSaving">
                    {{ 'actions.save' | translate }}
                </ng-container>
                <ng-container *ngIf="isSaving">
                    <mat-spinner [diameter]="15" color="accent"></mat-spinner>
                </ng-container>
            </button>
            <button mat-button (click)="close()">{{ 'actions.cancel' | translate }}</button>
            <div class="changes">
                <ng-container *ngIf="form.isDirty() && form.getNumberOfChanges() === 1">
                    {{ form.getNumberOfChanges() }} {{ 'shared.change' | translate }}
                </ng-container>
                <ng-container *ngIf="form.isDirty() && form.getNumberOfChanges() > 1">
                    {{ form.getNumberOfChanges() }} {{ 'shared.changes' | translate }}
                </ng-container>
            </div>
        </div>

        <input
            #replaceFileInput
            type="file"
            tabindex="-1"
            style="visibility: hidden"
            (change)="replaceFile($event)"
            [accept]="replacementMimeTypes" />
    </ng-container>
</div>
