import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- #1 import module
import { EditorRoutingModule } from './editor-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../../modules/shared/shared.module';
import { EditorComponent } from './pages/editor/editor.component';
import { DocumentComponent } from './pages/document/document.component';
import { PropertyEditorComponent } from './components/property-editor/property-editor.component';
import { PresetBrowserComponent } from './components/preset-browser/preset-browser.component';
import { UnknownNodeComponent } from './components/node-unknown/node-unknown.component';
import { EditableNodeComponent } from './components/node-editable/node-editable.component';
import { NodeDirective } from './directives/node.directive';
import { DragulaService } from 'ng2-dragula';
import * as fromServices from './services';
import { NodeViewModel } from './viewmodels/node.viewmodel';
import { EditTextComponent } from './components/edit/text/edit-text.component';
import { DisplayTextComponent } from './components/display/text/display-text.component';
import { EditTableComponent } from './components/edit/table/edit-table.component';
import { DisplayTableComponent } from './components/display/table/display-table.component';
import { EditImageComponent } from './components/edit/image/edit-image.component';
import { DisplayImageComponent } from './components/display/image/display-image.component';
import { DisplayChartComponent } from './components/display/chart/display-chart.component';
import { NewLineToBreakPipe } from '../../modules/shared/pipes/newLineToBreak.pipe';
import { EditImageGridComponent } from './components/edit/imagegrid/edit-imagegrid.component';
import { DisplayImageGridComponent } from './components/display/imagegrid/display-imagegrid.component';
import { DisplayTwoColsStartComponent } from './components/display/two-cols-start/display-two-cols-start.component';
import { DisplayTwoColsEndComponent } from './components/display/two-cols-end/display-two-cols-end.component';
import { DisplayPageBreakComponent } from './components/display/page-break/display-page-break.component';
import { DisplayColBreakComponent } from './components/display/col-break/display-col-break.component';
import { DisplayTableOfContentComponent } from './components/display/table-of-content/display-table-of-content.component';
import { SectionHeadlineComponent } from './components/section-headline/section-headline.component';
import { TreeService } from './services/tree.service';
import { DisplayLineComponent } from './components/display/line/display-line.component';
import { EditKeyFiguresComponent } from './components/edit/keyfigures/edit-keyfigures.component';
import { DisplayKeyfiguresComponent } from './components/display/keyfigures/display-keyfigures.component';
import { PreviewButtonComponent } from './components/preview-button/preview-button.component';
import { PreviewHtmlComponent } from './components/preview-html/preview-html.component';
import { DisplayExcelTableComponent } from './components/display/excel-table/display-excel-table.component';
import { ImageLoaderService } from './services/image-loader.service';
import { HotTableModule } from '@handsontable/angular';
import { SaveService } from './services/save.service';
import { AssetBrowserModule } from '../asset-browser/asset-browser.module';
import { CustomAssetComponent } from './components/custom-asset/custom-asset.component';
import { NewSectionComponent } from './dialogs/new-section/new-section.component';
import { MatTreeModule } from '@angular/material/tree';
import { EliasFormsModule } from '../../modules/forms/forms.module';
import { NodeSelectionComponent } from './components/node-selection/node-selection.component';
import { DataService } from './services/data.service';
import { ItemCustomComponent } from './components/item-custom/item-custom.component';
import { AssetBrowserService } from '../asset-browser/services/asset-browser.service';
import { EditorContainerService } from '../editor-container/services/editor-container.service';
import { DisplayKeyImagesComponent } from './components/display/keyimages/display-keyimages.component';
import { EditKeyImagesComponent } from './components/edit/keyimages/edit-keyimages.component';
import { DisplayMapsComponent } from './components/display/maps/display-maps.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        HotTableModule,
        EditorRoutingModule,
        FlexLayoutModule,
        AssetBrowserModule,
        MatTreeModule,
        FormsModule,
        ReactiveFormsModule,
        EliasFormsModule,
    ],
    declarations: [
        EditorComponent,
        DocumentComponent,
        PresetBrowserComponent,
        PropertyEditorComponent,
        EditableNodeComponent,
        EditTextComponent,
        DisplayTextComponent,
        EditImageComponent,
        DisplayImageComponent,
        CustomAssetComponent,
        EditTableComponent,
        DisplayTableComponent,
        DisplayLineComponent,
        DisplayExcelTableComponent,
        DisplayKeyfiguresComponent,
        EditKeyFiguresComponent,
        DisplayKeyImagesComponent,
        EditKeyImagesComponent,
        DisplayChartComponent,
        DisplayTwoColsStartComponent,
        DisplayTwoColsEndComponent,
        DisplayPageBreakComponent,
        DisplayColBreakComponent,
        DisplayTableOfContentComponent,
        UnknownNodeComponent,
        NodeDirective,
        PreviewButtonComponent,
        PreviewHtmlComponent,
        EditImageGridComponent,
        DisplayImageGridComponent,
        SectionHeadlineComponent,
        NewSectionComponent,
        NodeSelectionComponent,
        ItemCustomComponent,
        DisplayMapsComponent,
    ],
    providers: [
        ...fromServices.services,
        NewLineToBreakPipe,
        DragulaService,
        ImageLoaderService,
        TreeService,
        SaveService,
        NodeViewModel,
        DataService,
        AssetBrowserService,
        EditorContainerService,
    ],
})
export class EditorModule {}
