<div class="pe pe-keyfigures keyfigure-{{ keyFigureLayout }} row">
    <div *ngFor="let keyFigureCol of keyFigures; trackBy: trackByIndex" class="col-{{ cols }}">
        <div *ngFor="let keyFigureItem of keyFigureCol; trackBy: trackByIndex" class="keyfigure">
            <div class="title" [innerHTML]="keyFigureItem.title"></div>
            <div>
                <span class="number" [innerHTML]="keyFigureItem.number"></span>
                <span class="unit" [innerHTML]="keyFigureItem.unit"></span>
            </div>
            <div class="description" [innerHTML]="keyFigureItem.description"></div>
        </div>
    </div>
</div>
