import { Injectable } from '@angular/core';
import { AssetsQuery } from '../../../modules/shared/state/assets/assets.query';
import { AssetsService } from '../../../modules/shared/state/assets/assets.service';
import { RequestDto } from '../../editor/models/request-dto.model';
import { FlashMessageService } from '../../../modules/core/services/flash-message.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Injectable()
export class AssetBrowserService {
    constructor(
        private assetsQuery: AssetsQuery,
        private assetsService: AssetsService,
        private flashMessageService: FlashMessageService
    ) {}

    isFile(evt) {
        let dt = evt.dataTransfer;

        for (let i = 0; i < dt.types.length; i++) {
            if (dt.types[i] === 'Files') {
                return true;
            }
        }
        return false;
    }

    onDragEnter() {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'unset';
        dragDropIconText.style.visibility = 'unset';
        dropzone.style.opacity = '1';
        dragDropIconText.style.opacity = '1';
    }

    onDrop() {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'hidden';
        dragDropIconText.style.visibility = 'hidden';
        dropzone.style.opacity = '0';
        dragDropIconText.style.opacity = '0';
    }

    onDragLeave() {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'hidden';
        dragDropIconText.style.visibility = 'hidden';
        dropzone.style.opacity = '0';
        dragDropIconText.style.opacity = '0';
    }

    stickToTop(stickySearchTerm) {
        const configContainer = document.getElementsByClassName('view-config-container');
        const stickyContainer = document.getElementsByClassName('sticky-content');
        if (configContainer && stickyContainer) {
            if (stickySearchTerm) {
                this.setStickyVisible(stickyContainer, true);
                this.setContainerVisible(configContainer, false);
            } else if (configContainer[1] && (configContainer[1] as HTMLElement).getBoundingClientRect().top < 100) {
                this.setStickyVisible(stickyContainer, true);
                this.setContainerVisible(configContainer, false);
            } else {
                this.setStickyVisible(stickyContainer, false);
                this.setContainerVisible(configContainer, true);
            }
        }
    }

    applyNewStickyCondition(value) {
        const configContainer = document.getElementsByClassName('view-config-container');
        const stickyContainer = document.getElementsByClassName('sticky-content');
        if (value) {
            this.setStickyVisible(stickyContainer, true);
            this.setContainerVisible(configContainer, false);
        } else {
            this.setStickyVisible(stickyContainer, false);
            this.setContainerVisible(configContainer, true);
        }
    }

    setStickyVisible(stickyContainer, value) {
        if (value) {
            (stickyContainer as unknown as HTMLElement)[0].style.visibility = 'visible';
            (stickyContainer as unknown as HTMLElement)[0].style.opacity = '1';
        } else {
            (stickyContainer as unknown as HTMLElement)[0].style.visibility = 'hidden';
            (stickyContainer as unknown as HTMLElement)[0].style.opacity = '0';
        }
    }

    setContainerVisible(configContainer, value) {
        if (value) {
            (configContainer[1] as unknown as HTMLElement).style.visibility = 'visible';
            (configContainer[1] as unknown as HTMLElement).style.opacity = '1';
        } else {
            (configContainer[1] as unknown as HTMLElement).style.visibility = 'hidden';
            (configContainer[1] as unknown as HTMLElement).style.opacity = '0';
        }
    }

    getAssetMimeType(value) {
        return !(value == 'image/jpeg' || value == 'image/png' || value == 'image/gif' || value == 'image/jpg');
    }
}
