import { Component } from '@angular/core';

@Component({
    selector: 'elias-core-not-found',
    template: `<h2>Not found</h2>
        <p>The resource you are looking for cannot be found.</p>`,
    styleUrls: ['not-found.component.scss'],
})
export class NotFoundComponent {
    constructor() {}
}
