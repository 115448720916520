<div [ngSwitch]="type" style="word-break: break-word">
    <div
        class="editor pe-reset pe-style-title {{ titleClass }}"
        id="{{ containerId }}"
        [innerHTML]="localContent || '&nbsp;' | html"
        *ngSwitchCase="'title'"></div>
    <h6
        class="editor pe-reset pe-style-quote"
        id="{{ containerId }}"
        [innerHTML]="localContent | html"
        *ngSwitchCase="'quote'"></h6>
    <abbr
        class="editor pe-reset pe-style-author"
        id="{{ containerId }}"
        [innerHTML]="localContent | html"
        *ngSwitchCase="'author'"></abbr>
    <small
        class="editor pe-reset pe-style-small"
        id="{{ containerId }}"
        [innerHTML]="localContent | html"
        *ngSwitchCase="'small'"></small>
    <dfn
        class="editor pe-reset pe-style-big"
        id="{{ containerId }}"
        [innerHTML]="localContent | html"
        *ngSwitchCase="'big'"></dfn>
    <div
        class="editor pe-reset pe-style-lead node-type-lead lead"
        id="{{ containerId }}"
        [innerHTML]="localContent | html"
        *ngSwitchCase="'lead'"></div>
    <div
        class="editor pe-reset pe-style-text"
        id="{{ containerId }}"
        [ngClass]="{
            multicolumn: (nodeViewModel | async)?.multiColumn,
            'alignment-left': (nodeViewModel | async)?.alignment === 'left',
            'alignment-center': (nodeViewModel | async)?.alignment === 'center',
            'alignment-right': (nodeViewModel | async)?.alignment === 'right',
            'alignment-justify': (nodeViewModel | async)?.alignment === 'justify'
        }"
        [innerHTML]="localContent | html"
        *ngSwitchDefault></div>
</div>
