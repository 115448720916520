import { Component, NgZone, OnInit } from '@angular/core';
import { SaveService } from '../../../../editor/editor/services/save.service';
import { AuthService } from '../../../../auth/service/auth.service';
import { UiQuery } from '../../state/ui/ui.query';
import { Section } from '../../../../editor/editor/state/sections/section.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SideNavService } from '../../services/side-nav.service';
import { MainSideNavService } from '../../services/main-side-nav.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { User } from '../../../shared/state/users/user.model';
import { LoggedInUserService } from '../../../shared/state/logged-in-user/logged-in-user.service';
import { NavigationEnd, Router } from '@angular/router';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { PublicationGroupQuery } from '../../../../publication-group/state/publication-group/publication-group.query';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { SectionsQuery } from '../../../../editor/editor/state/sections/sections.query';
import { NotificationsQuery } from '../../../../notifications/state/notifications.query';
import { NotificationUpdateListener } from '../../../../notifications/listeners/notification-update.listener';

@Component({
    selector: 'elias-core-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    public activeSection$: Observable<Section>;
    public activeUrl: string;
    public path: string;
    public toolbarState = 'overview';
    public targetUrl: string;
    public userDetail: User;
    public numberOfUnreadNotifications!: number;

    public options: AnimationOptions = {
        path: './assets/images/animation/elias_logo_animation.json',
        autoplay: false,
        loop: false,
    };

    private animationItem: AnimationItem;

    constructor(
        private authService: AuthService,
        private loggedInUserService: LoggedInUserService,
        private mainSideNavService: MainSideNavService,
        private ngZone: NgZone,
        private notificationsQuery: NotificationsQuery,
        private notificationUpdateListener: NotificationUpdateListener,
        private publicationGroupQuery: PublicationGroupQuery,
        private publicationsQuery: PublicationsQuery,
        private router: Router,
        private saveService: SaveService,
        public sectionsQuery: SectionsQuery,
        private sideNavService: SideNavService,
        private translateService: TranslateService,
        private uiQuery: UiQuery
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeUrl = event.url;
                const url = this.activeUrl;
                const searchText = 'editor';
                const searchTextIndex = url.search(searchText) + searchText.length;
                const targetURL = url.slice(0, searchTextIndex);
                this.targetUrl = targetURL;
            }
        });
    }

    ngOnInit(): void {
        this.uiQuery.select('toolbar').subscribe((toolbar) => {
            this.toolbarState = toolbar.state;
        });

        this.loggedInUserService.getLoggedInUserDetail().subscribe((user) => {
            if (user) {
                this.userDetail = user;
            }
        });

        this.activeSection$ = this.getActiveSection();

        this.notificationUpdateListener.numberOfUnreadNotificationsUpdate$.subscribe();
        this.notificationsQuery.select('additionalData').subscribe((additionalData) => {
            this.numberOfUnreadNotifications = additionalData.numberOfUnreadNotifications;
        });
    }

    openMainNav(): void {
        if (this.sideNavService.isOpen()) this.sideNavService.close();
        this.mainSideNavService.toggle();
    }

    startAnimation(): void {
        this.ngZone.runOutsideAngular(() => this.animationItem.play());
    }

    stopAnimation(): void {
        this.ngZone.runOutsideAngular(() => setTimeout(() => this.animationItem.stop(), 5000));
    }

    animationCreated(animationItem: AnimationItem): void {
        this.animationItem = animationItem;
    }

    trackByIndex(index: number): number {
        return index;
    }

    closeEditor(): void {
        this.router.navigate([
            '/publication-groups',
            this.publicationGroupQuery.getActiveId(),
            'publication',
            (this.publicationsQuery.getActive() as Publication).id,
        ]);
    }

    getActiveSection(): Observable<Section> {
        return this.sectionsQuery.selectActive() as Observable<Section>;
    }
}
