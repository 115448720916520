import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { EnvServiceFactory } from './app/modules/core/providers/env-service.provider';
import { release } from './release';

akitaConfig({ resettable: true });

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

const env = EnvServiceFactory();

if (environment.production && env.sentryDsn) {
    Sentry.init({
        dsn: env.sentryDsn,
        environment: env.sentryEnvironment,
        ignoreErrors: ['Non-Error exception captured'],
        integrations: [
            new BrowserTracing({
                tracingOrigins: [/^[\S]+\.elias\.app/, /develop.elias.lstnet.ch/],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        release: release.version,
        tracesSampler: (samplingContext) => {
            if (
                samplingContext.transactionContext.name.includes('PUT') &&
                samplingContext.transactionContext.name.includes('/cycle/')
            ) {
                return 0;
            }
            return env.sentryTracesSampleRate;
        },
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
