<ng-container *ngIf="big && intermediate && list">
    <div class="btn-container">
        <i class="ei ei-card" [ngClass]="{ selected: viewOption === 0 }" (click)="changeViewOption(0)"></i>
        <i class="ei ei-list" [ngClass]="{ selected: viewOption === 2 }" (click)="changeViewOption(2)"></i>
        <!--<button mat-icon-button (click)="changeViewOption(1)" [ngClass]="viewOption == 1 ? 'active' : 'inactive'"><mat-icon color="{{viewOption == 1 ? 'accent': 'primary'}}">view_comfy</mat-icon></button>-->
    </div>
</ng-container>
<ng-container *ngIf="big && !intermediate && list">
    <div class="btn-container">
        <i class="ei ei-card" [ngClass]="{ selected: viewOption === 0 }" (click)="changeViewOption(0)"></i>
        <i class="ei ei-list" [ngClass]="{ selected: viewOption === 2 }" (click)="changeViewOption(2)"></i>
    </div>
</ng-container>
