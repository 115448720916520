import { Component, Input } from '@angular/core';
import { User } from '../../state/users/user.model';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';
import { AvatarSize } from '../../types/avatar-size.type';

@Component({
    selector: 'elias-shared-user-avatar',
    templateUrl: './user-avatar.component.html',
})
export class UserAvatarComponent {
    @Input() hideToolTip = false;
    @Input() size: AvatarSize = 'small';
    @Input() user?: Partial<User>;
    @Input() visualizeDisabledState = false;

    get initials(): string {
        if (this.user && this.user.firstName && this.user.lastName) {
            return this.user.firstName[0] + this.user.lastName[0];
        }

        return '';
    }

    constructor(public usersUtilService: UsersUtilsService) {}

    public getUserRoleColor(): string | undefined {
        return this.user ? this.usersUtilService.getUserRoleColor(this.user) : undefined;
    }
}
