import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AppComponent } from '../../app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AppRoutingModule } from '../../app-routing.module';
import { EditorModule } from '../../editor/editor/editor.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../../auth/auth.module';
import { ContainerComponent } from './components/container/container.component';
import { UiQuery } from './state/ui/ui.query';
import { UiService } from './state/ui/ui.service';
import { Router, RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './strategies/route-reuse.strategy';
import { SideNavService } from './services/side-nav.service';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { MainSideNavService } from './services/main-side-nav.service';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { DisabledUserComponent } from './pages/disabled-user/disabled-user.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { EnvServiceFactory, EnvServiceProvider } from './providers/env-service.provider';
import * as Sentry from '@sentry/angular';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { environment } from '../../../environments/environment';

export const COMPONENTS = [
    AppComponent,
    NotFoundComponent,
    ToolbarComponent,
    ContainerComponent,
    MainNavigationComponent,
    DisabledUserComponent,
    AccessDeniedComponent,
    LogoutComponent,
    BreadcrumbComponent,
];

export function playerFactory() {
    return import('lottie-web/build/player/lottie_light');
}

const env = EnvServiceFactory();

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AppRoutingModule,
        // TODO Remove import of EditorModule. Add api to change toolbar menu items from editor module and remove hard dependency
        EditorModule,
        AuthModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [
        EnvServiceProvider,
        UiQuery,
        UiService,
        SideNavService,
        MainSideNavService,
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: env.sentryShowDialog, // Show dialog for client to enter error message
                logErrors: !environment.production, // Log errors to console
            }),
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined,
        },
    ],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [AuthInterceptor],
        };
    }
}
