<div class="content-wrapper-for-hover" [ngClass]="{ 'show-spinner': showSpinner }">
    <div class="options" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
        <ng-container>
            <mat-card *ngFor="let n of newData; trackBy: trackByIndex" (click)="selectOption(n)">
                <mat-card-header>
                    <mat-card-title><i class="ei {{ n.icon }}"></i></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    {{ n.title }}
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
    <div *ngIf="showSpinner" class="align-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="node-buttons node-buttons-right">
    <i class="ei ei-trash icons" (click)="onRemove()"></i>
</div>
