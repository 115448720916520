import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeType } from '../../../types/node-types.type';
import { ImagesService } from '../../../services';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { DragulaService } from 'ng2-dragula';
import * as _ from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { KeyImageForm } from '../../../forms/key-image.form';
import { ItemCustomComponent } from '../../item-custom/item-custom.component';
import { TranslateService } from '@ngx-translate/core';
import { RequestDto } from '../../../models/request-dto.model';
import { FormConfig } from '../../../../../modules/forms/models/form-config.model';

@Component({
    selector: 'elias-editor-edit-keyimages',
    templateUrl: './edit-keyimages.component.html',
    styleUrls: ['edit-keyimages.component.scss'],
})
export class EditKeyImagesComponent implements NodeEdit, NodeRepresentation, OnInit, OnDestroy {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    @Output() contentChange = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    public keyImages = [];
    aspectRatio;
    dropSubscription: Subscription;
    cols = 2;
    public formConfig: FormConfig = { elements: [] };
    keyImageLayout = '';

    constructor(
        private imageService: ImagesService,
        private dragulaService: DragulaService,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.formConfig = KeyImageForm;
        this.keyImages = JSON.parse(this.content);
        this.cols = this.node.cols;
        this.keyImageLayout = this.node.layout;

        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }

        const bag: any = this.dragulaService.find('keyimage-draggable');
        if (bag === undefined) {
            this.dragulaService.createGroup('keyimage-draggable', {
                removeOnSpill: false,

                accepts(el, target, source, sibling) {
                    return true;
                },

                moves(el, source, handle, siblings) {
                    return (
                        handle.classList.contains('element-button-drag') ||
                        handle.classList.contains('element-button-drag-icon')
                    );
                },
            });

            this.dropSubscription = this.dragulaService
                .drop('keyimage-draggable')
                .subscribe(({ el, target, source }) => {
                    if (el != null) {
                        this.onNodeMove(el, target, source);
                    }
                    return false;
                });
        }
        this.onContentChange();
    }

    onNodeMove(element, newCol, oldCol) {
        const newPosition = this.getElementIndex(element);
        const oldPosition = this.getIndex(element);
        const newColIndex = this.getCol(newCol);
        const oldColIndex = this.getCol(oldCol);
        if (oldColIndex != newColIndex) {
            // if element is moved to another column
            const movedElement = this.keyImages[oldColIndex][oldPosition];
            // add to new array
            this.keyImages[newColIndex].splice(newPosition, 0, {
                label1: movedElement.label1,
                value1: movedElement.value1,
                label2: movedElement.label2,
                value2: movedElement.value2,
                label3: movedElement.label3,
                value3: movedElement.value3,
                image: movedElement.image,
            });
            // remove from old array
            const removePos = this.keyImages[oldColIndex].indexOf(movedElement);
            if (removePos > -1) {
                this.keyImages[oldColIndex].splice(removePos, 1);
            }
        } else {
            // if element is moved within the same column
            this.keyImages[newColIndex] = this.moveElementInArray(
                this.keyImages[newColIndex],
                oldPosition,
                newPosition
            );
        }
        this.keyImages = _.cloneDeep(this.keyImages);
        this.onContentChange();
    }

    moveElementInArray(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        arr = arr.filter(function (element) {
            return element !== undefined;
        });

        return arr;
    }

    getElementIndex(el: any) {
        return [].slice.call(el.parentElement.children).indexOf(el);
    }

    getCol(el: any) {
        return el.getAttribute('data-col');
    }

    getIndex(el: any) {
        return el.getAttribute('data-index');
    }

    onContentChange() {
        this.content = JSON.stringify(this.keyImages);
        this.contentChange.emit(this.content);
    }

    addElement(i) {
        this.keyImages[i].push({
            label1: this.translateService.instant('component.keyimages.label1'),
            value1: this.translateService.instant('component.keyimages.value1'),
            label2: this.translateService.instant('component.keyimages.label2'),
            value2: this.translateService.instant('component.keyimages.value2'),
            label3: this.translateService.instant('component.keyimages.label3'),
            value3: this.translateService.instant('component.keyimages.value3'),
            image: null,
        });
        this.openEditor(this.keyImages[i][this.keyImages[i].length - 1], i, this.keyImages[i].length - 1, 'add');
        this.onContentChange();
    }

    deleteElement(col, index) {
        this.keyImages[col].splice(index, 1);
        this.onContentChange();
    }

    getColClass() {
        return 12 / this.cols;
    }

    ngOnDestroy() {
        this.dragulaService.destroy('keyimage-draggable');
        if (this.dropSubscription) {
            this.dropSubscription.unsubscribe();
        }
    }

    openEditor(val, colInd, itemInd, source = null) {
        const onSave = new EventEmitter<any>();
        const onDelete = new EventEmitter<any>();

        const inputs = {
            data: val,
            type: 'keyimage',
            config: this.formConfig,
            source,
        };
        const outputs = {
            save: onSave,
            delete: onDelete,
        };

        this.sideNavService.setComponent(ItemCustomComponent, inputs, outputs);

        onSave.subscribe((data) => {
            this.onModifyData(data, colInd, itemInd);
        });

        onDelete.subscribe((value) => {
            if (value) {
                this.onDelete(colInd, itemInd);
            }
        });
    }

    onModifyData(data, colInd, itemInd) {
        for (const key of Object.keys(data)) {
            this.keyImages[colInd][itemInd][key] = data[key];
        }

        const arr = ['label1', 'label2', 'label3', 'value1', 'value2', 'value3', 'image'];
        let counter = 0;

        arr.forEach((ele) => {
            if (this.keyImages[colInd][itemInd].hasOwnProperty(ele)) {
                counter++;
            }
        });

        if (data.image !== undefined && data.image !== null) {
            this.imageService
                .loadSingleImage(
                    new RequestDto({
                        assetId: data.image,
                        aspectRatio: this.aspectRatio,
                    })
                )
                .subscribe();
        }

        if (counter === 7) {
            this.onContentChange();
            this.close();
        }
    }

    onDelete(col, itemInd) {
        this.keyImages[col].splice(itemInd, 1);
        this.onContentChange();
        this.close();
    }

    close() {
        this.sideNavService.toggle();
    }

    trackByIndex(index, item) {
        return index;
    }
}
