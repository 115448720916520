<div [formGroup]="formGroup">
    <div
        fxLayout="row"
        fxLayoutGap="30px"
        fxLayoutAlign="start space-between"
        *ngIf="ObjectKeys(settings).length && settings.filter">
        <div
            class="filters"
            fxFlex="fill"
            fxLayout="row"
            fxLayoutGap="30px"
            [ngClass]="{ styling: renderType === 'small-card' }">
            <div class="search-field">
                <input
                    type="search"
                    required
                    class="search-input"
                    #filterName
                    placeholder="{{ 'userlist.filter.search' | translate }}"
                    (keyup)="applyFilter($event.target.value)" />
                <i class="ei ei-close search-cancel icon" (click)="applyFilter(null)"></i>
            </div>
            <elias-shared-filter-dropdown
                class="filter-dropdown"
                title="{{ 'user.filter.roles' | translate }}"
                [options]="userRoleOptions"
                (changeValue)="onOptionSelected($event)"></elias-shared-filter-dropdown>
        </div>
    </div>
    <ng-container *ngIf="renderType === 'normal'">
        <div *ngIf="(filteredUser$ | async)?.length > 0; else showNoRecord">
            <div fxLayout="row wrap" fxLayoutGap="20px grid">
                <ng-container *ngFor="let user of filteredUser$ | async; trackBy: trackByItemId; let i = index">
                    <div *ngIf="user.enabled">
                        <mat-card
                            class="person"
                            [ngClass]="{
                                active: isActive(user),
                                personal: isActive(user) && !isChiefEditor(user),
                                'disable-user': isChiefEditor(user),
                                'disable-last-user':
                                    selectedUsers.length === 1 &&
                                    isActive(user) &&
                                    formGroup.controls.chiefEditors &&
                                    !isAdmin(user)
                            }"
                            (click)="toggleActivation(user)">
                            <mat-card-header fxLayout="column">
                                <i *ngIf="isActive(user) && !isChiefEditor(user)" class="ei ei-done done"></i>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div mat-card-avatar>
                                        <elias-shared-user-avatar
                                            [user]="user"
                                            [hideToolTip]="true"
                                            [size]="'medium'"></elias-shared-user-avatar>
                                    </div>
                                    <mat-card-title>{{
                                        user.firstName + ' ' + user.lastName | strlen : 20
                                    }}</mat-card-title>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="tags" fxLayout="row" fxLayoutGap="6px">
                                    <div
                                        *ngFor="let role of user.roles; trackBy: trackByIndex"
                                        class="tag dots"
                                        [ngStyle]="{ 'background-color': userRoleColor[role] }"
                                        matTooltip="{{ 'user.roles.' + role | translate }}"></div>
                                </div>
                            </mat-card-content>
                            <mat-card-footer>
                                <ng-container *ngIf="user.id !== loggedInUserQuery.getValue().id || isAdmin(user)">
                                    <button
                                        mat-flat-button
                                        color="accent"
                                        class="select-button"
                                        *ngIf="!isActive(user)">
                                        {{ 'publication.select' | translate }}
                                    </button>
                                    <button mat-flat-button color="accent" class="select-button" *ngIf="isActive(user)">
                                        {{ 'publication.deselect' | translate }}
                                    </button>
                                </ng-container>
                            </mat-card-footer>
                        </mat-card>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="renderType === 'small-card'">
        <div *ngIf="(filteredUser$ | async)?.length > 0; else showNoRecord">
            <div fxLayout="row wrap" fxLayoutGap="20px grid" class="styling">
                <ng-container *ngFor="let user of filteredUser$ | async; trackBy: trackByItemId; let i = index">
                    <div *ngIf="user.enabled">
                        <mat-card
                            class="person small-card"
                            [ngClass]="{
                                active: isActive(user),
                                personal: isActive(user) && !isChiefEditor(user),
                                'disable-user': isChiefEditor(user) || isAdmin(user) || isSuperAdmin(user),
                                'disable-last-user':
                                    selectedUsers.length === 1 && isActive(user) && formGroup.controls.chiefEditors
                            }"
                            (click)="toggleActivation(user)">
                            <mat-card-header fxLayout="column">
                                <i *ngIf="isActive(user) && !isChiefEditor(user)" class="ei ei-done done"></i>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div mat-card-avatar>
                                        <elias-shared-user-avatar
                                            [user]="user"
                                            [hideToolTip]="true"
                                            [size]="'medium'"></elias-shared-user-avatar>
                                    </div>
                                    <mat-card-title>{{
                                        user.firstName + ' ' + user.lastName | strlen : 20
                                    }}</mat-card-title>
                                </div>
                            </mat-card-header>
                        </mat-card>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #showNoRecord>
        <div>{{ 'userlist.noRecordError' | translate }}</div>
    </ng-template>
</div>
