import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestDto } from '../../models/request-dto.model';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { PreviewsStore } from './previews.store';

@Injectable()
export class PreviewService {
    constructor(private domainService: DomainService, private http: HttpClient, private previewStore: PreviewsStore) {}

    /** GET preview word preview */
    getWordSectionPreview(payload: RequestDto): Observable<Blob> {
        this.previewStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/preview/section/${payload.getParam(
            'sectionId'
        )}/channel/${payload.getParam('channelId')}`;
        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap((preview) => {
                this.previewStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    /** GET html preview */
    getHtmlSectionPreview(payload: RequestDto): Observable<any> {
        this.previewStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/preview/section/${payload.getParam(
            'sectionId'
        )}/channel/${payload.getParam('channelId')}`;
        return this.http.get(url, { responseType: 'text' }).pipe(
            tap((preview) => {
                this.previewStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
