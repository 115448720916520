import { QueryEntity } from '@datorama/akita';
import { ChartsState, ChartsStore, chartsStore } from './charts.store';
import { Chart } from './chart.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChartsQuery extends QueryEntity<ChartsState, Chart> {
    constructor(protected store: ChartsStore) {
        super(store);
    }
}

export const chartsQuery = new ChartsQuery(chartsStore);
