import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';

@Injectable({
    providedIn: 'root',
})
export class AssetForm {
    constructor() {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    name: 'name',
                    type: 'text',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [
                        {
                            name: 'maxLength',
                            options: 255,
                        },
                    ],
                    show: [],
                },
                {
                    name: 'assetCollections',
                    type: 'pills',
                    required: false,
                    width: 100,
                    options: [{ key: '', value: '', color: '' }],
                    settings: { multiple: true, category: 'single' },
                    validators: [],
                    show: [],
                    hint: 'publication.hint.category',
                },
            ],
        };
    }
}
