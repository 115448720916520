import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { RequestDto } from '../../editor/models/request-dto.model';
import { AssetsService } from '../../../modules/shared/state/assets/assets.service';
import { catchError, last, tap } from 'rxjs/operators';
import { AssetsQuery } from '../../../modules/shared/state/assets/assets.query';
import { AssetStoragesService } from '../state/asset-storages/asset-storages.service';
import { AssetStoragesQuery } from '../state/asset-storages/asset-storages.query';
import { AssetStorage } from '../state/asset-storages/asset-storage.model';

@Injectable()
export class AssetBrowserDeepLinksResolver implements Resolve<any> {
    constructor(
        private assetsQuery: AssetsQuery,
        private assetsService: AssetsService,
        private assetStoragesQuery: AssetStoragesQuery,
        private assetStoragesService: AssetStoragesService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const assetId = route.params['asset'];

        if (!assetId) {
            return of(true);
        }

        const observables: Observable<any>[] = [];
        const publicationGroupId = this.getRouteParamRecursively(route, 'id');

        if (!this.assetsQuery.hasEntity(assetId)) {
            const payload = new RequestDto({ assetId: assetId });
            observables.push(this.assetsService.loadSingleAsset(payload));
        }

        let availableAssetStorages: AssetStorage[];

        observables.push(
            this.assetStoragesService.getAssetStorages(new RequestDto({ pubGroupId: publicationGroupId })).pipe(
                tap((assetStorages) => {
                    availableAssetStorages = assetStorages;
                })
            )
        );

        return forkJoin(observables).pipe(
            last(),
            tap(() => {
                const { storage } = this.assetsQuery.getEntity(assetId);

                if (availableAssetStorages.some(({ id }) => id === storage.id)) {
                    return of(true);
                }
                return this.router.navigateByUrl(this.getRedirectUrl(state));
            }),
            catchError(() => this.router.navigateByUrl(this.getRedirectUrl(state)))
        );
    }

    private getRouteParamRecursively(route: ActivatedRouteSnapshot, param: string): string | null {
        let checkedRoute: ActivatedRouteSnapshot | null = route;

        while (checkedRoute !== null) {
            if (checkedRoute.params[param]) {
                return checkedRoute.params[param];
            }

            checkedRoute = checkedRoute.parent;
        }

        return null;
    }

    private getRedirectUrl(state: RouterStateSnapshot): string {
        return state.url.split(';asset')[0];
    }
}
