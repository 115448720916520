import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DomainService } from '../../../modules/core/services/domain.service';

@Injectable()
export class StylesheetService {
    constructor(private domainService: DomainService, private http: HttpClient) {}

    getStylesheet(rootSectionId: string): Observable<any> {
        const url = `${this.domainService.apiBaseUrl}/stylesheet/${rootSectionId}`;
        return this.http.get(url, { responseType: 'text' }).pipe(catchError((error) => throwError(error)));
    }
}
