import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DomainService } from '../../../core/services/domain.service';
import { LoggedInUserStore } from './logged-in-user.store';
import { LoggedInUserQuery } from './logged-in-user.query';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../users/user.model';
import { Role } from '../../../../editor/editor/enums/role.enum';
import { DateAdapter } from '@angular/material/core';

@Injectable({
    providedIn: 'root',
})
export class LoggedInUserService {
    constructor(
        private domainService: DomainService,
        private loggedInUserStore: LoggedInUserStore,
        private loggedInUserQuery: LoggedInUserQuery,
        private http: HttpClient,
        private adapter: DateAdapter<any>,
        private translateService: TranslateService
    ) {}

    getLoggedInUser() {
        this.loggedInUserStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users/me`;

        return this.http.get<User>(url).pipe(
            tap((user) => {
                this.translateService.use(user.locale);
                this.adapter.setLocale(user.locale);
                this.loggedInUserStore.update(user);
                this.loggedInUserStore.update({ loading: false, loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    public getLoggedInUserDetail() {
        return this.loggedInUserQuery.select();
    }

    mockUser() {
        return {
            id: 'f90fbbea-9425-11ea-b73b-7c4f188a6e88',
            username: 'parijit.kedia',
            email: 'parijit.kedia@lst.team',
            enabled: true,
            lastLogin: '2020-06-17T13:56:10+00:00',
            name: 'parijit.kedia',
            roles: [Role.SuperAdmin],
            firstName: 'Parijit',
            lastName: 'kedia',
            mobile: '+41112345678',
            locale: 'de_CH',
            publicationView: 'tile',
            twoFactorAuthenticationMethod: 'email',
            profileImage: '65da05243a4a5b3d880ee3146e8c00a4.jpeg',
            gender: 'm',
            profileImageContent: '',
            loaded: false,
            loading: false,
        };
    }
}
