import { Injectable } from '@angular/core';
import { AspectRatio } from '../../../modules/shared/state/aspect-ratios/aspect-ratio.model';
import { RequestDto } from '../models/request-dto.model';
import { ImagesService } from '../../editor-container/state/images/images.service';
import { Observable } from 'rxjs';
import { ImagesQuery } from '../../editor-container/state/images/images.query';
import { AspectRatiosQuery } from '../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { share } from 'rxjs/operators';

@Injectable()
export class ImageLoaderService {
    defaultAspectRatio$: Observable<AspectRatio[]> = this.aspectRatiosQuery.selectAll({
        filterBy: (aspectRatio) => aspectRatio.default === true,
    });

    defaultAspectRatio: AspectRatio;

    constructor(
        private imagesQuery: ImagesQuery,
        private imagesService: ImagesService,
        private aspectRatiosQuery: AspectRatiosQuery
    ) {
        this.defaultAspectRatio$.subscribe((defaultAspectRatio) => (this.defaultAspectRatio = defaultAspectRatio[0]));
    }

    public loadImages(imageIds: string[], aspectRatioId: string = null, force = false): void {
        if (aspectRatioId === null) {
            aspectRatioId = this.defaultAspectRatio.id;
        }

        const imagesToLoad = force
            ? imageIds
            : imageIds.filter((id: string) => id !== null && !this.checkIfImageHasAspectRatio(id, aspectRatioId));

        if (imagesToLoad.length > 0) {
            const payload = new RequestDto(
                {
                    imageIds: imagesToLoad.join(','),
                    aspectRatio: aspectRatioId,
                },
                {},
                {}
            );
            this.imagesService.loadImages(payload).pipe(share()).subscribe();
        }
    }

    private checkIfImageHasAspectRatio(id: string, aspectRatioId: string): boolean {
        if (
            this.imagesQuery.hasEntity(id) &&
            this.imagesQuery.getEntity(id).aspectRatios &&
            this.imagesQuery.getEntity(id).aspectRatios[aspectRatioId]
        ) {
            return true;
        }
        return false;
    }
}
