import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectedElement, SelectedNode, SelectedSection } from '../../models/selected-element.model';
import { NodesService, PropertyEditorService, SectionsService } from '../../services';
import { SaveService } from '../../services/save.service';
import { PropertyEditorQuery } from '../../state/property-editor/property-editor.query';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from '../../../../modules/forms/components/form/form.component';
import { Node } from '../../state/nodes/node.model';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { SectionsQuery } from '../../state/sections/sections.query';
import { Publication } from '../../../../publication/state/publication/publication.model';
import { RequestDto } from '../../models/request-dto.model';
import { NodeDto } from '../../models/node-dto.model';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { delay, first, tap } from 'rxjs/operators';
import { PublicationsQuery } from '../../../../publication/state/publication/publications.query';
import { UiService } from '../../../../modules/core/state/ui/ui.service';
import * as _ from 'lodash';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { AddChannelSettingsForm } from '../../forms/add-channel-settings.form';
import { SelectedElementType } from '../../enums/selected-element-type.enum';

@Component({
    selector: 'elias-editor-property-editor',
    templateUrl: './property-editor.component.html',
    styleUrls: ['./property-editor.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
})
export class PropertyEditorComponent implements OnInit {
    @Input() sectionOrNodeType;
    @Input() sectionId;
    @Input() nodeId;

    @ViewChild('form') form: FormComponent;

    public initData;
    public channelSettings: any[];
    public isSaving = false;
    public formConfig: FormConfig = { elements: [] };
    public formConfigIsReady$ = new BehaviorSubject<boolean>(false);
    public loaded$ = new BehaviorSubject<boolean>(false);
    public selectedElement: SelectedElement;

    constructor(
        private addChannelSettingsForm: AddChannelSettingsForm,
        private changeDetector: ChangeDetectorRef,
        public dialog: MatDialog,
        private flashMessageService: FlashMessageService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private propertyEditorQuery: PropertyEditorQuery,
        private propertyEditorService: PropertyEditorService,
        private publicationsQuery: PublicationsQuery,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private sideNavService: SideNavService,
        private uiService: UiService
    ) {}

    ngOnInit(): void {
        this.uiService.setToolbarState('editor');

        this.loadData(this.sectionId);

        this.loaded$
            .pipe(
                delay(50),
                tap((loaded) => {
                    if (loaded) {
                        if (this.sectionOrNodeType && this.nodeId) {
                            if (this.sectionOrNodeType === SelectedElementType.Node) {
                                const node = this.nodesQuery.getEntity(this.nodeId);
                                this.selectedElement = new SelectedNode(node);
                            } else {
                                const section = this.sectionsQuery.getEntity(this.nodeId);
                                this.selectedElement = new SelectedSection(section);
                            }
                        }

                        const state = this.propertyEditorQuery.getValue();
                        if (this.selectedElement.type === SelectedElementType.Node) {
                            const node = this.selectedElement.element as Node;
                            this.formConfig.elements = state.nodeConfigurations[node.type]
                                ? state.nodeConfigurations[node.type].properties
                                : [];
                            this.channelSettings = state.nodeConfigurations[node.type]
                                ? state.nodeConfigurations[node.type].channelSettings
                                : [];
                        } else {
                            this.formConfig.elements = state.sectionConfigurations.general.properties;
                            this.channelSettings = state.sectionConfigurations.general.channelSettings;
                        }

                        const channelSettingsFormConfig = this.addChannelSettingsForm.getConfiguration();
                        this.formConfig.elements = this.formConfig.elements.concat(channelSettingsFormConfig.elements);

                        this.setInitData();
                        // TODO: Evaluate if we should use this
                        this.formConfigIsReady$.next(true);
                        this.changeDetector.detectChanges();
                    }
                })
            )
            .subscribe();
    }

    loadData(sectionId): void {
        const observables = [];
        if (!this.propertyEditorQuery.getValue().loaded) {
            const rootSectionId = (this.publicationsQuery.getActive() as Publication).rootSectionId;
            const requestDto = new RequestDto({ rootSectionId });
            observables.push(this.propertyEditorService.loadPropertyEditorConfigurations(requestDto));
        }
        if (!this.sectionsQuery.getValue().loaded) {
            observables.push(this.sectionsService.getSections());
        }
        if (this.nodesQuery.getValue().loadedNodesForSectionId !== sectionId) {
            const payload: NodeDto = new NodeDto({ sectionId });
            observables.push(this.nodesService.getNodesForSection(payload));
        }

        if (observables.length > 0) {
            this.loaded$.next(false);
            forkJoin(observables)
                .pipe(first())
                .subscribe((results) => {
                    this.loaded$.next(true);
                });
        } else {
            this.loaded$.next(true);
        }
    }

    setInitData(): void {
        const data = [];
        this.formConfig.elements.map((property) => {
            data[property.name] = this.selectedElement.element[property.name];
        });
        data['channelSettings'] = this.channelSettings.map((channelSetting) => {
            return {
                name: channelSetting.name,
                label: channelSetting.label,
                active: this.selectedElement.element.channelSettings[channelSetting.name].active,
            };
        });
        this.initData = data;
    }

    onFormSubmitted(props): void {
        const properties = _.cloneDeep(props);
        this.isSaving = true;
        if (properties.channelSettings) {
            const newChannelSettings = {};
            properties.channelSettings.map((channelSetting) => {
                newChannelSettings[channelSetting.name] = { active: channelSetting.active };
            });
            properties.channelSettings = newChannelSettings;
        }
        this.saveService.saveProperties(this.selectedElement, properties).subscribe(() => {
            this.sideNavService.close();
        });
    }

    isSection(): boolean {
        return this.sectionOrNodeType === 'section';
    }

    removeNode(): void {
        const modalRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'node',
                requireExplicitConfirmation: false,
            },
        });

        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                const payload: NodeDto = new NodeDto({ nodeId: this.nodeId });
                this.nodesService.deleteNode(payload).subscribe(
                    () => {
                        this.flashMessageService.showTranslatedInfo('modal.delete.node.success');
                    },
                    (err) => {
                        this.flashMessageService.showTranslatedError('propertyEditor.remove.error');
                    }
                );
                this.close();
            }
        });
    }

    copyNode(): void {
        this.nodesService.copyNode(this.nodeId).subscribe();
        this.close();
    }

    close(): void {
        this.sideNavService.close();
    }
}
