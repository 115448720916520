import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../state/sections/section.model';
import { Observable } from 'rxjs';
import { TitlePipeReplacerService } from '../../services/utilities/title-pipe-replacer.service';
import { SaveService } from '../../services/save.service';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { PropertyEditorComponent } from '../property-editor/property-editor.component';
import { SectionCommentsSidenavService } from '../../../comments/services/sidenav/section-comments-sidenav.service';

@Component({
    selector: 'elias-editor-section-headline',
    styleUrls: ['./section-headline.component.scss'],
    templateUrl: './section-headline.component.html',
})
export class SectionHeadlineComponent implements OnInit {
    @Input() section$: Observable<Section>;

    section: Section;
    title: string;

    constructor(
        private saveService: SaveService,
        private sectionCommentsSidenavService: SectionCommentsSidenavService,
        private sideNavService: SideNavService,
        private titleReplacerService: TitlePipeReplacerService
    ) {}

    ngOnInit() {
        this.section$.subscribe((section) => {
            if (section) {
                this.section = section;
                this.title = this.titleReplacerService.replacePipes(section.title, true);
            }
        });
    }

    public openPropertyEditor() {
        this.saveService.saveNode(true).subscribe(() => {
            const inputs = {
                sectionOrNodeType: 'section',
                sectionId: this.section.id,
                nodeId: this.section.id,
            };

            const outputs = {};
            this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
        });
    }

    public async openComments(preselectedCommentId?: string): Promise<void> {
        await this.sectionCommentsSidenavService.open(this.section, preselectedCommentId);
    }
}
