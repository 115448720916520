<ng-container *ngIf="objectData.length > 0 && objectData[0].hasOwnProperty('image')">
    <ng-container *ngIf="!objectData[0]['image']">
        <i class="ei ei-error-full"></i>{{ 'component.image.missing' | translate }}
    </ng-container>
    <ng-container *ngIf="objectData[0]['image']">
        <ng-container *ngFor="let data of order; trackBy: trackByIndex">
            <ng-container *ngIf="data !== 'link'">
                <ng-container *ngIf="data === 'image'; else dispData">
                    <elias-shared-custom-image
                        [id]="objectData[0][data]"
                        [aspectRatio]="aspectRatio"></elias-shared-custom-image>
                </ng-container>
                <ng-template #dispData>
                    <div
                        class="{{ data }}"
                        *ngIf="itemFieldSettings[data]"
                        [innerHTML]="objectData[0][data] | html"></div>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<div class="space-after-line"></div>
<div *ngIf="!parsedContent.hasOwnProperty('image')" class="pe-node-display-error">
    <i class="ei ei-error-full"></i><span>{{ 'component.image.missing' | translate }}</span>
</div>
