import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class HtmlPreviewService {
    private contentSource = new BehaviorSubject(this.sanitizer.bypassSecurityTrustHtml('<h1>Dynamischer Inhalt</h1>'));
    content$ = this.contentSource.asObservable();

    constructor(private sanitizer: DomSanitizer) {}

    setContent(content: string) {
        this.contentSource.next(this.sanitizer.bypassSecurityTrustHtml(content));
    }
}
