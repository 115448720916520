<div
    class="content-wrapper-for-hover"
    [ngClass]="{
        'border-control': node.needsReview,
        'fixed-border': node.editing,
        'unresolved-comments-border': node.hasUnresolvedComments,
        pushLeft: node.type === 'image' && node['imageStyle'] === 'pushLeft',
        pushRight: node.type === 'image' && node['imageStyle'] === 'pushRight',
        fullWidth: node.type === 'image' && node['imageStyle'] === 'fullWidth',
        default: node.type === 'image' && node['imageStyle'] === 'default'
    }">
    <ng-template appNode> </ng-template>
</div>
<div
    class="node-buttons node-buttons-left"
    id="drag-button-left"
    *ngIf="!editing"
    [ngClass]="{
        pushLeft: node.type === 'image' && node['imageStyle'] === 'pushLeft',
        pushRight: node.type === 'image' && node['imageStyle'] === 'pushRight',
        fullWidth: node.type === 'image' && node['imageStyle'] === 'fullWidth',
        default: node.type === 'image' && node['imageStyle'] === 'default'
    }">
    <i class="ei ei-drag node-button-drag icons"></i>
</div>
<div
    class="node-buttons node-buttons-right"
    [ngClass]="{
        pushLeft: node.type === 'image' && node['imageStyle'] === 'pushLeft',
        pushRight: node.type === 'image' && node['imageStyle'] === 'pushRight',
        fullWidth: node.type === 'image' && node['imageStyle'] === 'fullWidth',
        default: node.type === 'image' && node['imageStyle'] === 'default'
    }">
    <i class="ei ei-settings icons" (click)="onPropertyEditorOpen($event)"></i>
    <i
        class="ei icons"
        [ngClass]="node.hasUnresolvedComments ? 'ei-comment-full unresolved-comments-icon' : 'ei-comment'"
        (click)="openComments($event)"></i>
</div>
